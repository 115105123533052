
import { Component, Vue, Emit } from "vue-property-decorator";
import Modal from "../../../../common/src/components/Modal.vue";
import ScheduleSessionForm from "./ScheduleSessionForm.vue";

@Component({
    components: { ScheduleSessionForm, Modal },
})
export default class ScheduleSessionModal extends Vue {
    chooseAffiliationText = "Choose Affiliation";
    selectDateTimeText = "Select Date & Time";

    modalMenuText = this.chooseAffiliationText;
    affiliationChosen(value: boolean) {
        this.modalMenuText = value ? this.selectDateTimeText : this.chooseAffiliationText;
    }

    @Emit()
    close() {
        //
    }
}
