
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import FormInput from "./FormInput.vue";
import FormLabel from "./FormLabel.vue";
import moment from "moment";
import { toDateString } from "@gsx/common";
import FormFeedback from "./FormFeedback.vue";

@Component({
    components: { FormLabel, FormInput, FormFeedback },
})
export default class InlineFormDate extends Vue {
    @Prop() label?: string;
    @Prop() value!: Date | string;
    @Prop({ default: false }) required!: boolean;
    @Prop() error?: string;

    date: string | null = null;

    created() {
        this.onValueUpdated(this.value);
    }

    @Emit()
    input(date: Date) {
        return date;
    }

    @Watch("date")
    onChange() {
        if (this.date) {
            const date = moment(`${this.date}`).utc();

            if (date.isValid()) {
                this.input(date.toDate());
            }
        }
    }

    @Watch("value")
    onValueUpdated(value: Date | string) {
        this.date = toDateString(value);
    }
}
