
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import FormInput from "../../../form/FormInput.vue";

@Component({
    components: { FormInput },
})
export default class InvestmentsTable extends Vue {
    @Prop({ default: () => [] }) value!: {
        announceDate: string;
        enterpriseValue: string;
        generalTransactionType: string;
        investmentAmount: string;
        investors: string;
        leadInvestorCountry: string;
        leadInvestorGeneralSectorCategory: string;
        leadInvestorRegion: string;
        notes: string;
        sourceLink: string;
        status: string;
        targetCompany: string;
        targetCountry: string;
        targetGeneralSectorCategory: string;
        targetProfile: string;
        targetRegion: string;
        trailingEbitda: string;
        trailingRev: string;
        transactionCurrency: string;
        transactionEnterpriseValue: string;
        transactionSubtype: string;
    }[];

    get formattedValue(): {
        announceDate: string;
        enterpriseValue: string;
        generalTransactionType: string;
        investmentAmount: string;
        investors: string;
        leadInvestorCountry: string;
        leadInvestorGeneralSectorCategory: string;
        leadInvestorRegion: string;
        notes: string;
        sourceLink: string;
        status: string;
        targetCompany: string;
        targetCountry: string;
        targetGeneralSectorCategory: string;
        targetProfile: string;
        targetRegion: string;
        trailingEbitda: string;
        trailingRev: string;
        transactionCurrency: string;
        transactionEnterpriseValue: string;
        transactionSubtype: string;
    }[] {
        return this.value || [];
    }

    @Emit()
    input(
        value: {
            announceDate: string;
            enterpriseValue: string;
            generalTransactionType: string;
            investmentAmount: string;
            investors: string;
            leadInvestorCountry: string;
            leadInvestorGeneralSectorCategory: string;
            leadInvestorRegion: string;
            notes: string;
            sourceLink: string;
            status: string;
            targetCompany: string;
            targetCountry: string;
            targetGeneralSectorCategory: string;
            targetProfile: string;
            targetRegion: string;
            trailingEbitda: string;
            trailingRev: string;
            transactionCurrency: string;
            transactionEnterpriseValue: string;
            transactionSubtype: string;
        }[],
    ) {
        return value;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add() {
        this.input([
            ...this.formattedValue,
            {
                announceDate: "",
                enterpriseValue: "",
                generalTransactionType: "",
                investmentAmount: "",
                investors: "",
                leadInvestorCountry: "",
                leadInvestorGeneralSectorCategory: "",
                leadInvestorRegion: "",
                notes: "",
                sourceLink: "",
                status: "",
                targetCompany: "",
                targetCountry: "",
                targetGeneralSectorCategory: "",
                targetProfile: "",
                targetRegion: "",
                trailingEbitda: "",
                trailingRev: "",
                transactionCurrency: "",
                transactionEnterpriseValue: "",
                transactionSubtype: "",
            },
        ]);
    }
}
