import { Api } from "../../../../../../common/src/api/Api";
import { DestroyStoreVuex } from "../../../../../../common/src/stores/general/DestroyStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module()
export class PastProjectDestroyStore extends DestroyStoreVuex {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async request(id: string): Promise<void> {
        await this.api.user.pastProject.delete(id);
    }
}
