
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { QuiltyResourceCreateStore } from "../../../stores/admin/quiltyResource/QuiltyResourceCreateStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { QuiltyResourceListStore } from "../../../stores/admin/quiltyResource/QuiltyResourceListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import QuiltyResourceForm from "../../../molecules/quiltyResource/QuiltyResourceForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";

@Component({
    components: {
        QuiltyResourceForm,
        ActionButton,
    },
})
export default class QuiltyResourceCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: QuiltyResourceCreateStore = this.container.get(QuiltyResourceCreateStore);
    readonly listStore: QuiltyResourceListStore = this.container.get(QuiltyResourceListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    media?: { resource?: File; picture?: File } = {};

    created() {
        this.createStore.data.organizationId = this.organizationId;
        if (!this.createStore.data.postDate) {
            this.createStore.data.postDate = new Date();
        }
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    async createQuiltyResource() {
        if (!this.media?.resource) {
            this.notificationStore.warning("Need to attach a PDF to create a quiltyResource");
            return;
        }

        this.createStore.quiltyResourceMedia.set("resource", this.media?.resource!);
        this.createStore.quiltyResourceMedia.set("picture", this.media?.picture!);

        await this.createStore.submit();

        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create an quiltyResource.");
        } else {
            this.notificationStore.success("You have created quiltyResource successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push(`/admin/organizations/${this.organizationId}/quilty-resources`);
        }
    }

    async sendTestEmail() {
        try {
            await this.api.admin.quiltyResources.sendEmail({
                ...this.createStore.data,
                distroList: ["quiltyResource test"],
            });
            this.notificationStore.success("Test email sent");
        } catch (err) {
            this.notificationStore.danger("Failed to send test email.");
        }
    }

    async sendEmail() {
        if (this.createStore.data.distroList?.length) {
            try {
                await this.api.admin.quiltyResources.sendEmail(this.createStore.data);
                this.notificationStore.success("Email sent");
            } catch (err) {
                this.notificationStore.danger("Failed to send email.");
            }
        } else {
            this.notificationStore.warning("No distribution list for quiltyResource");
        }
    }
}
