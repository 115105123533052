
import { Component, Vue, Inject } from "vue-property-decorator";
import ScheduleSessionModal from "../molecules/scheduler/ScheduleSessionModal.vue";
import { AdvertisingQuoteModalStore } from "../../../common/src/advertising-quote/AdvertisingQuoteModalStore";
import AdvertisingQuoteModal from "../../../common/src/advertising-quote/AdvertisingQuoteModal.vue";
import { Container } from "inversify";
import { PaymentService } from "../utility/payment/PaymentService";
import { NotificationStore } from "../../../common/src/stores/NotificationStore";
import { OrderType } from "@gsx/common/src/types/models/order";
import { handleError } from "../../../common/src/utility/errorUtils";
import ActionButton from "../../../common/src/components/ActionButton.vue";
import SubscriptionQuoteModal from "../../../common/src/subscription-quote/SubscriptionQuoteModal.vue";
import { SubscriptionQuoteModalStore } from "../../../common/src/subscription-quote/SubscriptionQuoteModalStore";
import { TransactionType } from "@gsx/common";
import { event } from "vue-gtag";

@Component({
    components: {
        ScheduleSessionModal,
        ActionButton,
        AdvertisingQuoteModal,
        SubscriptionQuoteModal,
    },
})
export default class ConferenceView extends Vue {
    @Inject("container") readonly container!: Container;
    isScheduleSessionModalVisible = false;
    readonly advertisingQuoteModalStore: AdvertisingQuoteModalStore = this.container.get(
        AdvertisingQuoteModalStore,
    );
    isPurchasingBook: boolean = false;
    readonly paymentService: PaymentService = this.container.get(PaymentService);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly subscriptionQuoteModalStore: SubscriptionQuoteModalStore = this.container.get(
        SubscriptionQuoteModalStore,
    );

    mounted() {
        let vywidget = document.getElementById("vywidget");
        if (vywidget) {
            vywidget.style.visibility = "visible";
        }
    }

    beforeDestroy() {
        let vywidget = document.getElementById("vywidget");
        if (vywidget) {
            vywidget.style.visibility = "hidden";
        }
    }

    openScheduleSessionModal() {
        this.isScheduleSessionModalVisible = true;
    }

    hideScheduleSessionModal() {
        this.isScheduleSessionModalVisible = false;
    }

    async purchaseBook() {
        this.isPurchasingBook = true;

        try {
            await this.paymentService.purchaseProduct(TransactionType.SpaceInsight2020);
            event("Purchase", {
                eventCategory: "Purchase",
                eventAction: OrderType.SpaceInsight2020,
                eventLabel: OrderType.SpaceInsight2020,
            });
        } catch (e: any) {
            this.notificationStore.warning("Could not initialize a payment process.");
            handleError(e);
        } finally {
            this.isPurchasingBook = false;
        }
    }

    openSubscriptionQuoteModal() {
        this.subscriptionQuoteModalStore.open();
    }

    openAdvertisingQuoteRequestModal() {
        this.advertisingQuoteModalStore.open();
    }
}
