
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import NotificationContainer from "./molecules/notification/NotificationContainer.vue";
import Layout from "./molecules/layout/Layout.vue";
import LayoutThirdParty from "./molecules/layout/LayoutThirdParty.vue";
import { AuthStore } from "../../common/src/stores/AuthStore";

const AUTH_KEY = "access";
const OK_VALUE = "ok";

@Component({
    components: { Layout, NotificationContainer, LayoutThirdParty },
})
export default class App extends Vue {
    @Inject("container") container!: Container;
    authStore: AuthStore = this.container.get(AuthStore);
    authorized = localStorage.getItem(AUTH_KEY) === OK_VALUE;

    get shouldGuard(): boolean {
        return !!process.env.SITE_PASSWORD && !this.authorized && !this.authStore.authorized;
    }

    checkPassword(e: any) {
        if (e.target.value === process.env.SITE_PASSWORD) {
            localStorage.setItem(AUTH_KEY, OK_VALUE);
            this.authorized = true;
        }
    }
    get checkThirdPartyUrl() {
        const host = window.location.host;
        const parts = host.split(".");
        return parts[0] === "quilty"; //CHANGE TO CONFIG VARIABLE
    }
}
