
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import NoRecords from "../../../../../../common/src/components/NoRecords.vue";
import { OrganizationLogListStore } from "../../../../stores/admin/organization/OrganizationLogListStore";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import { debounce } from "debounce";

@Component({
    components: { NoRecords, LoadingOverlay },
})
export default class OrganizationLogView extends Vue {
    @Inject("container") readonly container!: Container;
    listStore: OrganizationLogListStore = this.container.get(OrganizationLogListStore);

    @Watch("listStore.query")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.listStore.init(this.organizationId).catch(handleError);
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }
}
