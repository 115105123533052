
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../../components/MoreActions.vue";
import AddPhoneNumberModal from "./AddPhoneNumberModal.vue";
import EditPhoneNumberModal from "./EditPhoneNumberModal.vue";
import { PhoneNumber } from "@gsx/common";

@Component({
    components: { AddPhoneNumberModal, EditPhoneNumberModal, MoreActions },
})
export default class OrganizationPhoneNumberTable extends Vue {
    @Prop({ default: () => [] }) value!: PhoneNumber[];
    isAddModalVisible: boolean = false;
    editedIndex: number | null = null;

    get formattedValue(): PhoneNumber[] {
        return this.value || [];
    }

    get isEditModalVisible(): boolean {
        return this.editedIndex !== null;
    }

    @Emit()
    input(value: PhoneNumber[]) {
        return value;
    }

    displayAddModal() {
        this.isAddModalVisible = true;
    }

    hideAddModal() {
        this.isAddModalVisible = false;
    }

    displayEditModal(index: number) {
        this.editedIndex = index;
    }

    hideEditModal() {
        this.editedIndex = null;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add(phoneNumber: PhoneNumber) {
        this.input([...this.formattedValue, phoneNumber]);
    }

    edit(phoneNumber: PhoneNumber) {
        if (this.editedIndex === null) {
            return;
        }

        const newValue = [...this.formattedValue];
        newValue[this.editedIndex] = phoneNumber;
        this.input(newValue);
    }
}
