
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import { toLocaleDateString } from "@gsx/common";
import { NotificationStore } from "../../../../common/src/stores/NotificationStore";
import { ProfilePictureStore } from "../../stores/member/profile/ProfilePictureStore";

@Component
export default class AuthorCardProfile extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly profilePictureStore: ProfilePictureStore = this.container.get(ProfilePictureStore);

    get joinDate(): string {
        return toLocaleDateString(this.authStore.user!.registrationDate) || "n/a";
    }

    get memberStatus(): string {
        return `GSX: ${
            this.authStore.user?.subscription?.plan && this.authStore.user?.subscription?.active
                ? "X2-P"
                : "X1-P"
        }`;
    }

    get quiltyMemberStatus(): string {
        if (
            this.authStore.user?.quiltySubscription?.plan &&
            this.authStore.user?.quiltySubscription?.active
        ) {
            return `QRP: ${this.authStore.user?.quiltySubscription.plan.toUpperCase()}`;
        } else {
            return "";
        }
    }

    profilePictureUploadInput() {
        let fileUploadInput = document.querySelector(".file-upload") as HTMLElement;
        if (fileUploadInput) {
            fileUploadInput.click();
        }
    }

    async submitProfilePicture(input: any) {
        if (input.target.files && input.target.files[0]) {
            this.profilePictureStore.data.set("logo", input.target.files[0]);
            await this.profilePictureStore.submit();

            if (this.profilePictureStore.failed) {
                this.notificationStore.warning("Updating profile picture failed.");
            } else {
                this.notificationStore.success("Profile picture has been updated.");
                // Reload logo
                await this.authStore.me();
            }
        }
    }
}
