import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
// @ts-ignore
import VueTelInput from "vue-tel-input";
// @ts-ignore
import Pagination from "../../vendor/Pagination";
import {
    capitalize,
    capitalizeWords,
    studly,
    toDateStringWeekday,
    toDateUtcString,
    toLocaleDateString,
    toLocaleDateTimeString,
} from "@gsx/common";
// @ts-ignore
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export const setup = () => {
    Vue.use(VueQuillEditor);
    Vue.use(VueRouter);
    Vue.use(Vuex);
    Vue.use(VueTelInput);
    Vue.component("v-pagination", Pagination);
    Vue.filter("capitalize", capitalize);
    Vue.filter("capitalizeWords", capitalizeWords);
    Vue.filter("studly", studly);
    Vue.filter("toDateUtcString", toDateUtcString);
    Vue.filter("toLocaleDateString", toLocaleDateString);
    Vue.filter("toLocaleDateTimeString", toLocaleDateTimeString);
    Vue.filter("toDateStringWeekday", toDateStringWeekday);
};
