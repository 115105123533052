import { injectable } from "inversify";
import { SubmitStore } from "../../../../common/src/stores/general/SubmitStore";
import { EmailVerificationBody } from "@gsx/common/src/types/http/guest/emailVerification";
import { marshal, Nullable } from "@gsx/common";
import { Api } from "../../../../common/src/api/Api";
import { RecaptchaService } from "../../../../common/src/utility/recaptcha/RecaptchaService";

@injectable()
export class EmailVerificationStore extends SubmitStore<EmailVerificationBody> {
    readonly data: Nullable<EmailVerificationBody> = {
        email: null,
    };

    constructor(private readonly api: Api, private readonly recaptchaService: RecaptchaService) {
        super();
    }

    protected async request(): Promise<void> {
        const recaptchaToken = await this.recaptchaService.verify("email_verification");
        const body = marshal(this.data);
        await this.api.guest.emailVerification(body, recaptchaToken);
    }
}
