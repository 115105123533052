
import { Container } from "inversify";
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Api } from "../../../../../common/src/api/Api";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import Modal from "../../../../../common/src/components/Modal.vue";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { CreateUser } from "../../../../../common/src/utility/confirmations";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import FormInput from "../../../molecules/form/FormInput.vue";
import { UserRepresentationDashboardListStore } from "../../../stores/admin/user/UserRepresentationDashboardListStore";

@Component({
    components: {
        ActionButton,
        Modal,
        FormInput,
    },
})
export default class InviteLicenseModal extends Vue {
    @Inject("container")
    readonly container!: Container;
    readonly api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly userListStore: UserRepresentationDashboardListStore = this.container.get(
        UserRepresentationDashboardListStore,
    );
    @Prop() userid!: string;

    data = {
        firstName: "",
        lastName: "",
        email: "",
    };

    style = {
        display: "block",
    };

    @Emit()
    submit() {
        //
    }

    @Emit()
    close() {
        //
    }

    async onSubmit() {
        this.close();
        const confirmAddUser = await CreateUser(this.data.email);
        if (confirmAddUser.value) {
            try {
                await this.api.admin.user.addToLicense(this.userid, this.data);
                this.notificationStore.success("License has been updated.");
                this.userListStore.reload().catch(handleError);
            } catch (err) {
                this.notificationStore.warning("License has failed.");
            }
        }
    }
}
