
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Report } from "@gsx/common";
import LoadingOverlay from "../../../../common/src/components/LoadingOverlay.vue";
import { Container } from "inversify";
import NoRecords from "../../../../common/src/components/NoRecords.vue";
import { CollectionStore } from "../../../../common/src/stores/CollectionStore";
import { enrichedColumns } from "../../utils/tableUtils";
import { ProductTypeStore } from "../../../../common/src/stores/ProductTypeStore";
import { handleError } from "../../../../common/src/utility/errorUtils";
import { ServiceTypeStore } from "../../../../common/src/stores/ServiceTypeStore";
import { isNthLevel } from "../../../../common/src/utility/treeUtils";
import { ReportListItem } from "@gsx/common/src/types/http/admin/report";
import { escapeRegExp } from "../../../../../server/src/utils";
// import ListStoreVuex from "../../../../common/src/stores/general/ListStoreVuex";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import { ReportListStore } from "../../../../landing/src/stores/admin/report/ReportListStore";

interface Column {
    id?: keyof Report;
    name: string;
}

@Component({
    components: { NoRecords, LoadingOverlay },
})
export default class ReportTable extends Vue {
    @Prop() collectionStore!: CollectionStore<ReportListItem>;
    // @Prop() listStore!:
    //     | (FilterableListStore<ReportListItem> & { query: string })
    //     | (ListStoreVuex<ReportListItem> & { query: string });
    @Inject("container") readonly container!: Container;
    readonly listStore: ReportListStore = this.container.get(ReportListStore);

    readonly productTypeStore: ProductTypeStore = this.container.get(ProductTypeStore);
    readonly serviceTypeStore: ServiceTypeStore = this.container.get(ServiceTypeStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    readonly columns: ReadonlyArray<Column> = [
        {
            id: "postDate",
            name: "Date",
        },
        {
            id: "title",
            name: "Title",
        },
        {
            id: "summary",
            name: "Summary",
        },
        {
            id: "picture",
            name: "Picture",
        },
    ];

    created() {
        this.collectionStore.changeSort("postDate");
        this.collectionStore.changeSort("postDate");
        this.productTypeStore.load().catch(handleError);
        this.serviceTypeStore.load().catch(handleError);
    }

    get enrichedColumns() {
        return enrichedColumns(this.collectionStore, this.columns);
    }

    get downloadedReports() {
        if (this.authStore.user && this.authStore.user.downloadedReports) {
            return this.authStore.user.downloadedReports.map((report) => report.reportId);
        }

        return [];
    }

    getProductTags(report: ReportListItem): string[] {
        return (report.products || [])
            .filter((tag) => isNthLevel(tag, 1))
            .map((tag) => this.productTypeStore.idLabelMapping.get(tag) || tag);
    }

    searchingTag(tag: string) {
        if (this.listStore.query) {
            const regexp = new RegExp(escapeRegExp(this.listStore.query.trim()), "i");
            if (regexp.test(tag)) {
                return "primary";
            }
        }

        return "secondary";
    }

    @Emit("rowSelect")
    rowSelect(report: ReportListItem) {
        return report;
    }

    getServiceTags(report: ReportListItem): string[] {
        return (report.services || [])
            .filter((tag) => isNthLevel(tag, 1))
            .map((tag) => this.serviceTypeStore.idLabelMapping.get(tag) || tag);
    }

    getAllTags(report: ReportListItem): { label: string; link?: string | undefined }[] {
        const productTags = this.getProductTags(report).map((tag) => ({ label: tag }));
        const serviceTags = this.getServiceTags(report).map((tag) => ({ label: tag }));
        const orgTags = (report.organizations || []).map((org) => ({
            label: org.organizationName,
            link: `organizations/${org.organizationId}`,
        }));
        const customTags = (report.tags || []).map((tag) => ({ label: tag }));
        return [...orgTags, ...productTags, ...serviceTags, ...customTags];
    }
}
