
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { OrganizationProfileBody, Nullable, AssessmentParameters } from "@gsx/common";
import { FormErrors } from "../../../../../common/src/form/types";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";

@Component({
    components: {
        InlineFormSimpleMultiSelect,
    },
})
export default class AssessmentParametersPane extends Vue {
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<AssessmentParameters>;

    @Inject("container") readonly container!: Container;

    numberRating = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    get assessmentParameters() {
        if (!this.values.assessmentParameters) {
            this.values.assessmentParameters = {} as AssessmentParameters;
        }
        return this.values.assessmentParameters;
    }
}
