
import { Component, Inject, Vue } from "vue-property-decorator";
import SubmitRequestModal from "../../../molecules/request-form/SubmitRequestModal.vue";
import { Container } from "inversify";
import { RequestListStore } from "../../../stores/member/requests/RequestListStore";
import FormSelect from "../../../molecules/form/FormSelect.vue";
import { RawRequest, RequestStatus } from "@gsx/common";
import { compare } from "../../../utility/utils";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";

@Component({
    components: { NoRecords, FormSelect, LoadingOverlay, SubmitRequestModal },
})
export default class RequestsView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: RequestListStore = this.container.get(RequestListStore);
    isModalVisible: boolean = false;
    sortOrder: RequestStatus | null = RequestStatus.Open;

    created() {
        this.listStore.load().catch(handleError);
    }

    get requestStatuses(): RequestStatus[] {
        return Object.values(RequestStatus);
    }

    get noRecords(): boolean {
        const items = this.listStore.items;
        return Boolean(items && !items.length);
    }

    get requests(): RawRequest[] | null {
        if (!this.listStore.items) {
            return null;
        }

        return [...this.listStore.items].sort((a: RawRequest, b: RawRequest) => {
            if (a.status === this.sortOrder && b.status === this.sortOrder) {
                return compare(a.submissionDate, b.submissionDate, false);
            }

            if (a.status === this.sortOrder) {
                return -1;
            }

            if (b.status === this.sortOrder) {
                return 1;
            }

            return compare(a.submissionDate, b.submissionDate, false);
        });
    }

    badgeClass(request: RawRequest) {
        return {
            "badge-accent": request.status === RequestStatus.Open,
            "badge-danger": request.status === RequestStatus.Closed,
        };
    }

    showModal() {
        this.isModalVisible = true;
    }

    closeModal() {
        this.isModalVisible = false;
    }

    onSubmitRequest() {
        this.closeModal();
        this.listStore.reload().catch(handleError);
    }
}
