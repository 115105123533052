
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { OrganizationEditStore } from "../../../../stores/admin/organization/OrganizationEditStore";
import { OrganizationMergerAcquisitionsEditStore } from "../../../../stores/admin/organization/OrganizationMergerAcquisitionsEditStore";
import { Container } from "inversify";
import OrganizationProfileForm from "../../../../molecules/organization/profile/OrganizationProfileForm.vue";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import NotFound from "../../../../molecules/components/NotFound.vue";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import { QuiltyResourceListStore } from "../../../../stores/admin/quiltyResource/QuiltyResourceListStore";

@Component({
    components: { NotFound, LoadingOverlay, OrganizationProfileForm },
})
export default class OrganizationEditProfileView extends Vue {
    @Prop() editStore!: OrganizationEditStore;
    @Prop() organizationMergerAcquisitionsEditStore!: OrganizationMergerAcquisitionsEditStore;
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    quiltyResourceListStore: QuiltyResourceListStore = this.container.get(QuiltyResourceListStore);

    get organizationId(): string {
        return this.$route.params.organization;
    }

    async updateOrganization() {
        await this.organizationMergerAcquisitionsEditStore.update();
        await this.editStore.update();

        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Organization update failed.");
        } else {
            if (this.organizationMergerAcquisitionsEditStore.hasUpdatingFailed) {
                this.notificationStore.warning("Mergers/Acquisitions update failed.");
            } else {
                this.notificationStore.success("You have updated organization successfully.");
            }
        }
    }
}
