
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../../components/MoreActions.vue";
import AddEmailModal from "./AddEmailModal.vue";
import EditEmailModal from "./EditEmailModal.vue";
import { Email } from "@gsx/common";

@Component({
    components: { AddEmailModal, EditEmailModal, MoreActions },
})
export default class OrganizationEmailTable extends Vue {
    @Prop({ default: () => [] }) value!: Email[];
    isAddModalVisible: boolean = false;
    editedIndex: number | null = null;

    get formattedValue(): Email[] {
        return this.value || [];
    }

    get isEditModalVisible(): boolean {
        return this.editedIndex !== null;
    }

    @Emit()
    input(value: Email[]) {
        return value;
    }

    displayAddModal() {
        this.isAddModalVisible = true;
    }

    hideAddModal() {
        this.isAddModalVisible = false;
    }

    displayEditModal(index: number) {
        this.editedIndex = index;
    }

    hideEditModal() {
        this.editedIndex = null;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add(email: Email) {
        this.input([...this.formattedValue, email]);
    }

    edit(email: Email) {
        if (this.editedIndex === null) {
            return;
        }

        const newValue = [...this.formattedValue];
        newValue[this.editedIndex] = email;
        this.input(newValue);
    }
}
