
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { ChangePasswordStore } from "../../../stores/member/security/ChangePasswordStore";
import FormInput from "../../../molecules/form/FormInput.vue";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { focusOnFirstInvalidInput } from "../../../../../common/src/form/utils";
import { PASSWORD_HELP } from "../../../molecules/form/constants";

@Component({
    components: { ActionButton, FormInput },
})
export default class SecurityView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly changePasswordStore: ChangePasswordStore = this.container.get(ChangePasswordStore);
    updatedSuccessfully: boolean = false;

    get values() {
        return this.changePasswordStore.data;
    }

    get errors() {
        return this.changePasswordStore.formErrors;
    }

    get PASSWORD_HELP() {
        return PASSWORD_HELP;
    }

    async updatePassword() {
        await this.changePasswordStore.submit();

        if (this.changePasswordStore.failed) {
            this.notificationStore.warning("Password change failed.");
            focusOnFirstInvalidInput();
        } else {
            this.changePasswordStore.clear();
            this.notificationStore.success("Password has been successfully updated.");
            this.updatedSuccessfully = true;
        }
    }
}
