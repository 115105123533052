import { Nullable, QuiltyResource } from "@gsx/common";
import get from "lodash.get";
import { QuiltyResourceBody } from "@gsx/common/src/types/http/admin/quiltyResource";

export const createQuiltyResourceEmptyBody = (
    data?: Partial<QuiltyResource>,
): Nullable<QuiltyResourceBody> => ({
    applicationTags: get(data, "applicationTags", null),
    distroList: get(data, "distroList", null),
    editedDate: get(data, "editedDate", null),
    highlightBullets: get(data, "highlightBullets", null),
    isPublished: get(data, "isPublished", null),
    isRevised: get(data, "isRevised", null),
    metadata: get(data, "metadata", null),
    operatingRegimeTags: get(data, "operatingRegimeTags", null),
    organizationId: get(data, "organizationId", null),
    organizations: get(data, "organizations", null),
    picture: get(data, "picture", null),
    postDate: get(data, "postDate", null),
    products: get(data, "products", null),
    resource: get(data, "pdf", null),
    services: get(data, "services", null),
    summary: get(data, "summary", null),
    tags: get(data, "tags", null),
    title: get(data, "title", null),
});
