
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { QuiltyInsightsCreateStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsCreateStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { QuiltyInsightsListStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import QuiltyInsightsForm from "../../../molecules/quiltyInsights/QuiltyInsightsForm.vue";
@Component({
    components: {
        QuiltyInsightsForm,
    },
})
export default class QuiltyInsightsCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: QuiltyInsightsCreateStore = this.container.get(QuiltyInsightsCreateStore);
    readonly listStore: QuiltyInsightsListStore = this.container.get(QuiltyInsightsListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    media?: { picture?: File } = {};
    async createQuiltyInsights() {
        this.createStore.quiltyInsightsMedia.set("picture", this.media?.picture!);
        await this.createStore.submit();
        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create an flash news.");
        } else {
            this.notificationStore.success("You have created flash news successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/admin/quiltyInsights");
        }
    }
}
