
import { Component, Vue } from "vue-property-decorator";
import NotFound from "../molecules/components/NotFound.vue";

@Component({
    components: { NotFound },
})
export default class NotFoundView extends Vue {
    //
}
