
import { YearlyFinancialType } from "@gsx/common";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import FormInput from "../../../form/FormInput.vue";
import FormSimpleMultiSelect from "../../../form/FormSimpleMultiSelect.vue";

@Component({
    components: { FormInput, FormSimpleMultiSelect },
})
export default class YearlyFinancialTable extends Vue {
    @Prop({ default: () => [] }) value!: {
        year: string;
        type: string;
        revenue: string;
        grossProfit: string;
        ebitda: string;
        capex: string;
        ebitdaMargin: string;
    }[];

    get formattedValue(): {
        year: string;
        type: string;
        revenue: string;
        grossProfit: string;
        ebitda: string;
        capex: string;
        ebitdaMargin: string;
    }[] {
        return this.value || [];
    }

    @Emit()
    input(
        value: {
            year: string;
            type: string;
            revenue: string;
            grossProfit: string;
            ebitda: string;
            capex: string;
            ebitdaMargin: string;
        }[],
    ) {
        return value;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add() {
        this.input([
            ...this.formattedValue,
            {
                year: "",
                type: "",
                revenue: "",
                grossProfit: "",
                ebitda: "",
                capex: "",
                ebitdaMargin: "",
            },
        ]);
    }

    get yearlyFinancialType(): string[] {
        return Object.values(YearlyFinancialType);
    }
}
