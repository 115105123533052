
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import InlineFormInput from "../form/InlineFormInput.vue";
import InlineFormTextarea from "../form/InlineFormTextarea.vue";
import InlineFormBoolSelect from "../form/InlineFormBoolSelect.vue";
import { Nullable } from "@gsx/common";
import { FormErrors } from "../../../../common/src/form/types";
import InlineFormMultiSelect from "../form/InlineFormMultiSelect.vue";
import InlineFormSimpleMultiSelect from "../form/InlineFormSimpleMultiSelect.vue";
import InlineFormTreeselect from "../form/InlineFormTreeselect.vue";
import { FlashNewsBody } from "@gsx/common/src/types/http/admin/flashNews";
import { OrganizationListStore } from "../../stores/organization/OrganizationListStore";
import { SelectOption } from "../form/types";
import { Container } from "inversify";
import { ProductTypeStore } from "../../../../common/src/stores/ProductTypeStore";
import { ServiceTypeStore } from "../../../../common/src/stores/ServiceTypeStore";
import { handleError } from "../../../../common/src/utility/errorUtils";
import ReportBulletPointTable from "../report/bulletpoints/ReportBulletPointTable.vue";
import { OperatingRegimeTypeStore } from "../../../../common/src/stores/OperatingRegimeTypeStore";
import { ApplicationTypeStore } from "../../../../common/src/stores/ApplicationTypeStore";
import InlineFormDate from "../form/InlineFormDate.vue";
@Component({
    components: {
        InlineFormMultiSelect,
        InlineFormSimpleMultiSelect,
        InlineFormBoolSelect,
        InlineFormTextarea,
        InlineFormInput,
        InlineFormTreeselect,
        InlineFormDate,
        ReportBulletPointTable,
    },
})
export default class FlashNewsGeneralPane extends Vue {
    @Prop() values!: Nullable<FlashNewsBody>;
    @Prop() errors!: FormErrors<FlashNewsBody>;
    @Inject("container") readonly container!: Container;
    readonly organizationListStore: OrganizationListStore =
        this.container.get(OrganizationListStore);
    readonly productTypeStore: ProductTypeStore = this.container.get(ProductTypeStore);
    readonly serviceTypeStore: ServiceTypeStore = this.container.get(ServiceTypeStore);
    readonly operatingRegimeTypeStore: OperatingRegimeTypeStore =
        this.container.get(OperatingRegimeTypeStore);
    readonly applicationTypeStore: ApplicationTypeStore = this.container.get(ApplicationTypeStore);

    created() {
        this.organizationListStore.load().catch(handleError);
        this.productTypeStore.load().catch(handleError);
        this.serviceTypeStore.load().catch(handleError);
        this.operatingRegimeTypeStore.load().catch(handleError);
        this.applicationTypeStore.load().catch(handleError);
    }
    get serviceOptions() {
        return this.serviceTypeStore.item || [];
    }
    get productOptions() {
        return this.productTypeStore.item || [];
    }

    get operatingRegimeOptions() {
        return this.operatingRegimeTypeStore.item || [];
    }

    get applicationOptions() {
        return this.applicationTypeStore.item || [];
    }

    get organizations(): SelectOption[] {
        return this.organizationListStore.items
            ? this.organizationListStore.items.map((org) => ({
                  name: org.organizationName,
                  id: org.organizationid,
              }))
            : [];
    }

    get flashNewsOrganizationNames() {
        return (
            this.values.organizationNames?.map((organization) => ({
                name: organization.organizationName,
                id: organization.organizationId,
            })) || []
        );
    }

    selectOrganizationName(option: SelectOption[]): void {
        this.values.organizationNames = option.map((option) => {
            return {
                organizationName: option.name,
                organizationId: option.id,
            };
        });
    }
}
