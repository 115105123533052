import { Api } from "../../../../../common/src/api/Api";
import { Action, Module, RegisterOptions, VuexModule } from "vuex-class-modules";
import { OrganizationFileItem } from "@gsx/common/src/types/models/organizationFile";

@Module({ generateMutationSetters: true })
export class FileListStore extends VuexModule {
    organizationId: string = "";
    isLoading: boolean = false;
    isFailed: boolean = false;
    items: OrganizationFileItem[] | null = null;

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    @Action
    async load(organizationId: string): Promise<void> {
        if (this.organizationId !== organizationId) {
            this.organizationId = organizationId;
            this.items = null;
            await this.reload();
        }
    }

    @Action
    async reload(): Promise<void> {
        try {
            this.isFailed = false;
            this.isLoading = true;
            this.items = await this.api.admin.organization.file.list(this.organizationId);
        } catch (e) {
            this.isFailed = true;
            throw e;
        } finally {
            this.isLoading = false;
        }
    }

    @Action
    async upload(files: File[]): Promise<void> {
        try {
            this.isLoading = true;
            await this.api.admin.organization.file.upload(this.organizationId, files);
        } finally {
            this.isLoading = false;
        }

        await this.reload();
    }

    @Action
    async remove(fileId: string): Promise<void> {
        try {
            this.isLoading = true;
            await this.api.admin.organization.file.remove(this.organizationId, fileId);
        } finally {
            this.isLoading = false;
        }

        await this.reload();
    }
}
