
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Nullable } from "@gsx/common";
import { FormErrors } from "../../../../common/src/form/types";
import { QuiltyResourceBody } from "@gsx/common/src/types/http/admin/quiltyResource";
import { Container } from "inversify";
import FormLabel from "../form/FormLabel.vue";

@Component({
    components: {
        FormLabel,
    },
})
export default class QuiltyResourcePicturePane extends Vue {
    @Prop() values!: Nullable<QuiltyResourceBody>;
    @Prop() errors!: FormErrors<QuiltyResourceBody>;
    @Inject("container") readonly container!: Container;
    @Prop() value?: { pdf?: File; picture?: File };
    tempPicture: any = null;

    mounted() {
        this.convertFileToData(this.value?.picture!);
    }

    @Emit()
    input(file: File | null) {
        return { ...this.value, picture: file };
    }

    changeQuiltyResourcePicture(event: any) {
        const target: HTMLInputElement = event.target;
        const file = target.files && target.files[0];
        this.input(file);
        this.convertFileToData(file);
    }

    convertFileToData(file: File | null) {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.tempPicture = e.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            this.tempPicture = null;
        }
    }
}
