import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../common/src/api/Api";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { MergerAcquisitionBody } from "@gsx/common/src/types/http/admin/mergerAcquisition";
import { createMergerAcquisitionEmptyBody } from "../../../utility/entity/mergerAcquisition";

@injectable()
export class MergerAcquisitionsCreateStore extends SubmitStore<MergerAcquisitionBody> {
    readonly data: Nullable<MergerAcquisitionBody> = createMergerAcquisitionEmptyBody();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.mergerAcquisition.create(body);
    }
}
