
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
    // https://stackoverflow.com/a/50456738/4475823
    scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
}
