
import { Component, Inject, Vue, Ref } from "vue-property-decorator";
import { Container } from "inversify";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { OrganizationListStore } from "../../../stores/organization/OrganizationListStore";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import InlineFormInput from "../../../molecules/form/InlineFormInput.vue";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import InlineFormMultiSelect from "../../../molecules/form/InlineFormMultiSelect.vue";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { isAdmin, QuiltySubscriptionPlanType } from "@gsx/common";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";

@Component({
    components: {
        MoreActions,
        LoadingOverlay,
        InlineFormInput,
        NoRecords,
        ActionButton,
        InlineFormMultiSelect,
    },
})
export default class AccountCreationView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly organizationListStore: OrganizationListStore =
        this.container.get(OrganizationListStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    @Ref("organizationLogo") organizationLogo!: HTMLInputElement;

    registeredUserData: {
        firstName: string;
        lastName: string;
        email: string;
        organization?: {
            name: string;
            id?: string;
        };
    } = {
        firstName: "",
        lastName: "",
        email: "",
    };

    registeredUserFileData = new FormData();

    chosenSubscription: { id: SubscriptionPlanType; name: string } | { id: "free"; name: "Free" } =
        { id: "free", name: "Free" };
    chosenQuiltySubscription:
        | { id: QuiltySubscriptionPlanType; name: string }
        | { id: "free"; name: "Free" } = { id: "free", name: "Free" };

    created() {
        this.organizationListStore.load().catch(handleError);
    }

    reload() {}

    get organizationOptions() {
        return (this.organizationListStore.items || []).map((organization) => ({
            id: organization.organizationid,
            name: organization.organizationName,
        }));
    }

    get hasAdminAccess() {
        const user = this.authStore.user;
        return isAdmin(user);
    }

    changeUserLogo(event: any) {
        const target: HTMLInputElement = event.target;
        const file = target.files && target.files[0];
        this.registeredUserFileData.set("spaceSymposiumPicture", file as any);
    }

    addToOrganization(event: any) {
        if (event.new) {
            this.registeredUserData.organization = {
                name: event.name,
            };
        } else {
            this.registeredUserData.organization = {
                id: event.id,
                name: event.name,
            };
        }
    }

    get subscriptionNames() {
        return SubscriptionPlanType;
    }

    get quiltySubscriptionNames() {
        return QuiltySubscriptionPlanType;
    }

    async submit() {
        if (
            !this.registeredUserData.firstName ||
            this.registeredUserData.firstName === "" ||
            !this.registeredUserData.lastName ||
            this.registeredUserData.lastName === "" ||
            !this.registeredUserData.email ||
            this.registeredUserData.email === ""
        ) {
            this.notificationStore.warning("Incomplete form.");
            return;
        }

        try {
            const createdUserId = await this.api.admin.user.createUser(
                this.registeredUserData,
                this.registeredUserFileData,
            );

            try {
                if (createdUserId) {
                    if (this.chosenSubscription && this.chosenSubscription.id !== "free") {
                        await this.api.admin.user.createSubscription(
                            createdUserId,
                            this.chosenSubscription.id,
                        );
                    }

                    if (
                        this.chosenQuiltySubscription &&
                        this.chosenQuiltySubscription.id !== "free"
                    ) {
                        await this.api.admin.user.createQuiltySubscription(
                            createdUserId,
                            this.chosenQuiltySubscription.id,
                        );
                    }
                }
            } catch (err) {
                this.notificationStore.danger("Subscription creation failed.");
            }

            this.registeredUserData = {
                firstName: "",
                lastName: "",
                email: "",
            };
            this.registeredUserFileData.delete("spaceSymposiumPicture");
            this.chosenQuiltySubscription = { id: "free", name: "Free" };
            this.chosenSubscription = { id: "free", name: "Free" };
            this.notificationStore.success("Created successfully.");
        } catch (er) {
            this.notificationStore.warning("Creation failed.");
        }
    }
}
