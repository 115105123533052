import {
    FlashNewsListItem,
    FlashNewsListResponse,
} from "@gsx/common/src/types/http/admin/flashNews";
import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module({ generateMutationSetters: true })
export class FlashNewsListStore extends ListStoreVuex<FlashNewsListItem> {
    query: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
        this.pageSize = 5;
    }

    protected async fetch(): Promise<FlashNewsListResponse> {
        return this.api.admin.flashNews.list({
            ...this.queryParams,
            query: this.query,
        });
    }
}
