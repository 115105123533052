
import { Component, Prop, Vue } from "vue-property-decorator";
import InlineFormInput from "../../form/InlineFormInput.vue";
import InlineFormSelect from "../../form/InlineFormSelect.vue";
import InlineFormTextarea from "../../form/InlineFormTextarea.vue";
import {
    InitFunding,
    Nullable,
    OrganizationProfileBody,
    OrganizationType,
    OwnershipType,
    SubsidiaryType,
} from "@gsx/common";
import InlineFormBoolSelect from "../../form/InlineFormBoolSelect.vue";
import { FormErrors } from "../../../../../common/src/form/types";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";
import InlineFormCurrency from "../../form/InlineFormCurrency.vue";

@Component({
    components: {
        InlineFormCurrency,
        InlineFormSimpleMultiSelect,
        InlineFormBoolSelect,
        InlineFormTextarea,
        InlineFormSelect,
        InlineFormInput,
    },
})
export default class StructureFinancialsPane extends Vue {
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<OrganizationProfileBody>;

    get organizationTypes(): OrganizationType[] {
        return Object.values(OrganizationType);
    }

    get initFundings(): InitFunding[] {
        return Object.values(InitFunding);
    }

    get ownershipTypes(): OwnershipType[] {
        return Object.values(OwnershipType);
    }

    get subsidiaryTypes(): SubsidiaryType[] {
        return Object.values(SubsidiaryType);
    }
}
