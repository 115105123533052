
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import FormInput from "../../../../molecules/form/FormInput.vue";
import { EmailVerificationStore } from "../../../../stores/sign-up/EmailVerificationStore";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { Nullable, SignUpBody, UserRoleInOrganization } from "@gsx/common";
import FormEnumMultiSelect from "../../../../molecules/form/FormEnumMultiSelect.vue";
import { SignUpStore } from "../../../../stores/sign-up/SignUpStore";
@Component({
    components: {
        ActionButton,
        FormInput,
        FormEnumMultiSelect,
    },
})
export default class ProfileSignupStep extends Vue {
    @Inject("container") readonly container!: Container;
    readonly emailVerificationStore: EmailVerificationStore =
        this.container.get(EmailVerificationStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly signUpStore: SignUpStore = this.container.get(SignUpStore);

    terms: boolean = false;
    @Prop() value!: Nullable<SignUpBody>;

    get UserRoleInOrganization() {
        return UserRoleInOrganization;
    }

    async getVerifcationCode(): Promise<void> {
        if (this.emailVerificationStore.isLoading) {
            return;
        }

        this.emailVerificationStore.data.email = this.value.email;
        await this.emailVerificationStore.submit();
        if (this.emailVerificationStore.failed) {
            this.notificationStore.warning("Failed to send email with verification code.");
        } else {
            this.notificationStore.success("Verification code has been sent.");
        }
    }

    @Emit()
    submit() {
        //
    }
}
