import { marshal, Nullable, PastProjectItem } from "@gsx/common";
import { injectable } from "inversify";
import { SubmitStore } from "../../../../../../common/src/stores/general/SubmitStore";
import { Api } from "../../../../../../common/src/api/Api";
import { createPastProjectEmptyBody } from "../../../../utility/entity/pastProject";

@injectable()
export class PastProjectCreateStore extends SubmitStore<PastProjectItem> {
    readonly data: Nullable<PastProjectItem> = createPastProjectEmptyBody();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.user.pastProject.create(body);
    }
}
