
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { QuiltyResourceEditStore } from "../../../stores/admin/quiltyResource/QuiltyResourceEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";

@Component
export default class QuiltyResourcesEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: QuiltyResourceEditStore = this.container.get(QuiltyResourceEditStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    created() {
        this.editStore.setId(this.quiltyResourceId);
        this.editStore.load().catch(handleError);
    }

    get quiltyResourceId(): string {
        return this.$route.params.quiltyResource;
    }

    get generalUrl(): string {
        return `/admin/quiltyResources/${this.quiltyResourceId}/general`;
    }

    get mediaUrl(): string {
        return `/admin/quiltyResources/${this.quiltyResourceId}/media`;
    }
}
