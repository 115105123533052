import { marshal } from "@gsx/common";
import { injectable } from "inversify";
import { EditStore } from "../../general/EditStore";
import { Api } from "../../../../../common/src/api/Api";
import { MergerAcquisitionBody } from "@gsx/common/src/types/http/admin/mergerAcquisition";

// EditStoreVuex is not used here, because components are modifying
// data properties directly. That behaviour is not supported by vuex.
// The whole data would have to be replaced instead.

@injectable()
export class OrganizationMergerAcquisitionsEditStore extends EditStore<{
    list: MergerAcquisitionBody[];
}> {
    private id: string = "";
    readonly data: { list: MergerAcquisitionBody[] } = { list: [] };

    constructor(private readonly api: Api) {
        super();
    }

    public setId(value: string): void {
        this.id = value;
    }

    protected async loadRequest(): Promise<{ list: MergerAcquisitionBody[] }> {
        return { list: await this.api.admin.organization.getMergerAcquisitions(this.id) };
    }

    protected async updateRequest(): Promise<void> {
        const body = marshal(this.data.list);
        await this.api.admin.organization.updateMergerAcquisitions(this.id, body);
    }
}
