import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../common/src/api/Api";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { QuiltyResourceBody } from "@gsx/common/src/types/http/admin/quiltyResource";
import { createQuiltyResourceEmptyBody } from "../../../utility/entity/quiltyResource";

@injectable()
export class QuiltyResourceCreateStore extends SubmitStore<QuiltyResourceBody> {
    readonly data: Nullable<QuiltyResourceBody> = createQuiltyResourceEmptyBody();
    quiltyResourceMedia: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.quiltyResources.create(body, this.quiltyResourceMedia);
    }
}
