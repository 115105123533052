
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../../components/MoreActions.vue";
import AddOrganizationPointOfContactModal from "../point-of-contact/AddOrganizationPointOfContactModal.vue";
import EditOrganizationPointOfContactModal from "../point-of-contact/EditOrganizationPointOfContactModal.vue";
import { PointOfContact } from "@gsx/common";

@Component({
    components: {
        AddOrganizationPointOfContactModal,
        EditOrganizationPointOfContactModal,
        MoreActions,
    },
})
export default class OrganizationPointOfContactTable extends Vue {
    @Prop({ default: () => [] }) value!: PointOfContact[];
    isAddModalVisible: boolean = false;
    editedIndex: number | null = null;

    get formattedValue(): PointOfContact[] {
        return this.value || [];
    }

    get isEditModalVisible(): boolean {
        return this.editedIndex !== null;
    }

    @Emit()
    input(value: PointOfContact[]) {
        return value;
    }

    displayAddModal() {
        this.isAddModalVisible = true;
    }

    hideAddModal() {
        this.isAddModalVisible = false;
    }

    displayEditModal(index: number) {
        this.editedIndex = index;
    }

    hideEditModal() {
        this.editedIndex = null;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add(pointOfContact: PointOfContact) {
        this.input([...this.formattedValue, pointOfContact]);
    }

    edit(pointOfContact: PointOfContact) {
        if (this.editedIndex === null) {
            return;
        }

        const newValue = [...this.formattedValue];
        newValue[this.editedIndex] = pointOfContact;
        this.input(newValue);
    }
}
