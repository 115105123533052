
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import FormInput from "../../../../molecules/form/FormInput.vue";
import { EmailVerificationStore } from "../../../../stores/sign-up/EmailVerificationStore";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { Api } from "../../../../../../common/src/api/Api";
import { RecaptchaService } from "../../../../../../common/src/utility/recaptcha/RecaptchaService";
import { Nullable, SignUpBody } from "@gsx/common";

@Component({
    components: {
        ActionButton,
        FormInput,
    },
})
export default class EmailSignupStep extends Vue {
    @Inject("container") readonly container!: Container;
    readonly emailVerificationStore: EmailVerificationStore =
        this.container.get(EmailVerificationStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    private readonly api: Api = this.container.get(Api);
    private readonly recaptchaService: RecaptchaService = this.container.get(RecaptchaService);
    terms: boolean = false;
    loading = false;

    showWhyText: boolean = false;

    @Prop() value!: Nullable<SignUpBody>;

    async submit() {
        this.loading = true;
        const recaptchaToken = await this.recaptchaService.verify("emailExists");

        if (this.value.email) {
            if (!(await this.api.guest.emailExists(this.value.email, recaptchaToken))) {
                await this.getVerifcationCode();
                this.$emit("submit");
            } else {
                this.notificationStore.warning("Email already in use.");
            }
        }

        this.loading = false;
    }

    async getVerifcationCode(): Promise<void> {
        if (this.emailVerificationStore.isLoading) {
            return;
        }

        this.emailVerificationStore.data.email = this.value.email;
        await this.emailVerificationStore.submit();
        if (this.emailVerificationStore.failed) {
            this.notificationStore.warning("Failed to send email with verification code.");
        } else {
            this.notificationStore.success("Verification code has been sent.");
        }
    }
}
