
import { Component, Inject, Vue } from "vue-property-decorator";
import { PastProjectListStore } from "../../../../stores/member/organization/past-project/PastProjectListStore";
import { Container } from "inversify";
import { PastProjectDestroyStore } from "../../../../stores/member/organization/past-project/PastProjectDestroyStore";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import MoreActions from "../../../../molecules/components/MoreActions.vue";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import { PastProjectListItem } from "@gsx/common/src/types/http/user/organization/pastProject";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import NoRecords from "../../../../../../common/src/components/NoRecords.vue";
import { Confirm } from "../../../../../../common/src/utility/confirmations";

@Component({
    components: { NoRecords, MoreActions, ActionButton, LoadingOverlay },
})
export default class PastProjectListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: PastProjectListStore = this.container.get(PastProjectListStore);
    readonly destroyStore: PastProjectDestroyStore = this.container.get(PastProjectDestroyStore);

    created() {
        this.listStore.load().catch(handleError);
    }

    getStatus(pastProject: PastProjectListItem): string {
        return pastProject.dateCompleted ? "Completed" : "In-Work";
    }

    async destroy(id: string) {
        const result = await Confirm.fire();

        if (!result.value) {
            return;
        }

        await this.destroyStore.delete(id);

        if (this.destroyStore.failed) {
            this.notificationStore.warning("Could not delete past project.");
        } else {
            this.notificationStore.success("You have deleted past project successfully.");
            this.listStore.reload().catch(handleError);
        }
    }
}
