import VueRouter, { RouteConfig } from "vue-router";
import NotFoundView from "./views/NotFoundView.vue";
import { Container } from "inversify";
import { createAdminRoutes } from "./routes/admin";
import { createMemberRoutes } from "./routes/member";
import { scrollBehavior } from "../../common/src/utility/routerUtils";
import { SidebarStore } from "../../common/src/stores/SidebarStore";
import { createThirdpartyGuestRoutes } from "./routes/guest/thirdpartyGuest";
import { createBaseGuestRoutes } from "./routes/guest/baseGuest";
import { createGSXGuestRoutes } from "./routes/guest/gsxGuest";
import { createThirdPartyAdminRoutes } from "./routes/thirdPartyAdmin";

export const createRouter = (container: Container): VueRouter => {
    const routes: RouteConfig[] = [
        ...getSubdomainRoutes(),
        ...createBaseGuestRoutes(),
        ...createAdminRoutes(container),
        ...createThirdPartyAdminRoutes(container),
        ...createMemberRoutes(container),
        { path: "*", component: NotFoundView },
    ];

    function getSubdomainRoutes(): RouteConfig[] {
        const host = window.location.host;
        const parts = host.split(".");

        return parts[0] === "quilty" // CHANGE TO CONFIG VARIABLE
            ? createThirdpartyGuestRoutes(container)
            : createGSXGuestRoutes(container);
    }

    const router = new VueRouter({
        mode: "history",
        routes,
        linkActiveClass: "active",
        scrollBehavior,
    });

    router.afterEach(() => {
        const sidebarStore: SidebarStore = container.get(SidebarStore);
        sidebarStore.close();
    });

    return router;
};
