
import { Component, Inject, Vue } from "vue-property-decorator";
import FormInput from "../../../../molecules/form/FormInput.vue";
import FormEnumMultiSelect from "../../../../molecules/form/FormEnumMultiSelect.vue";
import { Container } from "inversify";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { focusOnFirstInvalidInput } from "../../../../../../common/src/form/utils";
import { PastProjectCreateStore } from "../../../../stores/member/organization/past-project/PastProjectCreateStore";
import FormTextarea from "../../../../molecules/form/FormTextarea.vue";
import { PastProjectListStore } from "../../../../stores/member/organization/past-project/PastProjectListStore";
import FormSimpleMultiSelect from "../../../../molecules/form/FormSimpleMultiSelect.vue";
import PastProjectForm from "../../../../molecules/organization/past-projects/PastProjectForm.vue";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";

@Component({
    components: {
        PastProjectForm,
        FormSimpleMultiSelect,
        FormTextarea,
        ActionButton,
        FormEnumMultiSelect,
        FormInput,
    },
})
export default class PastProjectCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: PastProjectListStore = this.container.get(PastProjectListStore);
    readonly createStore: PastProjectCreateStore = this.container.get(PastProjectCreateStore);

    async submit() {
        await this.createStore.submit();

        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create past project.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have created past project successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/member/organization/past-projects");
        }
    }
}
