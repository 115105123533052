
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Nullable, PhoneNumber, PhoneNumberType } from "@gsx/common";
import FormSelect from "../../../form/FormSelect.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import Modal from "../../../../../../common/src/components/Modal.vue";
import FormTel from "../../../form/FormTel.vue";
import FormTextarea from "../../../form/FormTextarea.vue";

@Component({
    components: {
        FormTextarea,
        FormTel,
        ActionButton,
        FormSelect,
        Modal,
    },
})
export default class EditPhoneNumberModal extends Vue {
    @Prop() value!: Nullable<PhoneNumber>;

    comments: string | null = this.value.comments;
    phoneNumber: string | null = this.value.phoneNumber;
    type: PhoneNumberType | null = this.value.type;

    get phoneNumberTypes(): PhoneNumberType[] {
        return Object.values(PhoneNumberType);
    }

    @Emit()
    submit(): Nullable<PhoneNumber> {
        return {
            comments: this.comments,
            type: this.type,
            phoneNumber: this.phoneNumber,
        };
    }

    @Emit()
    close() {
        //
    }

    async onSubmit() {
        this.submit();
        this.close();
    }
}
