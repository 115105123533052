
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import { UserRepresentationDashboardListStore } from "../../../stores/admin/user/UserRepresentationDashboardListStore";
import { UserCollectionStore } from "../../../stores/admin/user/UserCollectionStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { debounce } from "@fullcalendar/core";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { toLocaleDateString, RawUser, isAdmin, isThirdpartyAdmin } from "@gsx/common";
import { OrganizationListStore } from "../../../stores/organization/OrganizationListStore";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import InviteLicenseModal from "./InviteLicenseModal.vue";
import { DeleteUser } from "../../../../../common/src/utility/confirmations";
import InlineFormInput from "../../../molecules/form/InlineFormInput.vue";

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords, InviteLicenseModal, InlineFormInput },
})
export default class UserAccountsView extends Vue {
    @Inject("container") readonly container!: Container;
    // CollectionStore
    readonly userListStore: UserRepresentationDashboardListStore = this.container.get(
        UserRepresentationDashboardListStore,
    );
    readonly userCollectionStore: UserCollectionStore = this.container.get(UserCollectionStore);
    readonly api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly organizationListStore: OrganizationListStore =
        this.container.get(OrganizationListStore);

    authStore: AuthStore = this.container.get(AuthStore);
    isInviteLicenseModalVisible: boolean = false;
    opened: string[] = [];
    licenseUserId = "";
    isLicenseManager: { [userid: string]: boolean } = {};
    @Watch("userListStore.query")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.organizationListStore.load().catch(handleError);
        this.userListStore.load().catch(handleError);
    }

    reload() {
        this.userListStore.page = 1;
        this.userListStore.reload().catch(handleError);
    }

    joinDate(date: Date): string {
        return toLocaleDateString(date) || "n/a";
    }

    orgName(user: RawUser) {
        return user && user.organization ? user.organization.organizationName : undefined;
    }

    hasSubscription(user: RawUser) {
        return user && user.subscription && user.subscription.active;
    }

    subscriptionStatus(user: RawUser) {
        return user && user.subscription ? user.subscription.plan : undefined;
    }

    subscriptionEndsAt(user: RawUser) {
        return user && user.subscription && user.subscription.endsAt
            ? new Date(user.subscription.endsAt).toISOString().split("T")[0]
            : undefined;
    }

    hasQuiltySubscription(user: RawUser) {
        return user && user.quiltySubscription && user.quiltySubscription.active;
    }

    quiltySubscriptionStatus(user: RawUser) {
        return user && user.quiltySubscription ? user.quiltySubscription.plan : undefined;
    }

    quiltySubscriptionEndsAt(user: RawUser) {
        return user && user.quiltySubscription && user.quiltySubscription.endsAt
            ? new Date(user.quiltySubscription.endsAt).toISOString().split("T")[0]
            : undefined;
    }

    toggle(id: string) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
            this.opened.splice(index, 1);
        } else {
            this.opened.push(id);
        }
    }

    displayInviteLicenseModal(userid: string) {
        this.licenseUserId = userid;
        this.isInviteLicenseModalVisible = true;
    }

    hideInviteLicenseModal() {
        this.isInviteLicenseModalVisible = false;
    }

    onInviteSubmit() {
        this.hideInviteLicenseModal();
        this.userListStore.reload().catch(handleError);
    }

    async deleteLicense(userid: string, licenseEmail: string) {
        const confirmDelete = await DeleteUser(licenseEmail);
        if (confirmDelete.value) {
            try {
                await this.api.admin.user.removeFromLicense(userid, licenseEmail);
                this.userListStore.reload().catch(handleError);
                this.notificationStore.success("License was deleted.");
            } catch (err) {
                this.notificationStore.warning("License deleted has failed.");
            }
        }
    }

    async orgSearch(searchedValue: string, user: RawUser) {
        if (!this.organizationListStore.items) {
            return;
        }

        const searchedOrganization = this.organizationListStore.items.find((org) => {
            return searchedValue.toUpperCase().trim() === org.organizationName.toUpperCase().trim();
        });

        if (searchedOrganization) {
            var confirmation = confirm(
                "Change organization to " + searchedOrganization.organizationName,
            );
            if (confirmation) {
                const newOrg: any = {
                    organizationName: searchedOrganization.organizationName,
                    organizationid: searchedOrganization.organizationid,
                };
                user.organization = newOrg;
                await this.api.admin.user.updateOrganization(
                    user.userid,
                    searchedOrganization.organizationid,
                );
                this.notificationStore.success("User organization has been updated.");
            }
        }
    }

    async deleteUser(userid: string, firstName: string) {
        try {
            var confirmation = confirm("Are you sure you want to delete " + firstName);
            if (confirmation) {
                await this.api.admin.user.delete(userid);
                this.notificationStore.success("User has been deleted.");
                this.userListStore.reload().catch(handleError);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting user.");
        }
    }

    async updateUser(userId: string, contractId: string) {
        try {
            await this.api.admin.user.updateProfile(userId, { contractId: contractId });
            this.notificationStore.success("User's Contract ID has been updated.");
        } catch (e) {
            this.notificationStore.warning("Failure to update user's Contract ID");
        }
    }

    async changeUserSubscriptionStatus(user: RawUser, event: any) {
        if (user) {
            if (user.subscription && event.target.value === "registered") {
                const subscriptionStatus = { active: false };
                await this.api.admin.user.updateSubscription(user.userid, subscriptionStatus);
                this.notificationStore.success("Subscription status has been updated.");
            } else {
                await this.api.admin.user.createSubscription(user.userid, event.target.value);
                this.notificationStore.success("Subscription status has been created.");
            }

            this.userListStore.reload().catch(handleError);
        }
    }

    async updateSubscriptionEndsAt(user: RawUser, endsAt: any) {
        if (user && user.subscription) {
            await this.api.admin.user.updateSubscription(user.userid, { endsAt });
            this.notificationStore.success("Subscription status has been updated.");
            this.userListStore.reload().catch(handleError);
        }
    }

    async changeQuiltySubscriptionStatus(user: RawUser, event: any) {
        if (user) {
            if (user.quiltySubscription && event.target.value === "free") {
                const subscriptionStatus = { active: false };
                await this.api.admin.user.updateQuiltySubscription(user.userid, subscriptionStatus);
                this.notificationStore.success("Subscription status has been updated.");
            } else {
                await this.api.admin.user.createQuiltySubscription(user.userid, event.target.value);
                this.notificationStore.success("Subscription status has been created.");
            }

            this.userListStore.reload().catch(handleError);
        }
    }

    async updateQuiltySubscriptionEndsAt(user: RawUser, endsAt: any) {
        if (user && user.quiltySubscription) {
            await this.api.admin.user.updateQuiltySubscription(user.userid, { endsAt });
            this.notificationStore.success("Subscription status has been updated.");
            this.userListStore.reload().catch(handleError);
        }
    }

    get hasAdminAccess() {
        const user = this.authStore.user;
        return isAdmin(user);
    }

    get hasThirdPartyAdminAccess() {
        const user = this.authStore.user;
        return isThirdpartyAdmin(user);
    }
}
