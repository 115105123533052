import { getErrorHttpStatus } from "../../utility/utils";
import { boundMethod } from "autobind-decorator";
import { Nullable, unmarshal } from "@gsx/common";
import { NOT_FOUND } from "http-status-codes";
import { FormErrors } from "../../../../common/src/form/types";
import { formErrors } from "../../../../common/src/form/utils";

/**
 * TODO Use EditStoreVuex instead
 */
export abstract class EditStore<T> {
    abstract readonly data: Nullable<T>;

    isLoading: boolean = false;
    isUpdating: boolean = false;
    loadError: Error | null = null;
    updateError: Error | null = null;

    get hasUpdatingFailed(): boolean {
        return this.updateError !== null;
    }

    get hasLoadingFailed(): boolean {
        return this.loadError !== null;
    }

    get notFound(): boolean {
        return getErrorHttpStatus(this.loadError) === NOT_FOUND;
    }

    get formErrors(): FormErrors<T> {
        return formErrors(this.updateError) as FormErrors<T>;
    }

    @boundMethod
    async load(): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        try {
            const response = await this.loadRequest();
            Object.assign(this.data, unmarshal(response));
            this.loadError = null;
        } catch (e: any) {
            console.error(e);
            this.loadError = e;
        } finally {
            this.isLoading = false;
        }
    }

    @boundMethod
    async update(): Promise<void> {
        if (this.isUpdating) {
            return;
        }

        this.isUpdating = true;

        try {
            await this.updateRequest();
            this.updateError = null;
        } catch (e: any) {
            this.updateError = e;
        } finally {
            this.isUpdating = false;
        }
    }

    protected abstract loadRequest(): Promise<T>;
    protected abstract updateRequest(): Promise<void>;
}
