
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ResultCard extends Vue {
    @Prop() id!: string;
    @Prop() title!: string;
    @Prop() subtitle?: string;

    get organizationUrl() {
        return `/dashboard/organizations/${this.id}`;
    }
}
