
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { NotificationType } from "../../../../common/src/stores/NotificationStore";

@Component
export default class Notification extends Vue {
    @Prop() title!: string;
    @Prop() type!: NotificationType;

    @Emit()
    close() {
        //
    }
}
