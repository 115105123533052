
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Nullable } from "@gsx/common";
import { FormErrors } from "../../../../common/src/form/types";
import { QuiltyResourceBody } from "@gsx/common/src/types/http/admin/quiltyResource";
import { Container } from "inversify";
import FormLabel from "../form/FormLabel.vue";

@Component({
    components: {
        FormLabel,
    },
})
export default class QuiltyResourceFilePane extends Vue {
    @Prop() values!: Nullable<QuiltyResourceBody>;
    @Prop() errors!: FormErrors<QuiltyResourceBody>;
    @Inject("container") readonly container!: Container;
    @Prop() value?: { resource?: File; picture?: File };
    fileName: string | null = null;

    mounted() {
        this.fileName = this.extractFileName();
    }

    @Emit()
    input(file: File | null) {
        return { ...this.value, resource: file };
    }

    extractFileName(): string | null {
        if (this.values && this.values.resource) {
            return this.lastUrlSegment(this.values.resource);
        }

        if (this.value && this.value.resource) {
            return this.value.resource.name;
        }

        return null;
    }

    lastUrlSegment(url: string): string {
        // This function assumes 'url' is a valid URL and extracts the last segment.
        // If the URL is not in the expected format or is null, it returns "UNKNOWN".
        const match = url.match(/[^\/]+(?=\/$|$)/);
        return match ? match[0] : "UNKNOWN";
    }

    changeQuiltyResource(event: any) {
        const files = event.target.files;
        if (files.length === 0) {
            return;
        }

        const file = files[0];

        // Set the file name to display
        this.fileName = file.name;

        // Emit the 'input' event with the File object, allowing the parent to handle the uploaded file
        this.input(file);
    }
}
