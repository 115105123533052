
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import InputField from "../../form/InputField.vue";
import FormLabel from "../../form/FormLabel.vue";
import FormFeedback from "../../form/FormFeedback.vue";
import { OrganizationFileItem } from "@gsx/common/src/types/models/organizationFile";
import { Confirm } from "../../../../../common/src/utility/confirmations";

const lastUrlSegment = (url: string): string => url.match(/[^\/]+(?=\/$|$)/)?.[0] ?? "UNKNOWN";

@Component({
    components: { FormFeedback, FormLabel, InputField },
    filters: { lastUrlSegment },
})
export default class FilesUpload extends Vue {
    @Prop() label!: string;
    @Prop() value!: OrganizationFileItem[];
    @Prop() help?: string;
    agreementAccepted = false;

    @Emit()
    upload(files: File[]): File[] {
        return files;
    }

    @Emit()
    remove(fileId: string): string {
        return fileId;
    }

    async tryToRemove(fileId: string) {
        const confirm = await Confirm.fire();
        if (confirm.value) {
            this.remove(fileId);
        }
    }

    onFilesUpload(event: any) {
        const target: HTMLInputElement = event.target;
        let files = Array.from(target.files ?? []);
        this.upload(files);
    }
}
