
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component
export default class SearchInput extends Vue {
    @Prop({ default: "" }) value!: string;
    @Ref("searchInput") readonly searchInput!: HTMLButtonElement;

    mounted() {
        this.searchInput.focus();
    }
}
