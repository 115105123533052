
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { FormErrors } from "../../../../../common/src/form/types";
import { Nullable, OrganizationProfileBody } from "@gsx/common";
import InvestmentsTable from "./transactionalData/InvestmentsTable.vue";
import MergerAcquisitionTable from "./transactionalData/MergerAcquistionTable.vue";
import { MergerAcquisitionBody } from "@gsx/common/src/types/http/admin/mergerAcquisition";

@Component({
    components: {
        InvestmentsTable,
        MergerAcquisitionTable,
    },
})
export default class TransactionalDataPane extends Vue {
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() data!: { list: Nullable<MergerAcquisitionBody>[] };
    @Prop() errors!: FormErrors<OrganizationProfileBody>;
    @Inject("container") readonly container!: Container;

    get mergerAcquisitions() {
        return this.data.list;
    }

    set mergerAcquisitions(val: Nullable<MergerAcquisitionBody>[]) {
        this.data.list = val;
    }
}
