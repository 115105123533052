import { Nullable, Organization, OrganizationProfileBody } from "@gsx/common";
import get from "lodash.get";
import { CheckListBody } from "@gsx/common/src/types/http/user/organization/checkList";

export const createCheckListEmptyBody = (
    data?: Partial<CheckListBody>,
): Nullable<CheckListBody> => ({
    contactCompanyComments: get(data, "contactCompanyComments", null),
    contactCompanyDate: get(data, "contactCompanyDate", null),
    contactCompanyUserId: get(data, "contactCompanyUser", null),
    updatedAt: get(data, "updatedAt", null),
    privateComments: get(data, "privateComments", null),
    publicRecordsComments: get(data, "publicRecordsComments", null),
    publicRecordsDate: get(data, "publicRecordsDate", null),
    publicRecordsUserId: get(data, "publicRecordsUser", null),
    published: get(data, "published", null),
    regulatoryRecordsComments: get(data, "regulatoryRecordsComments", null),
    regulatoryRecordsDate: get(data, "regulatoryRecordsDate", null),
    regulatoryRecordsUserId: get(data, "regulatoryRecordsUser", null),
    socialMediaComments: get(data, "socialMediaComments", null),
    socialMediaDate: get(data, "socialMediaDate", null),
    socialMediaUserId: get(data, "socialMediaUser", null),
    websiteComments: get(data, "websiteComments", null),
    websiteDate: get(data, "websiteDate", null),
    websiteUserId: get(data, "websiteUser", null),
});

export const createOrganizationEmptyBody = (
    organization?: Partial<Organization>,
): Nullable<OrganizationProfileBody> => ({
    academiaProbs: get(organization, "academiaProbs", null),
    acquired: get(organization, "acquired", null),
    acquirer: get(organization, "acquirer", null),
    acquisitionAmount: get(organization, "acquisitionAmount", null),
    addresses: get(organization, "addresses") ?? [],
    advertisingInterest: get(organization, "advertisingInterest", null),
    approvedCountry: get(organization, "approvedCountry", null),
    applicationTags: get(organization, "applicationTags", null),
    assessmentParameters: get(organization, "assessmentParameters", null),
    bldgImagelink: get(organization, "bldgImagelink", null),
    briefUpload: get(organization, "briefUpload", null),
    city: get(organization, "city", null),
    competitors: get(organization, "competitors", null),
    conductRandD: get(organization, "conductRandD", null),
    corpCatalogs: get(organization, "corpCatalogs", null),
    corpGoals: get(organization, "corpGoals", null),
    corpLogo: get(organization, "corpLogo", null),
    corpOwners: get(organization, "corpOwners", null),
    corpProfit: get(organization, "corpProfit", null),
    corpRevenue: get(organization, "corpRevenue", null),
    corpStandards: get(organization, "corpStandards", null),
    country: get(organization, "country", null),
    currentPartners: get(organization, "currentPartners", []),
    customers: get(organization, "customers", null),
    dateYearFounded: get(organization, "dateYearFounded", null),
    dateMonthFounded: get(organization, "dateMonthFounded", null),
    description: get(organization, "description", null),
    duns: get(organization, "duns", null),
    emails: (get(organization, "emails") ?? []).map((item) => ({
        comments: item.comments ?? null,
        email: item.email ?? null,
        type: item.type ?? null,
    })),
    environmentalTest: get(organization, "environmentalTest", null),
    expertise: get(organization, "expertise", null),
    expertiseExplanation: get(organization, "expertiseExplanation", null),
    financialStabilityRating: get(organization, "financialStabilityRating", null),
    foreignFunding: get(organization, "foreignFunding", null),
    foreignOwnership: get(organization, "foreignOwnership", null),
    fundRandD: get(organization, "fundRandD", null),
    heritage: get(organization, "heritage", null),
    hiring: get(organization, "hiring", null),
    hiringHelp: get(organization, "hiringHelp", null),
    identifyManufacture: get(organization, "identifyManufacture", null),
    identifyPartners: get(organization, "identifyPartners", null),
    individualSpecialExpertise: get(organization, "individualSpecialExpertise", null),
    industryChallenges: get(organization, "industryChallenges", null),
    industryChanges: get(organization, "industryChanges", null),
    initFunding: get(organization, "initFunding", null),
    intlSales: get(organization, "intlSales", null),
    investmentsAndFunding: get(organization, "investmentsAndFunding", null),
    keyPerformanceIndicators: get(organization, "keyPerformanceIndicators", null),
    licenseTech: get(organization, "licenseTech", null),
    manufactureInternal: get(organization, "manufactureInternal", null),
    nameBODmembers: get(organization, "nameBODmembers", null),
    newReqs: get(organization, "newReqs", null),
    notifyRandD: get(organization, "notifyRandD", null),
    numBOD: get(organization, "numBOD", null),
    numEmployees: get(organization, "numEmployees", null),
    numVolunteers: get(organization, "numVolunteers", null),
    openToPartners: get(organization, "openToPartners", null),
    operatingRegimeTags: get(organization, "operatingRegimeTags", null),
    organizationDBA: get(organization, "organizationDBA", null),
    organizationName: get(organization, "organizationName", null),
    organizationSize: get(organization, "organizationSize", null),
    organizationType: get(organization, "organizationType", null),
    otherURLs: get(organization, "otherURLs", null),
    ownership: get(organization, "ownership", null),
    parentOrganizationName: get(organization, "parentOrganizationName", null),
    phoneNumbers: (get(organization, "phoneNumbers") ?? []).map((item) => ({
        comments: item.comments ?? null,
        phoneNumber: item.phoneNumber ?? null,
        type: item.type ?? null,
    })),
    pointOfContact: (get(organization, "pointOfContact", null) ?? []).map((item) => ({
        firstName: item.firstName ?? null,
        lastName: item.lastName ?? null,
        phoneNumber: item.phoneNumber ?? null,
        email: item.email ?? null,
        address: item.address ?? null,
        title: item.title ?? null,
        publicInfo: item.publicInfo ?? null,
    })),
    productTags: get(organization, "productTags", null),
    seekInvest: get(organization, "seekInvest", null),
    seekSale: get(organization, "seekSale", null),
    selfDescription: get(organization, "selfDescription", null),
    sellExportControlProds: get(organization, "sellExportControlProds", null),
    serviceTags: get(organization, "serviceTags", null),
    smallBusiness: get(organization, "smallBusiness", null),
    socialFacebook: get(organization, "socialFacebook", null),
    socialFlickr: get(organization, "socialFlickr", null),
    socialInstagram: get(organization, "socialInstagram", null),
    socialLinkedIn: get(organization, "socialLinkedIn", null),
    socialLivestream: get(organization, "socialLivestream", null),
    socialTwitter: get(organization, "socialTwitter", null),
    socialYouTube: get(organization, "socialYouTube", null),
    standardsUpdates: get(organization, "standardsUpdates", null),
    state: get(organization, "state", null),
    status: get(organization, "status", null),
    streetAddr: get(organization, "streetAddr", null),
    subsidiary: get(organization, "subsidiary", null),
    subsidiaryList: get(organization, "subsidiaryList", null),
    targetCustomers: get(organization, "targetCustomers", null),
    testRanges: get(organization, "testRanges", null),
    tiers: get(organization, "tiers", []),
    ufFederalCage: get(organization, "ufFederalCage", null),
    universityOfChoice: get(organization, "universityOfChoice", null),
    upcomingProducts: get(organization, "upcomingProducts", null),
    veteranOwned: get(organization, "veteranOwned", null),
    verticalIntegration: get(organization, "verticalIntegration", null),
    wantIntlSales: get(organization, "wantIntlSales", null),
    webAddr: get(organization, "webAddr", null),
    zipcode: get(organization, "zipcode", null),
});
