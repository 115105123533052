
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import AddressFormGroup from "../../../form/AddressFormGroup.vue";
import { Address, AddressBody, AddressDescription, Nullable } from "@gsx/common";
import FormSelect from "../../../form/FormSelect.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import Modal from "../../../../../../common/src/components/Modal.vue";

@Component({
    components: {
        AddressFormGroup,
        ActionButton,
        FormSelect,
        Modal,
    },
})
export default class EditAddressModal extends Vue {
    @Prop() value!: Nullable<Address>;

    description: AddressDescription | null = this.value.description;
    address: Nullable<AddressBody> = {
        country: this.value.country,
        city: this.value.city,
        zipcode: this.value.zipcode,
        state: this.value.state,
        streetAddress: this.value.streetAddress,
        coordinates: this.value.coordinates,
    };

    get addressDescriptions(): AddressDescription[] {
        return Object.values(AddressDescription);
    }

    @Emit()
    submit(): Nullable<Address> {
        return {
            description: this.description,
            ...this.address,
        };
    }

    @Emit()
    close() {
        //
    }

    async onSubmit() {
        this.submit();
        this.close();
    }
}
