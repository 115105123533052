import { injectable } from "inversify";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { Api } from "../../../../../common/src/api/Api";

@injectable()
export class OrganizationLogoEditStore extends SubmitStore<any> {
    private id: string = "";
    data: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    public setId(value: string): void {
        this.id = value;
    }

    protected async request(): Promise<void> {
        await this.api.admin.organization.updateLogo(this.id, this.data);
    }
}
