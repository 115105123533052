
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import FormInput from "../form/FormInput.vue";
import FormSelect from "../form/FormSelect.vue";
import FormCheckbox from "../form/FormCheckbox.vue";
import FormTextarea from "../form/FormTextarea.vue";
import FormTel from "../form/FormTel.vue";
import ActionButton from "../../../../common/src/components/ActionButton.vue";
import { FormErrors } from "../../../../common/src/form/types";
import { QuiltyInsightsBody } from "@gsx/common/src/types/http/admin/quiltyInsights";
import { Nullable } from "@gsx/common";
import QuiltyInsightsGeneralPane from "./QuiltyInsightsGeneralPane.vue";
import QuiltyInsightsPicturePane from "./QuiltyInsightsPicturePane.vue";

@Component({
    components: {
        FormTel,
        FormTextarea,
        FormCheckbox,
        FormSelect,
        ActionButton,
        FormInput,
    },
})
export default class QuiltyInsightsForm extends Vue {
    @Prop({ default: false }) loading!: boolean;
    @Prop() values!: Nullable<QuiltyInsightsBody>;
    @Prop() errors!: FormErrors<QuiltyInsightsBody>;
    @Prop({ default: "Update Flash News" }) submitText!: string;
    @Prop() admin!: boolean;
    @Prop() value?: { picture: File };
    media?: { picture?: File } = this.value;

    @Watch("media")
    doIt() {
        if (this.media && this.media.picture) {
            this.input();
        }
    }

    @Emit()
    input() {
        return this.media;
    }

    readonly tabs = [
        {
            title: "General <br />Information",
            component: QuiltyInsightsGeneralPane,
        },
        {
            title: "Picture <br />Upload",
            component: QuiltyInsightsPicturePane,
        },
    ];
    activeTab: number = 0;
    get enrichedTabs(): any[] {
        return this.tabs.map((tab, index) => ({
            ...tab,
            active: index === this.activeTab,
        }));
    }
    activateTab(index: number) {
        this.activeTab = index;
    }
    @Emit()
    submit() {
        //
    }
}
