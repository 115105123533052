import { Api } from "../../../../../../common/src/api/Api";
import { injectable } from "inversify";
import { SubmitStore } from "../../../../../../common/src/stores/general/SubmitStore";

@injectable()
export class UpdateUserOrganizationLogoStore extends SubmitStore<any> {
    data: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        await this.api.user.updateUserOrganizationLogo(this.data);
    }
}
