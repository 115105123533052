
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { ReportEditStore } from "../../../stores/admin/report/ReportEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";

@Component
export default class ReportsEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: ReportEditStore = this.container.get(ReportEditStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    created() {
        this.editStore.setId(this.reportId);
        this.editStore.load().catch(handleError);
    }

    get reportId(): string {
        return this.$route.params.reportId;
    }

    get generalUrl(): string {
        return `/admin/reports/${this.reportId}/general`;
    }

    get mediaUrl(): string {
        return `/admin/reports/${this.reportId}/media`;
    }
}
