
import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import { Nullable, Address, PointOfContact } from "@gsx/common";
import FormSelect from "../../../form/FormSelect.vue";
import FormCheckbox from "../../../form/FormCheckbox.vue";
import FormLabel from "../../../form/FormLabel.vue";
import FormInput from "../../../form/FormInput.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import Modal from "../../../../../../common/src/components/Modal.vue";
import FormTel from "../../../form/FormTel.vue";
import FormTextarea from "../../../form/FormTextarea.vue";
import EditAddressModal from "../address/EditAddressModal.vue";
import AddAddressModal from "../address/AddAddressModal.vue";

@Component({
    components: {
        FormTextarea,
        FormTel,
        FormInput,
        FormCheckbox,
        FormLabel,
        ActionButton,
        FormSelect,
        Modal,
        EditAddressModal,
        AddAddressModal,
    },
})
export default class EditOrganizationPointOfContactModal extends Vue {
    @Prop() value!: Nullable<PointOfContact>;

    firstName: string | null = this.value.firstName;
    lastName: string | null = this.value.lastName;
    phoneNumber: string | null = this.value.phoneNumber;
    email: string | null = this.value.email;
    address: Address | null = this.value.address;
    title: string | null = this.value.title;
    publicInfo: boolean | null = this.value.publicInfo;

    isMainModalVisible = true;
    isEditAddressModalVisible = false;
    isAddAddressModalVisible = false;

    displayMainModal() {
        this.isMainModalVisible = true;
    }

    displayEditAddressModal() {
        this.hideMainModal();
        this.isEditAddressModalVisible = true;
    }

    displayAddAddressModal() {
        this.hideMainModal();
        this.isAddAddressModalVisible = true;
    }

    hideAddAddressModal() {
        this.displayMainModal();
        this.isAddAddressModalVisible = false;
    }

    hideMainModal() {
        this.isMainModalVisible = false;
    }

    hideEditAddressModal() {
        this.displayMainModal();
        this.isEditAddressModalVisible = false;
    }

    editAddress(address: Address) {
        this.address = address;
    }

    addAddress(address: Address) {
        this.address = address;
    }

    @Emit()
    submit(): Nullable<PointOfContact> {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email,
            address: this.address,
            title: this.title,
            publicInfo: this.publicInfo,
        };
    }

    @Emit()
    close() {
        //
    }

    async onSubmit() {
        this.submit();
        this.close();
    }
}
