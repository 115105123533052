
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { PastProjectListStore } from "../../../../stores/member/organization/past-project/PastProjectListStore";
import { PastProjectEditStore } from "../../../../stores/member/organization/past-project/PastProjectEditStore";
import { focusOnFirstInvalidInput } from "../../../../../../common/src/form/utils";
import PastProjectForm from "../../../../molecules/organization/past-projects/PastProjectForm.vue";
import { PastProjectDestroyStore } from "../../../../stores/member/organization/past-project/PastProjectDestroyStore";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import NotFound from "../../../../molecules/components/NotFound.vue";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import { Confirm } from "../../../../../../common/src/utility/confirmations";

@Component({
    components: { NotFound, LoadingOverlay, PastProjectForm },
})
export default class PastProjectEditView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: PastProjectListStore = this.container.get(PastProjectListStore);
    editStore: PastProjectEditStore = this.container.get(PastProjectEditStore);
    destroyStore: PastProjectDestroyStore = this.container.get(PastProjectDestroyStore);

    created() {
        this.editStore.setId(this.pastProjectId);
        this.editStore.load().catch(handleError);
    }

    get pastProjectId(): string {
        return this.$route.params.pastProject;
    }

    async submit() {
        await this.editStore.update();

        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Could not update past project.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have updated past project successfully.");
            this.listStore.reload().catch(handleError);
        }
    }

    async destroy() {
        const result = await Confirm.fire();

        if (!result.value) {
            return;
        }

        await this.destroyStore.delete(this.pastProjectId);

        if (this.destroyStore.failed) {
            this.notificationStore.warning("Could not delete past project.");
        } else {
            this.notificationStore.success("You have deleted past project successfully.");
            this.$router.push("/member/organization/past-projects");
        }
    }
}
