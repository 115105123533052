
import { Component, Inject, Vue } from "vue-property-decorator";
import FormInput from "../molecules/form/FormInput.vue";
import { Container } from "inversify";
import { getErrorHttpStatus } from "../utility/utils";
import { ChangePasswordStore } from "../stores/password-reset/ChangePasswordStore";
import ActionButton from "../../../common/src/components/ActionButton.vue";
import { NOT_FOUND } from "http-status-codes";
import { PASSWORD_HELP } from "../molecules/form/constants";
import { NotificationStore } from "../../../common/src/stores/NotificationStore";
import { focusOnFirstInvalidInput } from "../../../common/src/form/utils";

@Component({
    components: { ActionButton, FormInput },
})
export default class ChangePasswordView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly changePasswordStore: ChangePasswordStore = this.container.get(ChangePasswordStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    passwordIsVisible: boolean = false;

    created() {
        this.changePasswordStore.setToken(this.token);
    }

    get token(): string {
        return this.$route.params.token;
    }

    get values() {
        return this.changePasswordStore.data;
    }

    get errors() {
        return this.changePasswordStore.formErrors;
    }

    get PASSWORD_HELP() {
        return PASSWORD_HELP;
    }

    async changePassword() {
        await this.changePasswordStore.submit();

        if (this.changePasswordStore.failed) {
            if (getErrorHttpStatus(this.changePasswordStore.error) === NOT_FOUND) {
                this.notificationStore.warning("Invalid token.");
            } else {
                focusOnFirstInvalidInput();
                this.notificationStore.warning("You entered an invalid data.");
            }
        } else {
            this.notificationStore.success("Your password has been changed.");
            this.$router.push("/signin");
        }
    }
}
