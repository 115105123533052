
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { MergerAcquisition } from "@gsx/common";
import { OrganizationListStore } from "../../../../stores/organization/OrganizationListStore";
import { createMergerAcquisitionEmptyBody } from "../../../../utility/entity/mergerAcquisition";
import FormInput from "../../../form/FormInput.vue";
import FormMultiSelect from "../../../form/FormMultiSelect.vue";
import FormDate from "../../../form/FormDate.vue";
import { SelectOption } from "../../../form/types";

@Component({
    components: { FormInput, FormMultiSelect, FormDate },
})
export default class MergerAcquisitionTable extends Vue {
    @Inject("container") readonly container!: Container;
    @Prop({ default: () => [] }) value!: MergerAcquisition[];
    readonly organizationListStore: OrganizationListStore =
        this.container.get(OrganizationListStore);
    get formattedValue(): MergerAcquisition[] {
        return this.value || [];
    }

    get organizations(): SelectOption[] {
        return this.organizationListStore.items
            ? this.organizationListStore.items.map((org) => ({
                  name: org.organizationName,
                  id: org.organizationid,
              }))
            : [];
    }

    @Emit()
    input(value: MergerAcquisition[]) {
        return value;
    }

    mounted() {
        this.organizationListStore.load();
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add() {
        this.input([
            ...this.formattedValue,
            createMergerAcquisitionEmptyBody() as NonNullable<MergerAcquisition>,
        ]);
    }

    selectTargetOrganization(option: SelectOption, index: number): void {
        this.formattedValue[index].targetOrganization.organizationName = option.name;
        this.formattedValue[index].targetOrganization.organizationId = option.new
            ? undefined
            : option.id;

        this.input([...this.formattedValue]);
    }

    selectAcquirerOrganization(option: SelectOption, index: number): void {
        this.formattedValue[index].acquirerOrganization.organizationName = option.name;
        this.formattedValue[index].acquirerOrganization.organizationId = option.new
            ? undefined
            : option.id;

        this.input([...this.formattedValue]);
    }
}
