
import { Component, Emit, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import FormInput from "../form/FormInput.vue";
import FormSelect from "../form/FormSelect.vue";
import FormCheckbox from "../form/FormCheckbox.vue";
import FormTextarea from "../form/FormTextarea.vue";
import FormTel from "../form/FormTel.vue";
import ActionButton from "../../../../common/src/components/ActionButton.vue";
import { FormErrors } from "../../../../common/src/form/types";
import { ReportBody } from "@gsx/common/src/types/http/admin/report";
import { Nullable } from "@gsx/common";
import ReportGeneralPane from "./ReportGeneralPane.vue";
import ReportPDFPane from "./ReportPDFPane.vue";
import ReportPicturePane from "./ReportPicturePane.vue";
import { Api } from "../../../../common/src/api/Api";
import { Container } from "inversify";

@Component({
    components: {
        FormTel,
        FormTextarea,
        FormCheckbox,
        FormSelect,
        ActionButton,
        FormInput,
    },
})
export default class ReportForm extends Vue {
    @Inject("container") readonly container!: Container;
    @Prop({ default: false }) loading!: boolean;
    @Prop() values!: Nullable<ReportBody>;
    @Prop() errors!: FormErrors<ReportBody>;
    @Prop({ default: "Update Report" }) submitText!: string;
    @Prop() admin!: boolean;
    @Prop() value?: { pdf: File; picture: File };
    media?: { pdf?: File; picture?: File } = this.value;
    readonly api: Api = this.container.get(Api);

    @Watch("media")
    propagateMedia() {
        if (this.media && (this.media.pdf || this.media.picture)) {
            this.input();
        }
    }

    @Emit()
    input() {
        return this.media;
    }

    readonly tabs = [
        {
            title: "General <br />Information",
            component: ReportGeneralPane,
        },
        {
            title: "PDF <br />Upload",
            component: ReportPDFPane,
        },
        {
            title: "Picture <br />Upload",
            component: ReportPicturePane,
        },
    ];

    activeTab: number = 0;

    get enrichedTabs(): any[] {
        return this.tabs.map((tab, index) => ({
            ...tab,
            active: index === this.activeTab,
        }));
    }

    activateTab(index: number) {
        this.activeTab = index;
    }

    @Emit()
    submit() {
        //
    }
}
