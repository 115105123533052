
import { Component, Vue, Inject, Emit } from "vue-property-decorator";
import FormInput from "../../../../landing/src/molecules/form/FormInput.vue";
import FormMultiSelect from "../../../../landing/src/molecules/form/FormMultiSelect.vue";
import FormSimpleMultiSelect from "../../../../landing/src/molecules/form/FormSimpleMultiSelect.vue";
import { NotificationStore } from "../../../../common/src/stores/NotificationStore";
import { Container } from "inversify";

@Component({
    components: { FormSimpleMultiSelect, FormMultiSelect, FormInput },
})
export default class ScheduleSessionForm extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    isGovernmentSchedulerVisible = false;
    isCommercialSchedulerVisible = false;

    @Emit("affiliationChosen")
    showCommercialScheduler() {
        this.isCommercialSchedulerVisible = true;
        this.isGovernmentSchedulerVisible = false;
        return true;
    }

    @Emit("affiliationChosen")
    showGovernmentScheduler() {
        this.isGovernmentSchedulerVisible = true;
        this.isCommercialSchedulerVisible = false;
        return true;
    }

    @Emit("affiliationChosen")
    resetForm() {
        this.isCommercialSchedulerVisible = false;
        this.isGovernmentSchedulerVisible = false;
        return false;
    }
}
