import { FeedbackListItem, FeedbackListResponse } from "@gsx/common/src/types/http/admin/feedback";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";
import { Api } from "../../../../../common/src/api/Api";

@Module({ generateMutationSetters: true })
export class FeedbackListStore extends ListStoreVuex<FeedbackListItem> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
        this.pageSize = 5;
    }

    protected async fetch(): Promise<FeedbackListResponse> {
        return this.api.admin.feedback.list(this.queryParams);
    }
}
