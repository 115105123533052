import { RequestListItem, RequestListResponse } from "@gsx/common/src/types/http/admin/request";
import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module({ generateMutationSetters: true })
export class RequestListStore extends ListStoreVuex<RequestListItem> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
        this.pageSize = 5;
    }

    protected async fetch(): Promise<RequestListResponse> {
        return this.api.admin.request.list(this.queryParams);
    }
}
