
import { Component, Inject, Vue } from "vue-property-decorator";
import Breadcrumbs from "../molecules/layout/Breadcrumbs.vue";
import { Breadcrumb } from "../molecules/layout/types";
import FormInput from "../molecules/form/FormInput.vue";
import { Container } from "inversify";
import { PasswordResetStore } from "../stores/password-reset/PasswordResetStore";
import { focusOnFirstInvalidInput } from "../../../common/src/form/utils";
import ActionButton from "../../../common/src/components/ActionButton.vue";
import { NotificationStore } from "../../../common/src/stores/NotificationStore";

@Component({
    components: { ActionButton, FormInput, Breadcrumbs },
})
export default class PasswordResetView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly passwordResetStore: PasswordResetStore = this.container.get(PasswordResetStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    breadcrumbs: Breadcrumb[] = [
        {
            to: "/signin",
            name: "Home",
        },
    ];

    get values() {
        return this.passwordResetStore.data;
    }

    get errors() {
        return this.passwordResetStore.formErrors;
    }

    get checkThirdPartyUrl() {
        const host = window.location.host;
        const parts = host.split(".");
        return parts[0] === "quilty"; //CHANGE TO CONFIG VARIABLE
    }

    async resetPassword() {
        if (this.checkThirdPartyUrl) {
            this.passwordResetStore.platformReferer = "quilty";
        }

        await this.passwordResetStore.submit();

        if (this.passwordResetStore.failed) {
            focusOnFirstInvalidInput();
            this.notificationStore.warning("You have entered an invalid data.");
        } else {
            this.notificationStore.success(
                "If you entered a valid email, you will receive the instructions how to reset your password.",
            );
        }
    }

    get hasQuiltyInUrl() {
        const url = window.location.href;
        return url.includes("http://quilty.") || url.includes("https://quilty.");
    }
}
