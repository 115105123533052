
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { MergerAcquisitionsEditStore } from "../../../stores/admin/mergerAcquisitions/MergerAcquisitionsEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import MergerAcquisitionForm from "../../../molecules/mergerAcquisitions/MergerAcquisitionForm.vue";
@Component({
    components: {
        MergerAcquisitionForm,
    },
})
export default class MergerAcquisitionsEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: MergerAcquisitionsEditStore = this.container.get(MergerAcquisitionsEditStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    created() {
        this.editStore.setId(this.mergerAcquisitionId);
        this.editStore.load().catch(handleError);
    }
    get mergerAcquisitionId(): string {
        return this.$route.params.mergerAcquisitionId;
    }

    async updateMergerAcquisition() {
        await this.editStore.update();
        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Merger/Acquisition update failed.");
        } else {
            this.notificationStore.success("You have updated merger/acquisition successfully.");
        }
    }
}
