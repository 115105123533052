
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
// import { MergerAcquisitionsCreateStore } from "../../../stores/admin/mergerAcquisitions/MergerAcquisitionsCreateStore";
import { MergerAcquisitionsListStore } from "../../stores/admin/mergerAcquisitions/MergerAcquisitionsListStore";
import { NotificationStore } from "../../../../common/src/stores/NotificationStore";
// import { handleError } from "../../../../../common/src/utility/errorUtils";
import InlineFormInput from "../../molecules/form/InlineFormInput.vue";
import InlineFormDate from "../../molecules/form/InlineFormDate.vue";
import InlineFormMultiSelect from "../../molecules/form/InlineFormMultiSelect.vue";
import { MergerAcquisitionBody } from "@gsx/common/src/types/http/admin/mergerAcquisition";
import { Nullable } from "@gsx/common";
import { FormErrors } from "../../../../common/src/form/types";
import ActionButton from "../../../../common/src/components/ActionButton.vue";
import { OrganizationListStore } from "../../stores/organization/OrganizationListStore";
import { SelectOption } from "../form/types";
import { GeneralTransactionType } from "@gsx/common";
import InlineFormSimpleMultiSelect from "../form/InlineFormSimpleMultiSelect.vue";
// import InlineFormInput from "../../../molecules/form/FormLabel.vue"
@Component({
    components: {
        InlineFormInput,
        InlineFormDate,
        ActionButton,
        InlineFormMultiSelect,
        InlineFormSimpleMultiSelect,
    },
})
export default class MergerAcquisitionForm extends Vue {
    @Inject("container") readonly container!: Container;
    // readonly createStore: MergerAcquisitionsCreateStore = this.container.get(MergerAcquisitionsCreateStore);
    readonly listStore: MergerAcquisitionsListStore = this.container.get(
        MergerAcquisitionsListStore,
    );
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    @Prop({ default: false }) loading!: boolean;
    @Prop() values!: Nullable<MergerAcquisitionBody>;
    @Prop() errors!: FormErrors<MergerAcquisitionBody>;
    @Prop({ default: "Update Merger/Acquisition" }) submitText!: string;
    @Prop() admin!: boolean;
    readonly organizationListStore: OrganizationListStore =
        this.container.get(OrganizationListStore);

    get organizations(): SelectOption[] {
        return this.organizationListStore.items
            ? this.organizationListStore.items.map((org) => ({
                  name: org.organizationName,
                  id: org.organizationid,
              }))
            : [];
    }

    mounted() {
        this.organizationListStore.load();
    }

    @Emit()
    submit() {
        //
    }

    selectTargetOrganization(option: SelectOption): void {
        this.values.targetOrganization = {
            organizationName: option.name,
            organizationId: option.new ? undefined : option.id,
        };
    }

    selectAcquirerOrganization(option: SelectOption): void {
        this.values.acquirerOrganization = {
            organizationName: option.name,
            organizationId: option.new ? undefined : option.id,
        };
    }

    selectInvestors(option: SelectOption[]): void {
        this.values.investors = option.map((obj) => obj.name);
    }

    get generalTransactionTypes(): GeneralTransactionType[] {
        return Object.values(GeneralTransactionType);
    }
}
