import "reflect-metadata";
import { Container } from "inversify";
import axios from "axios";
import { RecaptchaService } from "../../common/src/utility/recaptcha/RecaptchaService";
import { RequestListStore as MemberRequestListStore } from "./stores/member/requests/RequestListStore";
import { OrganizationListStore } from "./stores/organization/OrganizationListStore";
import { PastProjectListStore as MemberPastProjectListStore } from "./stores/member/organization/past-project/PastProjectListStore";
import { FeedbackListStore as AdminFeedbackListStore } from "./stores/admin/feedback/FeedbackListStore";
import { RequestListStore as AdminRequestListStore } from "./stores/admin/request/RequestListStore";
import { UserRepresentationListStore as AdminUserRepresentationListStore } from "./stores/admin/user/UserRepresentationListStore";
import { OrganizationListStore as AdminOrganizationListStore } from "./stores/admin/organization/OrganizationListStore";
import { OrganizationCollectionStore as AdminOrganizationCollectionStore } from "./stores/admin/organization/OrganizationCollectionStore";
import { OrganizationLogListStore as AdminOrganizationLogListStore } from "./stores/admin/organization/OrganizationLogListStore";
import { PastProjectListStore as AdminPastProjectListStore } from "./stores/admin/organization/PastProjectListStore";
import { FileListStore as AdminFileListStore } from "./stores/admin/organization/FileListStore";
import { FlashNewsListStore as AdminFlashNewsListStore } from "./stores/admin/flashNews/FlashNewsListStore";
import { FlashNewsCollectionStore as AdminFlashNewsCollectionStore } from "./stores/admin/flashNews/FlashNewsCollectionStore";
import { Store } from "vuex";
import { PaymentService } from "./utility/payment/PaymentService";
import { PastProjectDestroyStore as AdminPastProjectDestroyStore } from "./stores/admin/organization/PastProjectDestroyStore";
import { PastProjectDestroyStore as MemberPastProjectDestroyStore } from "./stores/member/organization/past-project/PastProjectDestroyStore";
import { AuthStore } from "../../common/src/stores/AuthStore";
import { Api } from "../../common/src/api/Api";
import { ApiFactory } from "../../common/src/api/ApiFactory";
import { Axios } from "@gsx/common";
import { registerModules } from "../../common/src/utility/containerUtils";
import { CheckListStore } from "./stores/admin/organization/CheckListStore";
import { StatisticsStore } from "./stores/statistic/StatisticsStore";
import { OrderListStore } from "./stores/order/OrderListStore";
import { UserRepresentationDashboardListStore } from "./stores/admin/user/UserRepresentationDashboardListStore";
import { UserActivityLogsListStore } from "./stores/admin/user/UserActivityLogsListStore";
import { UserActivityLogsCollectionStore } from "./stores/admin/user/UserActivityLogsCollectino";
import { ReportCollectionStore as AdminReportCollectionStore } from "./stores/admin/report/ReportCollectionStore";
import { ReportListStore as AdminReportListStore } from "../../landing/src/stores/admin/report/ReportListStore";
import { QuiltyInsightsCollectionStore as AdminQuiltyInsightCollectionStore } from "./stores/admin/quiltyInsights/QuiltyInsightsCollectionStore";
import { QuiltyInsightsListStore as AdminQuiltyInsightsListStore } from "./stores/admin/quiltyInsights/QuiltyInsightsListStore";
import { QuiltyResourceCollectionStore as AdminQuiltyResourceCollectionStore } from "./stores/admin/quiltyResource/QuiltyResourceCollectionStore";
import { QuiltyResourceListStore as AdminQuiltyResourceListStore } from "./stores/admin/quiltyResource/QuiltyResourceListStore";
import { MergerAcquisitionsListStore as AdminMergerAcquisitionsListStore } from "./stores/admin/mergerAcquisitions/MergerAcquisitionsListStore";
import { UserCollectionStore } from "./stores/admin/user/UserCollectionStore";
import axiosRetry from "axios-retry";

export const createContainer = (store: Store<any>): Container => {
    const container = new Container({
        autoBindInjectable: true,
        skipBaseClassChecks: true,
    });
    container.bind(Container).toConstantValue(container);

    container
        .bind(Axios)
        .toDynamicValue(() => {
            const instance = axios.create({
                withCredentials: true,
            });

            instance.interceptors.request.use((config) => {
                if (config.url?.startsWith(process.env.API_URL!)) {
                    const authStore: AuthStore = container.get(AuthStore);

                    if (authStore.token) {
                        config.headers = {
                            Authorization: `JWT ${authStore.token}`,
                        };
                    }
                }

                return config;
            });

            axiosRetry(instance, {
                retries: 3,
                retryCondition: (error) =>
                    axiosRetry.isNetworkOrIdempotentRequestError(error) ||
                    error.response?.status === 500,
                retryDelay: axiosRetry.exponentialDelay,
            });

            return instance;
        })
        .inSingletonScope();

    container.bind(ApiFactory).toDynamicValue(() => {
        const axiosInstance = container.get<Axios>(Axios);
        return new ApiFactory(axiosInstance, process.env.API_URL!);
    });

    registerModules(container, store);

    container
        .bind(AdminReportCollectionStore)
        .toDynamicValue(
            () =>
                new AdminReportCollectionStore(container.get(AdminReportListStore), {
                    store,
                    name: "adminReportCollectionStore",
                }),
        )
        .inSingletonScope();

    container
        .bind(OrganizationListStore)
        .toDynamicValue(
            () =>
                new OrganizationListStore(container.get(Api), { store, name: "organizationList" }),
        )
        .inSingletonScope();

    container
        .bind(OrderListStore)
        .toDynamicValue(() => new OrderListStore(container.get(Api), { store, name: "orderList" }))
        .inSingletonScope();

    container
        .bind(MemberRequestListStore)
        .toDynamicValue(
            () =>
                new MemberRequestListStore(container.get(Api), {
                    store,
                    name: "memberRequestList",
                }),
        )
        .inSingletonScope();

    container
        .bind(MemberPastProjectListStore)
        .toDynamicValue(
            () =>
                new MemberPastProjectListStore(container.get(Api), {
                    store,
                    name: "memberPastProjectList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminRequestListStore)
        .toDynamicValue(
            () =>
                new AdminRequestListStore(container.get(Api), { store, name: "adminRequestList" }),
        )
        .inSingletonScope();

    container
        .bind(AdminUserRepresentationListStore)
        .toDynamicValue(
            () =>
                new AdminUserRepresentationListStore(container.get(Api), {
                    store,
                    name: "adminUserRepresentationList",
                }),
        )
        .inSingletonScope();

    container
        .bind(UserRepresentationDashboardListStore)
        .toDynamicValue(
            () =>
                new UserRepresentationDashboardListStore(container.get(Api), {
                    store,
                    name: "userRepresentationDashboardListStore",
                }),
        )
        .inSingletonScope();

    container.bind(UserCollectionStore).toDynamicValue(
        () =>
            new UserCollectionStore(container.get(UserRepresentationDashboardListStore), {
                store,
                name: "userCollectionStore",
            }),
    );

    container
        .bind(UserActivityLogsListStore)
        .toDynamicValue(
            () =>
                new UserActivityLogsListStore(container.get(Api), {
                    store,
                    name: "userActivityLogsListStore",
                }),
        )
        .inSingletonScope();

    container
        .bind(UserActivityLogsCollectionStore)
        .toDynamicValue(
            () =>
                new UserActivityLogsCollectionStore(container.get(UserActivityLogsListStore), {
                    store,
                    name: "userActivityLogsCollectionStore",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminFeedbackListStore)
        .toDynamicValue(
            () =>
                new AdminFeedbackListStore(container.get(Api), {
                    store,
                    name: "adminFeedbackList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminPastProjectListStore)
        .toDynamicValue(
            () =>
                new AdminPastProjectListStore(container.get(Api), {
                    store,
                    name: "adminPastProjectList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminFileListStore)
        .toDynamicValue(
            () =>
                new AdminFileListStore(container.get(Api), {
                    store,
                    name: "adminFileList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminOrganizationListStore)
        .toDynamicValue(
            () =>
                new AdminOrganizationListStore(container.get(Api), {
                    store,
                    name: "adminOrganizationList",
                }),
        )
        .inSingletonScope();

    container.bind(AdminOrganizationCollectionStore).toDynamicValue(
        () =>
            new AdminOrganizationCollectionStore(container.get(AdminOrganizationListStore), {
                store,
                name: "adminOrganizationCollectionStore",
            }),
    );

    container
        .bind(AdminOrganizationLogListStore)
        .toDynamicValue(
            () =>
                new AdminOrganizationLogListStore(container.get(Api), {
                    store,
                    name: "adminOrganizationLogList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminPastProjectDestroyStore)
        .toDynamicValue(
            () =>
                new AdminPastProjectDestroyStore(container.get(Api), {
                    store,
                    name: "adminPastProjectDestroy",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminReportListStore)
        .toDynamicValue(
            () =>
                new AdminReportListStore(container.get(Api), {
                    store,
                    name: "adminReportList",
                }),
        )
        .inSingletonScope();

    container
        .bind(MemberPastProjectDestroyStore)
        .toDynamicValue(
            () =>
                new MemberPastProjectDestroyStore(container.get(Api), {
                    store,
                    name: "memberPastProjectDestroy",
                }),
        )
        .inSingletonScope();

    container
        .bind(CheckListStore)
        .toDynamicValue(
            () =>
                new CheckListStore(container.get(Api), {
                    store,
                    name: "checkList",
                }),
        )
        .inSingletonScope();

    container
        .bind(StatisticsStore)
        .toDynamicValue(
            () =>
                new StatisticsStore(container.get(Api), {
                    store,
                    name: "statistics",
                }),
        )
        .inSingletonScope();

    container
        .bind(RecaptchaService)
        .toDynamicValue(() => new RecaptchaService(process.env.RECAPTCHA_SITE_KEY))
        .inSingletonScope();

    container
        .bind(PaymentService)
        .toDynamicValue(
            () => new PaymentService(container.get(Api), process.env.STRIPE_PUBLISHABLE),
        )
        .inSingletonScope();

    container
        .bind(AdminMergerAcquisitionsListStore)
        .toDynamicValue(
            () =>
                new AdminMergerAcquisitionsListStore(container.get(Api), {
                    store,
                    name: "adminMergerAcquisitionsList",
                }),
        )
        .inSingletonScope();

    container
        .bind("AdminAllMergerAcquisitionsListStore")
        .toDynamicValue(() => {
            const instance = new AdminMergerAcquisitionsListStore(container.get(Api), {
                store,
                name: "adminAllMergerAcquisitionsListStore",
            });

            instance.pageSize = Number.MAX_SAFE_INTEGER;
            return instance;
        })
        .inSingletonScope();

    container
        .bind(AdminFlashNewsListStore)
        .toDynamicValue(
            () =>
                new AdminFlashNewsListStore(container.get(Api), {
                    store,
                    name: "adminFlashNewsList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminQuiltyInsightsListStore)
        .toDynamicValue(
            () =>
                new AdminQuiltyInsightsListStore(container.get(Api), {
                    store,
                    name: "adminQuiltyInsightsList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminQuiltyInsightCollectionStore)
        .toDynamicValue(
            () =>
                new AdminQuiltyInsightCollectionStore(container.get(AdminQuiltyInsightsListStore), {
                    store,
                    name: "quiltyInsightsReportCollectionStore",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminFlashNewsCollectionStore)
        .toDynamicValue(
            () =>
                new AdminFlashNewsCollectionStore(container.get(AdminFlashNewsListStore), {
                    store,
                    name: "flashNewsCollectionStore",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminQuiltyResourceListStore)
        .toDynamicValue(
            () =>
                new AdminQuiltyResourceListStore(container.get(Api), {
                    store,
                    name: "adminQuiltyResourceList",
                }),
        )
        .inSingletonScope();

    container
        .bind(AdminQuiltyResourceCollectionStore)
        .toDynamicValue(
            () =>
                new AdminQuiltyResourceCollectionStore(
                    container.get(AdminQuiltyResourceListStore),
                    {
                        store,
                        name: "quiltyResourceCollectionStore",
                    },
                ),
        )
        .inSingletonScope();

    return container;
};
