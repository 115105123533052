
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import LogoCard from "../../../../molecules/organization/logo/LogoCard.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { Transaction } from "../../../../utility/Transaction";
import { Container } from "inversify";
import { OrganizationLogoEditStore } from "../../../../stores/admin/organization/OrganizationLogoEditStore";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { OrganizationEditStore } from "../../../../stores/admin/organization/OrganizationEditStore";
import { handleError } from "../../../../../../common/src/utility/errorUtils";

@Component({
    components: { ActionButton, LogoCard },
})
export default class LogoEditView extends Vue {
    @Prop() editStore!: OrganizationEditStore;
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly logoEditStore: OrganizationLogoEditStore =
        this.container.get(OrganizationLogoEditStore);
    logoFile: any = null;
    transaction: Transaction = new Transaction();

    created() {
        this.logoEditStore.setId(this.organizationId);
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    async updateLogo() {
        this.logoEditStore.data.set("logo", this.logoFile);
        await this.logoEditStore.submit();

        if (this.logoEditStore.failed) {
            this.notificationStore.warning("Updating organization logo failed.");
        } else {
            this.notificationStore.success("Organization logo has been updated.");
            this.logoFile = null;
            // Reload logo
            this.editStore.load().catch(handleError);
        }
    }
}
