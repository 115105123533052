
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../components/MoreActions.vue";
import FormInput from "../../form/FormInput.vue";

@Component({
    components: { MoreActions, FormInput },
})
export default class ReportBulletPointTable extends Vue {
    @Prop({ default: () => [] }) value!: string[];

    get formattedValue(): string[] {
        return this.value || [];
    }

    @Emit()
    input(value: string[]) {
        return value;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add() {
        this.input([...this.formattedValue, ""]);
    }

    edit(bullet: string, editedIndex: number) {
        const newValue = [...this.formattedValue];
        newValue[editedIndex] = bullet;
        this.input(newValue);
    }
}
