import { Unmarshalled, unmarshal } from "@gsx/common";
import { NOT_FOUND } from "http-status-codes";
import { FormErrors } from "../../form/types";
import { formErrors } from "../../form/utils";
import { Action, VuexModule } from "vuex-class-modules";
import { getErrorHttpStatus } from "../../../../landing/src/utility/utils";

export abstract class EditStoreVuex<T> extends VuexModule {
    abstract data: Unmarshalled<T>;

    isLoaded: boolean = false;
    isLoading: boolean = false;
    isUpdating: boolean = false;
    loadError: Error | null = null;
    updateError: Error | null = null;

    get hasUpdatingFailed(): boolean {
        return this.updateError !== null;
    }

    get hasLoadingFailed(): boolean {
        return this.loadError !== null;
    }

    get notFound(): boolean {
        return getErrorHttpStatus(this.loadError) === NOT_FOUND;
    }

    get formErrors(): FormErrors<T> {
        return formErrors(this.updateError) as FormErrors<T>;
    }

    @Action
    async load(): Promise<void> {
        if (!this.isLoaded) {
            await this.reload();
        }
    }

    @Action
    async reload(): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        try {
            const response = await this.loadRequest();
            this.data = unmarshal(response);
            this.loadError = null;
            this.isLoaded = true;
        } catch (e: any) {
            this.loadError = e;
        } finally {
            this.isLoading = false;
        }
    }

    @Action
    async update(): Promise<void> {
        if (this.isUpdating) {
            return;
        }

        this.isUpdating = true;

        try {
            await this.updateRequest();
            this.updateError = null;
        } catch (e: any) {
            this.updateError = e;
        } finally {
            this.isUpdating = false;
        }
    }

    @Action
    reset(): void {
        this.isLoaded = false;
        this.isLoading = false;
        this.isUpdating = false;
        this.loadError = null;
        this.updateError = null;
    }

    protected abstract loadRequest(): Promise<T>;
    protected abstract updateRequest(): Promise<void>;
}
