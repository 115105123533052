
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import countries, { ICountry } from "../../../../vendor/country-state-city";
import FormSelect from "./FormSelect.vue";
import FormInput from "./FormInput.vue";
import FormMultiSelect from "./FormMultiSelect.vue";
import { getCountry } from "../../utility/address";

@Component({
    components: { FormMultiSelect, FormInput, FormSelect },
})
export default class FormCitizenship extends Vue {
    @Prop() value?: string;
    @Prop() error?: string;

    citizenship: ICountry | null = null;

    created() {
        this.citizenship = getCountry(this.value || null);
    }

    get countries(): ICountry[] {
        return countries.getAllCountries();
    }

    @Emit()
    input(country: ICountry | null) {
        this.citizenship = country;
        return country ? country.name : null;
    }
}
