import { RouteConfig } from "vue-router";
import { Container } from "inversify";
import SignInView from "../../views/guest/gsx/SignInView.vue";
import SignUpView from "../../views/guest/gsx/SignUpView.vue";
import { AuthGate } from "../../../../common/src/AuthGate";

export const createGSXGuestRoutes = (container: Container): RouteConfig[] => {
    const authGate: AuthGate = container.get(AuthGate);

    return [
        {
            path: "/",
            beforeEnter: () => {
                window.location.href = "https://www.globalspaceexchange.com";
            },
        },
        {
            path: "/signin",
            component: SignInView,
            beforeEnter: authGate.requireUnauthorizedGuard(),
        },
        {
            path: "/signup",
            component: SignUpView,
            beforeEnter: authGate.requireUnauthorizedGuard(),
        },
    ];
};
