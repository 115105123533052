
import { Component, Inject, Ref, Vue } from "vue-property-decorator";
import { RawUser } from "@gsx/common";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { Container } from "inversify";
import ProvideFeedback from "../../../../../common/src/feedback/ProvideFeedback.vue";
import ProfileItems from "../components/ProfileItems.vue";
import { SidebarStore } from "../../../../../common/src/stores/SidebarStore";
import RecommendOrganization from "../../../../../common/src/feedback/RecommendOrganization.vue";

@Component({
    components: { RecommendOrganization, ProfileItems, ProvideFeedback },
})
export default class Header extends Vue {
    @Inject("container") readonly container!: Container;
    authStore: AuthStore = this.container.get(AuthStore);
    sidebarStore: SidebarStore = this.container.get(SidebarStore);
    @Ref("searchInput") readonly searchInput!: HTMLButtonElement;
    isSearchBoxVisible: boolean = false;
    searchQuery: string = "";

    get user(): RawUser | null {
        return this.authStore.user;
    }

    get isRegistedUser(): boolean {
        return this.authStore.authorized;
    }

    goToSearch() {
        if (this.searchQuery) {
            this.$router.push(`/search/${encodeURIComponent(this.searchQuery)}`);
        }
    }

    openSearch() {
        this.isSearchBoxVisible = true;
        setTimeout(() => this.searchInput.focus(), 100);
    }

    closeSearch() {
        this.isSearchBoxVisible = false;
        this.searchQuery = "";
    }
}
