
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import InputField from "./InputField.vue";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";

@Component({
    components: { FormFeedback, FormLabel, InputField },
    inheritAttrs: false,
})
export default class InlineFormTextarea extends Vue {
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: 4 }) rows!: number;
    @Prop() help?: string;
    @Prop() error?: string;
    @Prop() placeholder?: string;
    @Prop({ default: "text-muted" }) labelClass?: string;
    @Ref("textarea") readonly input!: HTMLTextAreaElement;
    focused: boolean = false;

    focus() {
        this.focused = true;
        this.$nextTick(() => this.input.focus());
    }

    unfocus() {
        this.focused = false;
    }
}
