import { marshal, Nullable, PastProjectItem } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../../common/src/api/Api";
import { EditStore } from "../../../general/EditStore";
import { createPastProjectEmptyBody } from "../../../../utility/entity/pastProject";

@injectable()
export class PastProjectEditStore extends EditStore<PastProjectItem> {
    private id: string = "";
    readonly data: Nullable<PastProjectItem> = createPastProjectEmptyBody();

    constructor(private readonly api: Api) {
        super();
    }

    public setId(value: string): void {
        this.id = value;
    }

    protected async loadRequest(): Promise<PastProjectItem> {
        return this.api.user.pastProject.get(this.id);
    }

    protected async updateRequest(): Promise<void> {
        const body = marshal(this.data);
        await this.api.user.pastProject.update(this.id, body);
    }
}
