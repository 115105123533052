
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import FormInput from "../../../../molecules/form/FormInput.vue";
import { EmailVerificationStore } from "../../../../stores/sign-up/EmailVerificationStore";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { IndustrySegment, Nullable, SignUpBody } from "@gsx/common";
@Component({
    components: {
        ActionButton,
        FormInput,
    },
})
export default class IndustrySegmentSignupStep extends Vue {
    @Inject("container") readonly container!: Container;
    readonly emailVerificationStore: EmailVerificationStore =
        this.container.get(EmailVerificationStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    terms: boolean = false;
    @Prop() value!: Nullable<SignUpBody>;

    get IndustrySegment() {
        return IndustrySegment;
    }

    setIndustrySegment(industry: IndustrySegment) {
        this.value.industrySegment = industry;
    }

    @Emit()
    submit() {
        //
    }
}
