
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { SubscriptionPlan } from "@gsx/common/src/types/models/subscription";
import PlanCard from "../../../molecules/components/PlanCard.vue";
import { getUserSubscriptionPlan } from "../../../utility/entity/user";
import { hasActiveSubscription } from "../../../../../common/src/utility/entity/user";
import { PaymentService } from "../../../utility/payment/PaymentService";
import { Order, OrderStatus, OrderType } from "@gsx/common/src/types/models/order";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import AdvertisingQuoteModal from "../../../../../common/src/advertising-quote/AdvertisingQuoteModal.vue";
import { AdvertisingQuoteModalStore } from "../../../../../common/src/advertising-quote/AdvertisingQuoteModalStore";
import { OrderListStore } from "../../../stores/order/OrderListStore";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { event } from "vue-gtag";
import { TransactionType } from "@gsx/common";
import { IndividualSubscriptionRedirectModalStore } from "../../../../../common/src/subscription-quote/IndividualSubscriptionRedirectModalStore";

@Component({
    components: {
        NoRecords,
        LoadingOverlay,
        AdvertisingQuoteModal,
        ActionButton,
        PlanCard,
    },
})
export default class SubscriptionView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly paymentService: PaymentService = this.container.get(PaymentService);
    readonly listStore: OrderListStore = this.container.get(OrderListStore);
    readonly advertisingQuoteModalStore: AdvertisingQuoteModalStore = this.container.get(
        AdvertisingQuoteModalStore,
    );
    readonly individualSubscriptionRedirectModalStore: IndividualSubscriptionRedirectModalStore =
        this.container.get(IndividualSubscriptionRedirectModalStore);
    isPurchasingBook: boolean = false;

    created() {
        this.listStore.load();
    }

    get subscriptionPlan(): SubscriptionPlan | null {
        return getUserSubscriptionPlan(this.authStore.user);
    }

    get isSubscriptionActive(): boolean {
        return hasActiveSubscription(this.authStore.user);
    }

    get noRecords(): boolean {
        const items = this.listStore.items;
        return Boolean(items && !items.length);
    }

    get orders(): Order[] {
        return this.listStore.items ?? [];
    }

    badgeClass(order: Order) {
        return {
            "badge-accent": order.status === OrderStatus.RequestReceived,
            "badge-info": order.status === OrderStatus.InProcess,
            "badge-success": [OrderStatus.Delivered, OrderStatus.QuoteSent].includes(order.status),
            "badge-danger": [OrderStatus.Shipped].includes(order.status),
        };
    }

    openAdvertisingQuoteRequestModal() {
        this.advertisingQuoteModalStore.open();
    }

    subscribeUser() {
        this.individualSubscriptionRedirectModalStore.open();
    }

    async purchaseBook() {
        this.isPurchasingBook = true;

        try {
            await this.paymentService.purchaseProduct(TransactionType.SpaceInsight2020);
            event("Purchase", {
                eventCategory: "Purchase",
                eventAction: OrderType.SpaceInsight2020,
                eventLabel: OrderType.SpaceInsight2020,
            });
        } catch (e: any) {
            this.notificationStore.warning("Could not initialize a payment process.");
            handleError(e);
        } finally {
            this.isPurchasingBook = false;
        }
    }
}
