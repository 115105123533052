
import { Component, Inject, Vue } from "vue-property-decorator";
import Breadcrumbs from "../../molecules/layout/Breadcrumbs.vue";
import { Container } from "inversify";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import ListGroupItem from "../../molecules/components/ListGroupItem.vue";
import { Breadcrumb } from "../../molecules/layout/types";
import AuthorCardProfile from "../../molecules/components/AuthorCardProfile.vue";
import { createUrl } from "@gsx/common";

@Component({
    components: { AuthorCardProfile, ListGroupItem, Breadcrumbs },
})
export default class AdminView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);

    get breadcrumbs(): Breadcrumb[] {
        return this.$route.matched
            .flatMap((match) => match.meta.breadcrumbs || [])
            .map((breadcrumb) => ({
                ...breadcrumb,
                to: createUrl(breadcrumb.to, this.$route.params),
            }));
    }

    get title(): string {
        return this.$route.meta!.title;
    }
}
