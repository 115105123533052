
import { Component, Inject, Vue } from "vue-property-decorator";
import Header from "./thirdparty/Header.vue";
import Footer from "./thirdparty/Footer.vue";
import SubmitFeedbackModal from "../../../../common/src/feedback/SubmitFeedbackModal.vue";
import { FeedbackModalStore } from "../../../../common/src/feedback/FeedbackModalStore";
import { Container } from "inversify";
import Sidebar from "./thirdparty/Sidebar.vue";
import { IndividualSubscriptionRedirectModalStore } from "../../../../common/src/subscription-quote/IndividualSubscriptionRedirectModalStore";
import IndividualSubscriptionRedirectModal from "../../../../common/src/subscription-quote/IndividualSubscriptionRedirectModal.vue";

@Component({
    components: {
        Sidebar,
        SubmitFeedbackModal,
        Footer,
        Header,
        IndividualSubscriptionRedirectModal,
    },
})
export default class LayoutThirdParty extends Vue {
    @Inject("container") readonly container!: Container;
    readonly feedbackModalStore: FeedbackModalStore = this.container.get(FeedbackModalStore);
    readonly individualSubscriptionRedirectModalStore: IndividualSubscriptionRedirectModalStore =
        this.container.get(IndividualSubscriptionRedirectModalStore);
}
