import { Api } from "../../../../common/src/api/Api";
import { marshal, Nullable, SignUpBody, UserAffiliation } from "@gsx/common";
import { injectable } from "inversify";
import { RecaptchaService } from "../../../../common/src/utility/recaptcha/RecaptchaService";
import { SubmitStore } from "../../../../common/src/stores/general/SubmitStore";

@injectable()
export class SignUpStore extends SubmitStore<SignUpBody> {
    platformReferer: string = "gse";

    readonly data: Nullable<SignUpBody> = {
        affiliation: UserAffiliation.CommercialDeveloper,
        citizenship: "",
        city: null,
        organizationName: null,
        confirmPassword: null,
        country: "",
        email: null,
        firstName: null,
        lastName: null,
        newsletterEmail: true,
        password: null,
        phone: null,
        quiltyMarketMonitorEmails: true,
        quiltyReportNotificaitonEmails: true,
        quiltyNewsletterEmails: true,
        roleInOrganization: null,
        state: null,
        streetAddress: null,
        verificationCode: null,
        zipcode: null,
        emailVerificationCodeNeeded: true,
        industrySegment: null,
    };

    constructor(private readonly api: Api, private readonly recaptchaService: RecaptchaService) {
        super();
    }

    protected async request(): Promise<void> {
        const recaptchaToken = await this.recaptchaService.verify("signup");
        const body = marshal(this.data);
        await this.api.guest.signup(body, recaptchaToken, this.platformReferer);
    }
}
