
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import { OrganizationListStore } from "../../../stores/admin/organization/OrganizationListStore";
import { OrganizationCollectionStore } from "../../../stores/admin/organization/OrganizationCollectionStore";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import debounce from "debounce";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { OrganizationListItem } from "@gsx/common/src/types/http/admin/organization";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { isAdmin } from "@gsx/common";

@Component({
    components: { MoreActions, NoRecords, LoadingOverlay },
})
export default class OrganizationListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: OrganizationListStore = this.container.get(OrganizationListStore);
    readonly organizationCollectionStore: OrganizationCollectionStore = this.container.get(
        OrganizationCollectionStore,
    );
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    @Watch("listStore.query")
    @Watch("listStore.privateOrgs")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.listStore.load().catch(handleError);
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }

    getOrganizationUrl(organization: OrganizationListItem) {
        return `/admin/organizations/${organization.organizationid}/profile`;
    }

    isAdmin() {
        return isAdmin(this.authStore.user);
    }
}
