
import { Component, Inject, Vue } from "vue-property-decorator";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { Container } from "inversify";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import FormEnumMultiSelect from "../../../molecules/form/FormEnumMultiSelect.vue";
import FormCheckbox from "../../../molecules/form/FormCheckbox.vue";
import { UserRoleInOrganization } from "@gsx/common";
import { RequestAffiliationStore } from "../../../stores/member/request-affiliation/RequestAffiliationStore";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import FormInput from "../../../molecules/form/FormInput.vue";

@Component({
    components: {
        FormInput,
        FormEnumMultiSelect,
        ActionButton,
        FormCheckbox,
    },
})
export default class RequestAffiliationView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly requestAffiliationStore: RequestAffiliationStore =
        this.container.get(RequestAffiliationStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    orgAgreementAccepted: boolean = false;
    govAgreementAccepted: boolean = false;

    get values() {
        return this.requestAffiliationStore.data;
    }

    get errors() {
        return this.requestAffiliationStore.formErrors;
    }

    get UserRoleInOrganization() {
        return UserRoleInOrganization;
    }

    async submitForm() {
        await this.requestAffiliationStore.submit();

        if (this.requestAffiliationStore.failed) {
            this.notificationStore.warning("Your affiliation request has failed.");
        } else {
            this.notificationStore.success("Your affiliation request is being processed.");
            this.authStore
                .me()
                .then(() => this.$router.push("/member/profile"))
                .catch(handleError);
        }
    }

    orgCheckboxInput(value: boolean) {
        this.orgAgreementAccepted = value;
    }

    govCheckboxInput(value: boolean) {
        this.govAgreementAccepted = value;
    }
}
