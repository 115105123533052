
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import InputField from "./InputField.vue";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";
import { mapToEnum, stringToNumber } from "@gsx/common";
// @ts-ignore
import ClickOutside from "vue-click-outside";

enum Currency {
    USD = "USD",
    CAD = "CAD",
    EUR = "EUR",
    GBP = "GBP",
    AUD = "AUD",
    JPY = "JPY",
    KRW = "KRW",
    NOK = "NOK",
    THB = "THB",
    SEK = "SEK",
}

@Component({
    components: { FormFeedback, FormLabel, InputField },
    directives: { ClickOutside },
})
export default class InlineFormCurrency extends Vue {
    @Prop() label!: string;
    @Prop() value!: string | null;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) readonly!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;
    @Prop() placeholder?: string;
    @Prop({ default: "text-muted" }) labelClass?: string;

    @Ref("quantityElement") readonly quantityElement!: HTMLInputElement;
    focused: boolean = false;
    currency: Currency = Currency.USD;
    quantity: number | null = null;

    created() {
        this.onValueChange(this.value);
    }

    get currencies(): Currency[] {
        return Object.values(Currency);
    }

    get displayValue(): string | null {
        if (!this.quantity) {
            return null;
        }

        const format = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: this.currency,
        });
        return format.format(this.quantity);
    }

    @Emit()
    input(value: string | null) {
        return value;
    }

    @Watch("value")
    onValueChange(value: string | null): void {
        if (!value) {
            this.quantity = null;
            this.currency = Currency.USD;
            return;
        }

        const values = value.split(" ");
        if (values.length !== 2) {
            this.quantity = null;
            this.currency = Currency.USD;
            return;
        }

        this.quantity = stringToNumber(values[0]) ?? 0;
        this.currency = mapToEnum(Currency, values[1], Currency.USD);
    }

    @Watch("quantity")
    onQuantityChange(quantity: number | null) {
        if (!this.readonly) {
            const newValue = this.formatValue(quantity, this.currency);
            if (newValue !== this.value) {
                this.input(newValue);
            }
        }
    }

    @Watch("currency")
    onCurrencyChange(currency: Currency) {
        if (!this.readonly) {
            const newValue = this.formatValue(this.quantity, currency);
            if (newValue !== this.value) {
                this.input(newValue);
            }
        }
    }

    formatValue(quantity: number | null, currency: Currency): string | null {
        if (quantity === null) {
            return null;
        }

        return `${quantity} ${currency}`;
    }

    focus(e: any) {
        e.stopPropagation();
        this.focused = true;
        this.$nextTick(() => this.quantityElement.focus());
    }

    unfocus() {
        this.focused = false;
    }
}
