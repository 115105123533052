
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { ReportCreateStore } from "../../../stores/admin/report/ReportCreateStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { ReportListStore } from "../../../stores/admin/report/ReportListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import ReportForm from "../../../molecules/report/ReportForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";

@Component({
    components: {
        ReportForm,
        ActionButton,
    },
})
export default class ReportsCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: ReportCreateStore = this.container.get(ReportCreateStore);
    readonly listStore: ReportListStore = this.container.get(ReportListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    media?: { pdf?: File; picture?: File } = {};

    created() {
        if (!this.createStore.data.postDate) {
            this.createStore.data.postDate = new Date();
        }
    }

    async createReport() {
        if (!this.media?.pdf) {
            this.notificationStore.warning("Need to attach a PDF to create a report");
            return;
        }

        this.createStore.reportMedia.set("pdf", this.media?.pdf!);
        this.createStore.reportMedia.set("picture", this.media?.picture!);

        await this.createStore.submit();

        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create an report.");
        } else {
            this.notificationStore.success("You have created report successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/admin/reports");
        }
    }

    async sendTestEmail() {
        try {
            await this.api.admin.reports.sendEmail({
                ...this.createStore.data,
                distroList: ["report test"],
            });
            this.notificationStore.success("Test email sent");
        } catch (err) {
            this.notificationStore.danger("Failed to send test email.");
        }
    }

    async sendEmail() {
        if (this.createStore.data.distroList?.length) {
            try {
                await this.api.admin.reports.sendEmail(this.createStore.data);
                this.notificationStore.success("Email sent");
            } catch (err) {
                this.notificationStore.danger("Failed to send email.");
            }
        } else {
            this.notificationStore.warning("No distribution list for report");
        }
    }
}
