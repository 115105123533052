
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { MergerAcquisitionsCreateStore } from "../../../stores/admin/mergerAcquisitions/MergerAcquisitionsCreateStore";
import { MergerAcquisitionsListStore } from "../../../stores/admin/mergerAcquisitions/MergerAcquisitionsListStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import MergerAcquisitionForm from "../../../molecules/mergerAcquisitions/MergerAcquisitionForm.vue";
import { handleError } from "../../../../../common/src/utility/errorUtils";
@Component({
    components: {
        MergerAcquisitionForm,
    },
})
export default class MergerAcquistionsCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: MergerAcquisitionsCreateStore = this.container.get(
        MergerAcquisitionsCreateStore,
    );
    readonly listStore: MergerAcquisitionsListStore = this.container.get(
        MergerAcquisitionsListStore,
    );
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    async createMergerAcquisition() {
        await this.createStore.submit();
        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create a Merger/Acquisition.");
        } else {
            this.notificationStore.success("You have created a Merger/Acquisition successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/admin/mergerAcquisitions");
        }
    }
}
