
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { OrganizationEditStore } from "../../../stores/admin/organization/OrganizationEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { isAdmin } from "@gsx/common";
import { OrganizationMergerAcquisitionsEditStore } from "../../../stores/admin/organization/OrganizationMergerAcquisitionsEditStore";
import { QuiltyResourceListStore } from "../../../stores/admin/quiltyResource/QuiltyResourceListStore";

@Component
export default class OrganizationEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: OrganizationEditStore = this.container.get(OrganizationEditStore);
    organizationMergerAcquisitionsEditStore: OrganizationMergerAcquisitionsEditStore =
        this.container.get(OrganizationMergerAcquisitionsEditStore);
    quiltyResourceListStore: QuiltyResourceListStore = this.container.get(QuiltyResourceListStore);
    authStore: AuthStore = this.container.get(AuthStore);

    created() {
        this.editStore.setId(this.organizationId);
        this.editStore.load().catch(handleError);

        this.organizationMergerAcquisitionsEditStore.setId(this.organizationId);
        this.organizationMergerAcquisitionsEditStore.load().catch(handleError);
        this.quiltyResourceListStore.setOrganizationId(this.organizationId);
        this.quiltyResourceListStore.reload().catch(handleError);
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    get profileUrl(): string {
        return `/admin/organizations/${this.organizationId}/profile`;
    }

    get logoUrl(): string {
        return `/admin/organizations/${this.organizationId}/logo`;
    }

    get filesUrl(): string {
        return `/admin/organizations/${this.organizationId}/files`;
    }

    get pastProjectsUrl(): string {
        return `/admin/organizations/${this.organizationId}/past-projects`;
    }

    get checkListUrl(): string {
        return `/admin/organizations/${this.organizationId}/check-list`;
    }

    get logUrl(): string {
        return `/admin/organizations/${this.organizationId}/log`;
    }

    get quiltyResourcesUrl(): string {
        return `/admin/organizations/${this.organizationId}/quilty-resources`;
    }

    get hasAdminAccess(): boolean {
        return isAdmin(this.authStore.user);
    }
}
