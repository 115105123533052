
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Nullable, PastProjectItem, PastProjectType } from "@gsx/common";
import { Container } from "inversify";
import FormTextarea from "../../form/FormTextarea.vue";
import FormSimpleMultiSelect from "../../form/FormSimpleMultiSelect.vue";
import FormInput from "../../form/FormInput.vue";
import FormEnumMultiSelect from "../../form/FormEnumMultiSelect.vue";
import { FormErrors } from "../../../../../common/src/form/types";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { ProductTypeStore } from "../../../../../common/src/stores/ProductTypeStore";
import FormTreeselect from "../../form/FormTreeselect.vue";
import { ServiceTypeStore } from "../../../../../common/src/stores/ServiceTypeStore";

@Component({
    components: {
        FormTreeselect,
        FormTextarea,
        FormSimpleMultiSelect,
        FormInput,
        FormEnumMultiSelect,
        ActionButton,
    },
})
export default class PastProjectForm extends Vue {
    @Prop({ default: false }) loading!: boolean;
    @Prop({ default: false }) destroying!: boolean;
    @Prop({ default: false }) allowDestroy!: boolean;
    @Prop() values!: Nullable<PastProjectItem>;
    @Prop() errors!: FormErrors<PastProjectItem>;
    @Inject("container") readonly container!: Container;
    readonly productTypeStore: ProductTypeStore = this.container.get(ProductTypeStore);
    readonly serviceTypeStore: ServiceTypeStore = this.container.get(ServiceTypeStore);

    created() {
        this.productTypeStore.load().catch(handleError);
        this.serviceTypeStore.load().catch(handleError);
    }

    get productOptions() {
        return this.productTypeStore.item || [];
    }

    get serviceOptions() {
        return this.serviceTypeStore.item || [];
    }

    get PastProjectType() {
        return PastProjectType;
    }

    @Emit()
    submit() {
        //
    }

    @Emit()
    destroy() {
        //
    }
}
