export class Transaction {
    public isProcessing: boolean = false;

    public async run<T>(callback: () => T): Promise<T> {
        if (this.isProcessing) {
            throw new Error("Transaction conflict");
        }

        this.isProcessing = true;

        try {
            return await callback();
        } finally {
            this.isProcessing = false;
        }
    }

    public wrap<T, U>(callback: (...args: U[]) => T) {
        return async (...args: U[]) => {
            if (this.isProcessing) {
                throw new Error("Transaction conflict");
            }

            this.isProcessing = true;

            try {
                return await callback(...args);
            } finally {
                this.isProcessing = false;
            }
        };
    }
}
