import ListStoreVuex from "./ListStoreVuex";
import { Mutation } from "vuex-class-modules";
import { UserListResponse } from "@gsx/common";

export default abstract class UserStoreVuex<I> extends ListStoreVuex<I> {
    quiltyCount: any = {};
    gsxCount: any = {};

    @Mutation
    markAsLoaded(response: UserListResponse<I[]>) {
        this.items = response.data;
        this.count = response.count;
        this.quiltyCount = response.quiltyCount;
        this.gsxCount = response.gsxCount;
        this.error = null;
        this.isLoading = false;
    }

    @Mutation
    clear() {
        super.clear(); // INHERTIANCE SETTER / GETTER / DECONSTRUCTORING
        this.quiltyCount = 0;
        this.gsxCount = 0;
    }
}
