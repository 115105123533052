import {
    OrganizationListItem,
    OrganizationListResponse,
} from "@gsx/common/src/types/http/admin/organization";
import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module({ generateMutationSetters: true })
export class OrganizationListStore extends ListStoreVuex<OrganizationListItem> {
    query: string = "";
    privateOrgs: boolean = false;

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch(): Promise<OrganizationListResponse> {
        return this.api.admin.organization.list({
            ...this.queryParams,
            query: this.query,
            privateOrgs: `${this.privateOrgs}`,
        });
    }
}
