import { ReportListItem, ReportListResponse } from "@gsx/common/src/types/http/admin/report";
import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module({ generateMutationSetters: true })
export class ReportListStore extends ListStoreVuex<ReportListItem> {
    query: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
        this.pageSize = 5;
    }

    protected async fetch(): Promise<ReportListResponse> {
        return this.api.admin.reports.list({
            ...this.queryParams,
            sort: this.sort || "postDate",
            order: this.order,
            skip: `${this.pageSize * (this.page - 1)}`,
            take: `${this.pageSize}`,
            query: this.query,
        });
    }
}
