
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../../../molecules/components/MoreActions.vue";
import { Container } from "inversify";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { PastProjectDestroyStore } from "../../../../stores/admin/organization/PastProjectDestroyStore";
import { PastProjectListItem as PastProjectListItemType } from "@gsx/common/src/types/http/admin/organizationPastProject";
import { Confirm } from "../../../../../../common/src/utility/confirmations";

@Component({
    components: { MoreActions },
})
export default class PastProjectListItem extends Vue {
    @Prop() organizationId!: string;
    @Prop() pastProject!: PastProjectListItemType;
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly destroyStore: PastProjectDestroyStore = this.container.get(PastProjectDestroyStore);

    get pastProjectUrl(): string {
        const pastprojectid = this.pastProject.pastprojectid;
        return `/admin/organizations/${this.organizationId}/past-projects/${pastprojectid}`;
    }

    get status(): string {
        return this.pastProject.dateCompleted ? "Completed" : "In-Work";
    }

    async destroy() {
        const result = await Confirm.fire();

        if (!result.value) {
            return;
        }

        await this.destroyStore.delete(this.pastProject.pastprojectid);

        if (this.destroyStore.failed) {
            this.notificationStore.warning("Could not delete past project.");
        } else {
            this.notificationStore.success("You have deleted past project successfully.");
            this.$emit("destroy");
        }
    }
}
