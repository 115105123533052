import { Api } from "../../../../common/src/api/Api";
import ListStoreVuex from "../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";
import { OrganizationListItem } from "@gsx/common/src/types/http/guest/organization";

@Module()
export class OrganizationListStore extends ListStoreVuex<OrganizationListItem> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch() {
        return this.api.guest.organization.list();
    }
}
