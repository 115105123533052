import { Api } from "../../../../../common/src/api/Api";
import UserStoreVuex from "../../../../../common/src/stores/general/UserStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";
import { RawUser, ToJson } from "@gsx/common";
import { UserRepresentationRawListResponse } from "@gsx/common/src/types/http/admin/user";

@Module({ generateMutationSetters: true })
export class UserRepresentationDashboardListStore extends UserStoreVuex<ToJson<RawUser>> {
    query: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch(): Promise<UserRepresentationRawListResponse> {
        return this.api.admin.user.dashboardList({
            ...this.queryParams,
            query: this.query,
        });
    }
}
