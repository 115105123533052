
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { QuiltyInsightsListStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsListStore";
import { QuiltyInsightsListItem } from "@gsx/common/src/types/http/admin/quiltyInsights";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { debounce } from "debounce";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { QuiltyInsightsCollectionStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsCollectionStore";

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords },
})
export default class QuiltyInsightsView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly listStore: QuiltyInsightsListStore = this.container.get(QuiltyInsightsListStore);
    readonly quiltyInsightsCollectionStore: QuiltyInsightsCollectionStore = this.container.get(
        QuiltyInsightsCollectionStore,
    );
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    @Watch("listStore.query")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.listStore.load().catch(handleError);
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }

    getQuiltyInsightsUrl(quiltyInsights: QuiltyInsightsListItem) {
        return `/admin/quiltyInsights/${quiltyInsights.quiltyInsightsId}`;
    }

    async destoryQuiltyInsights(quiltyInsightsId: string) {
        try {
            var confirmation = confirm("Are you sure you want to delete report");
            if (confirmation) {
                await this.api.admin.quiltyInsights.delete(quiltyInsightsId);
                this.notificationStore.success("User has been deleted.");
                this.listStore.reload().catch(handleError);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting flash news.");
        }
    }
}
