
import { Component, Inject, Vue } from "vue-property-decorator";
import Notification from "./Notification.vue";
import { Container } from "inversify";
import { NotificationStore } from "../../../../common/src/stores/NotificationStore";

@Component({
    components: { Notification },
})
export default class NotificationContainer extends Vue {
    @Inject("container") readonly container!: Container;
    notificationStore: NotificationStore = this.container.get(NotificationStore);
}
