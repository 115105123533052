import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../common/src/api/Api";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { FlashNewsBody } from "@gsx/common/src/types/http/admin/flashNews";
import { createFlashNewsEmptyBody } from "../../../utility/entity/flashNews";

@injectable()
export class FlashNewsCreateStore extends SubmitStore<FlashNewsBody> {
    readonly data: Nullable<FlashNewsBody> = createFlashNewsEmptyBody();
    flashNewsMedia: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.flashNews.create(body, this.flashNewsMedia);
    }
}
