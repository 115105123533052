
import { Component, Vue } from "vue-property-decorator";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import SignInBox from "../../../molecules/components/thirdparty/SignInBox.vue";

@Component({
    components: { SignInBox, ActionButton },
})
export default class SignInView extends Vue {
    //
}
