
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { Nullable } from "@gsx/common";
import { FormErrors } from "../../../../common/src/form/types";
import { ReportBody } from "@gsx/common/src/types/http/admin/report";
import { Container } from "inversify";
import FormLabel from "../form/FormLabel.vue";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import LoadingOverlay from "../../../../common/src/components/LoadingOverlay.vue";
import axios from "axios";
import axiosRetry from "axios-retry";

const axiosInstance = axios.create();

axiosRetry(axiosInstance, {
    retries: 3,
    retryCondition: (error) =>
        axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status === 500,
    retryDelay: axiosRetry.exponentialDelay,
});

@Component({
    components: {
        LoadingOverlay,
        FormLabel,
    },
})
export default class ReportPDFPane extends Vue {
    @Prop() values!: Nullable<ReportBody>;
    @Prop() errors!: FormErrors<ReportBody>;
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    @Prop() value?: { pdf?: File; picture?: File };
    tempPDF: any = null;
    pdfBlob: any = null;
    loadingPDFBlob = false;
    errorLoadingPDFBlob = false;

    mounted() {
        this.convertFileToData(this.value?.pdf!);
        this.fetchPDFBlob();
    }

    async fetchPDFBlob() {
        this.pdfBlob = null;
        if (this.values && this.values.pdf) {
            try {
                this.loadingPDFBlob = true;
                const response = await axiosInstance.get(`${this.values.pdf}?${this.sasToken}`, {
                    responseType: "blob",
                });
                if (response && response.data) {
                    this.pdfBlob = URL.createObjectURL(response.data);
                }
            } catch (err) {
                this.errorLoadingPDFBlob = true;
                console.error("ERROR FETCHING PDF", err);
            } finally {
                this.loadingPDFBlob = false;
            }
        }
    }

    get sasToken() {
        return this.authStore.sasToken;
    }

    @Emit()
    input(file: File | null) {
        return { ...this.value, pdf: file };
    }

    changeReportPDF(event: any) {
        const target: HTMLInputElement = event.target;
        const file = target.files && target.files[0];
        this.input(file);
        this.convertFileToData(file);
    }

    convertFileToData(file: File | null) {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.tempPDF = e.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            this.tempPDF = null;
        }
    }
}
