
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Email, EmailType, Nullable } from "@gsx/common";
import FormSelect from "../../../form/FormSelect.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import Modal from "../../../../../../common/src/components/Modal.vue";
import FormInput from "../../../form/FormInput.vue";
import FormTextarea from "../../../form/FormTextarea.vue";

@Component({
    components: {
        FormTextarea,
        FormInput,
        ActionButton,
        FormSelect,
        Modal,
    },
})
export default class EditEmailModal extends Vue {
    @Prop() value!: Nullable<Email>;

    comments: string | null = this.value.comments;
    email: string | null = this.value.email;
    type: EmailType | null = this.value.type;

    get emailTypes(): EmailType[] {
        return Object.values(EmailType);
    }

    @Emit()
    submit(): Nullable<Email> {
        return {
            comments: this.comments,
            type: this.type,
            email: this.email,
        };
    }

    @Emit()
    close() {
        //
    }

    async onSubmit() {
        this.submit();
        this.close();
    }
}
