
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ListGroupItem extends Vue {
    @Prop() name!: string;
    @Prop({ default: false }) active!: boolean;
    @Prop() customClass?: string;
    @Prop({ default: false }) expanded!: boolean;
    @Prop() icon?: string;
    @Prop() to?: string;
    localExpanded: boolean = this.expanded;

    get hasDefaultSlot(): boolean {
        return !!this.$slots.default;
    }

    toggle() {
        this.localExpanded = !this.localExpanded;
    }
}
