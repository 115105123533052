
import { Component, Inject, Vue } from "vue-property-decorator";
import Breadcrumbs from "../../molecules/layout/Breadcrumbs.vue";
import { Container } from "inversify";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import ListGroupItem from "../../molecules/components/ListGroupItem.vue";
import { Breadcrumb } from "../../molecules/layout/types";
import AuthorCardProfile from "../../molecules/components/AuthorCardProfile.vue";
import OrganizationLogoEditCard from "../../molecules/member/OrganizationLogoEditCard.vue";
import TierProgressBar from "../../../../common/src/components/TierProgressBar.vue";

@Component({
    components: {
        OrganizationLogoEditCard,
        AuthorCardProfile,
        ListGroupItem,
        Breadcrumbs,
        TierProgressBar,
    },
})
export default class MemberView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);

    get breadcrumbs(): Breadcrumb[] {
        return this.$route.matched.flatMap((match) => match.meta.breadcrumbs || []);
    }

    get title(): string {
        return this.$route.meta!.title;
    }

    get isOrganizationTabActive(): boolean {
        return this.$route.fullPath.startsWith("/member/organization");
    }

    get isAffiliated(): boolean {
        return !!this.authStore.user?.organization;
    }
}
