
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import debounce from "debounce";
import SearchInput from "../molecules/search/SearchInput.vue";
import { SearchStore } from "../stores/search/SearchStore";
import ResultCard from "../molecules/search/ResultCard.vue";
import { handleError } from "../../../common/src/utility/errorUtils";
import RetryFailed from "../../../common/src/components/RetryFailed.vue";
import LoadingOverlay from "../../../common/src/components/LoadingOverlay.vue";

@Component({
    components: { RetryFailed, LoadingOverlay, ResultCard, SearchInput },
})
export default class SearchView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly searchStore: SearchStore = this.container.get(SearchStore);
    readonly minQueryLength = 2;

    query: string = "";

    loadDebounce = debounce(this.load, 300);

    created() {
        this.query = this.$route.params.search || "";
        this.load();
    }

    get isValid(): boolean {
        return this.query.length >= this.minQueryLength;
    }

    @Watch("query")
    async updateQuery() {
        this.$router.push(`/search/${this.query}`);
        this.loadDebounce();
    }

    load() {
        if (this.isValid) {
            this.searchStore.load(this.query).catch(handleError);
        }
    }
}
