
import { Component, Inject, Vue } from "vue-property-decorator";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { Container } from "inversify";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { isAdmin } from "@gsx/common";

@Component
export default class ProfileItems extends Vue {
    @Inject("container") readonly container!: Container;
    authStore: AuthStore = this.container.get(AuthStore);
    notificationStore: NotificationStore = this.container.get(NotificationStore);

    get hasAdminAccess(): boolean {
        return isAdmin(this.authStore.user);
    }

    async signout() {
        // The order is important. We want to change path and then signout user.
        // Otherwise component based on user data can re-render and break the page.
        this.$router.push("/");
        await this.authStore.signout();
        this.notificationStore.success("You have signed out successfully.");
    }
}
