
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import InlineFormInput from "../../form/InlineFormInput.vue";
import InlineFormSelect from "../../form/InlineFormSelect.vue";
import { FormErrors } from "../../../../../common/src/form/types";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";
import { Nullable, OrganizationProfileBody } from "@gsx/common";
import InlineFormBoolSelect from "../../form/InlineFormBoolSelect.vue";
import countries from "../../../../../vendor/country-state-city";
import InlineFormMultiSelect from "../../form/InlineFormMultiSelect.vue";
import { Container } from "inversify";
import { OrganizationListStore } from "../../../stores/organization/OrganizationListStore";
import { SelectOption } from "../../form/types";
import { handleError } from "../../../../../common/src/utility/errorUtils";

@Component({
    components: {
        InlineFormMultiSelect,
        InlineFormBoolSelect,
        InlineFormSimpleMultiSelect,
        InlineFormSelect,
        InlineFormInput,
    },
})
export default class PartnershipPane extends Vue {
    @Inject("container") readonly container!: Container;
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<OrganizationProfileBody>;
    readonly organizationListStore: OrganizationListStore =
        this.container.get(OrganizationListStore);

    created() {
        this.organizationListStore.load().catch(handleError);
    }

    get currentPartners(): SelectOption[] {
        return (this.values.currentPartners ?? []).map((partner) => ({
            id: partner.organizationId ?? partner.name,
            name: partner.name,
        }));
    }

    get countries(): string[] {
        return countries.getAllCountries().map((country) => country.name);
    }

    get organizationOptions(): SelectOption[] {
        return (this.organizationListStore.items ?? []).map((organization) => ({
            id: organization.organizationid,
            name: organization.organizationName,
        }));
    }

    updateCurrentPartners(options: SelectOption[]) {
        this.values.currentPartners = options.map((option) => ({
            organizationId: option.id === option.name ? undefined : (option.id as any),
            name: option.name,
        }));
    }
}
