import { marshal, Nullable, PastProjectItem } from "@gsx/common";
import { injectable } from "inversify";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { createPastProjectEmptyBody } from "../../../utility/entity/pastProject";
import { Api } from "../../../../../common/src/api/Api";

@injectable()
export class PastProjectCreateStore extends SubmitStore<PastProjectItem> {
    private organizationId: string = "";
    readonly data: Nullable<PastProjectItem> = createPastProjectEmptyBody();

    constructor(private readonly api: Api) {
        super();
    }

    public setOrganizationId(id: string): void {
        this.organizationId = id;
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.organization.pastProject.create(this.organizationId, body);
    }
}
