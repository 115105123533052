
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import {
    EnvironmentalTest,
    ManufactureInternal,
    Nullable,
    OrganizationProfileBody,
    OrganizationStatus,
} from "@gsx/common";
import InlineFormInput from "../../form/InlineFormInput.vue";
import InlineFormTextarea from "../../form/InlineFormTextarea.vue";
import InlineFormSelect from "../../form/InlineFormSelect.vue";
import { FormErrors } from "../../../../../common/src/form/types";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";
import MoreActions from "../../components/MoreActions.vue";
import OrganizationAddressTable from "./address/OrganizationAddressTable.vue";
import { Container } from "inversify";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { StandardOptionStore } from "../../../../../common/src/stores/StandardOptionStore";
import OrganizationPhoneNumberTable from "./phone-number/OrganizationPhoneNumberTable.vue";
import OrganizationPointOfContactTable from "./point-of-contact/OrganizationPointOfContactTable.vue";
import OrganizationEmailTable from "./email/OrganizationEmailTable.vue";
import InlineAddressFormGroup from "../../form/InlineAddressFormGroup.vue";
import InlineFormCurrency from "../../form/InlineFormCurrency.vue";
import InlineFormBoolSelect from "../../form/InlineFormBoolSelect.vue";

@Component({
    components: {
        InlineFormCurrency,
        InlineAddressFormGroup,
        OrganizationEmailTable,
        OrganizationPhoneNumberTable,
        OrganizationAddressTable,
        OrganizationPointOfContactTable,
        MoreActions,
        InlineFormSimpleMultiSelect,
        InlineFormSelect,
        InlineFormTextarea,
        InlineFormInput,
        InlineFormBoolSelect,
    },
})
export default class OrganizationResourcesPane extends Vue {
    @Inject("container") readonly container!: Container;
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<OrganizationProfileBody>;
    @Prop() admin!: boolean;
    standardOptionStore: StandardOptionStore = this.container.get(StandardOptionStore);

    created() {
        this.standardOptionStore.load().catch(handleError);
    }

    get standardOptions(): string[] {
        return (this.standardOptionStore.item || []).map((option) => option.value);
    }

    get environmentalTestTypes(): EnvironmentalTest[] {
        return Object.values(EnvironmentalTest);
    }

    get manufactureInternalTypes(): ManufactureInternal[] {
        return Object.values(ManufactureInternal);
    }

    get organizationStatuses(): OrganizationStatus[] {
        return Object.values(OrganizationStatus);
    }
}
