
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { FlashNewsCreateStore } from "../../../stores/admin/flashNews/FlashNewsCreateStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { FlashNewsListStore } from "../../../stores/admin/flashNews/FlashNewsListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import FlashNewsForm from "../../../molecules/flashNews/FlashNewsForm.vue";
@Component({
    components: {
        FlashNewsForm,
    },
})
export default class FlashNewsCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: FlashNewsCreateStore = this.container.get(FlashNewsCreateStore);
    readonly listStore: FlashNewsListStore = this.container.get(FlashNewsListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    media?: { picture?: File } = {};
    async createFlashNews() {
        this.createStore.flashNewsMedia.set("picture", this.media?.picture!);
        await this.createStore.submit();
        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create an flash news.");
        } else {
            this.notificationStore.success("You have created flash news successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/admin/flashNews");
        }
    }
}
