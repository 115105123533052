import { Api } from "../../../../../../common/src/api/Api";
import { marshal, Nullable, OrganizationProfileBody } from "@gsx/common";
import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";
import { AuthStore } from "../../../../../../common/src/stores/AuthStore";
import { SubmitStore } from "../../../../../../common/src/stores/general/SubmitStore";
import { createOrganizationEmptyBody } from "../../../../utility/entity/organization";

@injectable()
export class UpdateUserOrganizationStore extends SubmitStore<OrganizationProfileBody> {
    readonly data: Nullable<OrganizationProfileBody> = createOrganizationEmptyBody(
        this.authStore.user!.organization,
    );

    constructor(private readonly api: Api, private readonly authStore: AuthStore) {
        super();
    }

    @boundMethod
    async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.user.updateUserOrganization(body);
    }
}
