import { marshal, Unmarshalled } from "@gsx/common";
import { Api } from "../../../../../common/src/api/Api";
import { createCheckListEmptyBody } from "../../../utility/entity/organization";
import { CheckListBody } from "@gsx/common/src/types/http/user/organization/checkList";
import { EditStoreVuex } from "../../../../../common/src/stores/general/EditStoreVuex";
import { Action, Module, RegisterOptions } from "vuex-class-modules";
import omit from "lodash.omit";

@Module({ generateMutationSetters: true })
export class CheckListStore extends EditStoreVuex<CheckListBody> {
    data: Unmarshalled<CheckListBody> = createCheckListEmptyBody();
    id: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    @Action
    public async init(id: string): Promise<void> {
        if (this.id !== id) {
            this.id = id;
            this.data = createCheckListEmptyBody();
            this.reset();
            await this.reload();
        }
    }

    protected async loadRequest(): Promise<CheckListBody> {
        return this.api.admin.organization.getCheckList(this.id);
    }

    protected async updateRequest(): Promise<void> {
        const body = marshal(omit(this.data, ["updatedAt"]));
        await this.api.admin.organization.updateCheckList(this.id, body);
    }
}
