
import { Component, Inject, Vue } from "vue-property-decorator";
import LogoCard from "../../../../molecules/organization/logo/LogoCard.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import FilesUpload from "../../../../molecules/organization/files/FilesUpload.vue";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import { FileListStore } from "../../../../stores/admin/organization/FileListStore";
import { Container } from "inversify";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import { OrganizationFileItem } from "@gsx/common/src/types/models/organizationFile";

@Component({
    components: { LoadingOverlay, FilesUpload, ActionButton, LogoCard },
})
export default class FilesView extends Vue {
    @Inject("container") container!: Container;
    private readonly fileListStore: FileListStore = this.container.get(FileListStore);

    created() {
        this.fileListStore.load(this.organizationId).catch(handleError);
    }

    get value(): OrganizationFileItem[] {
        return this.fileListStore.items ?? [];
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }
}
