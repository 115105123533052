
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { FlashNewsListStore } from "../../../stores/admin/flashNews/FlashNewsListStore";
import { FlashNewsCollectionStore } from "../../../stores/admin/flashNews/FlashNewsCollectionStore";

import { FlashNewsListItem } from "@gsx/common/src/types/http/admin/flashNews";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { debounce } from "debounce";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords },
})
export default class FlashNewsView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly listStore: FlashNewsListStore = this.container.get(FlashNewsListStore);
    readonly flashNewsCollectionStore: FlashNewsCollectionStore =
        this.container.get(FlashNewsCollectionStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    @Watch("listStore.query")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.listStore.load().catch(handleError);
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }

    getFlashNewsUrl(flashNews: FlashNewsListItem) {
        return `/admin/flashNews/${flashNews.flashNewsId}`;
    }

    async destoryFlashNews(flashNewsId: string) {
        try {
            var confirmation = confirm("Are you sure you want to delete report");
            if (confirmation) {
                await this.api.admin.flashNews.delete(flashNewsId);
                this.notificationStore.success("User has been deleted.");
                this.listStore.reload().catch(handleError);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting flash news.");
        }
    }
}
