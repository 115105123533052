
import { Component, Vue } from "vue-property-decorator";
import RequestForm from "../molecules/request-form/RequestForm.vue";

@Component({
    components: { RequestForm },
})
export default class RequestFormView extends Vue {
    onSubmit() {
        this.$router.back();
    }
}
