
import { Component, Prop, Vue } from "vue-property-decorator";
import InlineFormInput from "../../form/InlineFormInput.vue";
import InlineFormTextarea from "../../form/InlineFormTextarea.vue";
import InlineFormBoolSelect from "../../form/InlineFormBoolSelect.vue";
import { Nullable, OrganizationProfileBody } from "@gsx/common";
import { FormErrors } from "../../../../../common/src/form/types";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";

@Component({
    components: {
        InlineFormSimpleMultiSelect,
        InlineFormBoolSelect,
        InlineFormTextarea,
        InlineFormInput,
    },
})
export default class OrganizationalPreferencesPane extends Vue {
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<OrganizationProfileBody>;
}
