
import { Component, Vue } from "vue-property-decorator";
import RequestListView from "./RequestListView.vue"; // Adjust the import path accordingly
// import SubscriptionOrdersView from "./SubscriptionOrdersView.vue"; // Assume you have this component

@Component({
    components: {
        RequestListView,
        // SubscriptionOrdersView,
    },
})
export default class RequestTabsView extends Vue {
    tabs = [
        { title: "Requests", component: "RequestListView", active: true },
        { title: "Subscription Orders", component: "SubscriptionOrdersView", active: false },
    ];

    // Activate a tab: set the 'active' state to true for the selected tab
    activateTab(selectedIndex: number) {
        this.tabs.forEach((tab, index) => {
            tab.active = index === selectedIndex;
        });
    }
}
