
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { QuiltyResourceCollectionStore } from "../../../stores/admin/quiltyResource/QuiltyResourceCollectionStore";
import { QuiltyResourceListItem } from "@gsx/common/src/types/http/admin/quiltyResource";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { debounce } from "debounce";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";

import { QuiltyResourceListStore } from "../../../stores/admin/quiltyResource/QuiltyResourceListStore";
import QuiltyResourceTable from "../../../molecules/quiltyResource/QuiltyResourceTable.vue";

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords, QuiltyResourceTable },
})
export default class QuiltyResourceListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: QuiltyResourceListStore = this.container.get(QuiltyResourceListStore);
    readonly collectionStore: QuiltyResourceCollectionStore = this.container.get(
        QuiltyResourceCollectionStore,
    );
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    @Watch("listStore.query")
    reloadDebounce = debounce(this.reload, 300);

    get organizationId() {
        return this.$route.params.organization;
    }

    created() {
        this.listStore.setOrganizationId(this.organizationId);
        this.listStore.load().catch(handleError);
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }

    editQuiltyResource(quiltyResource: QuiltyResourceListItem) {
        this.$router.push(
            `/admin/organizations/${this.organizationId}/quilty-resources/${quiltyResource.quiltyResourceId}`,
        );
    }
}
