
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AccordionCard extends Vue {
    @Prop() title!: string;
    @Prop() to?: string;
    active: boolean = false;

    toggle() {
        this.active = !this.active;
    }
}
