import { Nullable, MergerAcquisition, GeneralTransactionType } from "@gsx/common";
import get from "lodash.get";
import { MergerAcquisitionBody } from "@gsx/common/src/types/http/admin/mergerAcquisition";

export const createMergerAcquisitionEmptyBody = (
    data?: Partial<MergerAcquisition>,
): Nullable<MergerAcquisitionBody> => ({
    announceDate: get(data, "announceDate", null),
    acquirerCountry: get(data, "acquirerCountry", null),
    acquirerOrganization: get(data, "acquirerOrganization", {
        organizationName: "",
        organizationId: "",
    }),
    acquirerRegion: get(data, "acquirerRegion", null),
    enterpriseValue: get(data, "enterpriseValue", null),
    generalTransactionType: get(
        data,
        "generalTransactionType",
        GeneralTransactionType.MergerAcquisition,
    ),
    investmentAmount: get(data, "investmentAmount", null),
    investorGeneralSectorCategory: get(data, "investorGeneralSectorCategory", null),
    investors: get(data, "investors", null),
    mergerAcquisitionId: get(data, "mergerAcquisitionId", null),
    notes: get(data, "notes", null),
    sourceLink: get(data, "sourceLink", null),
    status: get(data, "status", null),
    targetCountry: get(data, "targetCountry", null),
    targetGeneralSectorCategory: get(data, "targetGeneralSectorCategory", null),
    targetOrganization: get(data, "targetOrganization", {
        organizationName: "",
        organizationId: "",
    }),
    targetProfile: get(data, "targetProfile", null),
    targetRegion: get(data, "targetRegion", null),
    trailingEbitda: get(data, "trailingEbitda", null),
    trailingRev: get(data, "trailingRev", null),
    transactionCurrency: get(data, "transactionCurrency", null),
    transactionEnterpriseValue: get(data, "transactionEnterpriseValue", null),
    transactionSubtype: get(data, "transactionSubtype", null),
});
