import { Module } from "vuex-class-modules";
import { CollectionStore } from "../../../../../common/src/stores/CollectionStore";
import { UserActivityLogListItem } from "@gsx/common/src/types/http/admin/userActivityLog";
import { SortOrder } from "@gsx/common";

@Module()
export class UserActivityLogsCollectionStore extends CollectionStore<UserActivityLogListItem> {
    sort: keyof UserActivityLogListItem = "createdAt";
    order: SortOrder = SortOrder.Desc;
}
