
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { PastProjectListStore } from "../../../../stores/admin/organization/PastProjectListStore";
import PastProjectListItem from "./PastProjectListItem.vue";
import MoreActions from "../../../../molecules/components/MoreActions.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import NoRecords from "../../../../../../common/src/components/NoRecords.vue";

@Component({
    components: { PastProjectListItem, NoRecords, MoreActions, ActionButton, LoadingOverlay },
})
export default class PastProjectListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: PastProjectListStore = this.container.get(PastProjectListStore);

    created() {
        this.listStore.loadWithId(this.organizationId).catch(handleError);
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    get createPastProjectUrl(): string {
        return `/admin/organizations/${this.organizationId}/past-projects/create`;
    }
}
