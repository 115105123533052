
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { SignUpStore } from "../../../stores/sign-up/SignUpStore";
import FormInput from "../../../molecules/form/FormInput.vue";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import FormSelect from "../../../molecules/form/FormSelect.vue";
import {
    UserAffiliation,
    UserRoleInOrganization,
    OrderType,
    QuiltyUserRoleInOrganization,
} from "@gsx/common";
import FormCheckbox from "../../../molecules/form/FormCheckbox.vue";
import { focusOnFirstInvalidInput } from "../../../../../common/src/form/utils";
import FormTel from "../../../molecules/form/FormTel.vue";
import RecaptchaDisclosure from "../../../molecules/recaptcha/RecaptchaDisclosure.vue";
import FormEnumMultiSelect from "../../../molecules/form/FormEnumMultiSelect.vue";
import AddressFormGroup from "../../../molecules/form/AddressFormGroup.vue";
import { PASSWORD_HELP } from "../../../molecules/form/constants";
import { EmailVerificationStore } from "../../../stores/sign-up/EmailVerificationStore";
import FormCitizenship from "../../../molecules/form/FormCitizenship.vue";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { event } from "vue-gtag";
import { PaymentService } from "../../../utility/payment/PaymentService";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";

@Component({
    components: {
        FormCitizenship,
        AddressFormGroup,
        FormEnumMultiSelect,
        RecaptchaDisclosure,
        FormTel,
        FormCheckbox,
        FormSelect,
        ActionButton,
        FormInput,
    },
})
export default class SignUpView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly signUpStore: SignUpStore = this.container.get(SignUpStore);
    readonly emailVerificationStore: EmailVerificationStore =
        this.container.get(EmailVerificationStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    terms1: boolean = false;
    terms2: boolean = false;
    readonly paymentService: PaymentService = this.container.get(PaymentService);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    get values() {
        return this.signUpStore.data;
    }

    get errors() {
        return this.signUpStore.formErrors;
    }

    get affiliations(): UserAffiliation[] {
        return Object.values(UserAffiliation).sort();
    }

    get PASSWORD_HELP() {
        return PASSWORD_HELP;
    }

    get canSubmitForm(): boolean {
        return this.terms1 && this.terms2 && !!this.isFormValid;
    }

    get UserRoleInOrganization() {
        return UserRoleInOrganization;
    }

    get QuiltyUserRoleInOrganization() {
        return QuiltyUserRoleInOrganization;
    }

    get hasEightCharacters() {
        return this.values && this.values.password && this.values.password.length >= 8;
    }

    get hasOneNumber() {
        return this.values && this.values.password && /\d/.test(this.values.password);
    }

    get hasOneCapital() {
        return this.values && this.values.password && /[A-Z]/.test(this.values.password);
    }

    get hasOneSpecialCharacter() {
        return (
            this.values && this.values.password && /[!@#$%^&*()_+,.?]/.test(this.values.password)
        );
    }

    get isFormValid() {
        return (
            this.values &&
            this.values.password &&
            this.values.confirmPassword &&
            this.hasEightCharacters &&
            this.hasOneNumber &&
            this.hasOneCapital &&
            this.hasOneSpecialCharacter &&
            this.values.password === this.values.confirmPassword
        );
    }

    async signUp(): Promise<void> {
        this.signUpStore.data.emailVerificationCodeNeeded = false;
        this.signUpStore.platformReferer = "quilty";

        await this.signUpStore.submit();

        if (this.signUpStore.failed) {
            this.notificationStore.warning("Could not sign up. Check the sign up form!");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have signed up successfully.");

            const { password, email } = this.signUpStore.data;
            await this.authStore.signin({ password: password || "", email: email || "" });

            const defaultRedirectTo = "/dashboard/quiltyDashboard";

            const redirectTo = this.$route.query.redirectTo
                ? this.$route.query.redirectTo.toString()
                : defaultRedirectTo;

            if (redirectTo === "subscription") {
                try {
                    await this.paymentService.purchasePlan(SubscriptionPlanType.Individual);
                    event("Purchase", {
                        eventCategory: "Purchase",
                        eventAction: OrderType.RequestSubscriptionQuote,
                        eventLabel: OrderType.RequestSubscriptionQuote,
                    });
                } catch (e: any) {
                    window.location.href = defaultRedirectTo;
                    this.notificationStore.warning("Could not initialize a payment process.");
                    handleError(e);
                }
            } else if (redirectTo) {
                window.location.href = redirectTo;
            }

            // if (redirectTo.startsWith("/")) {
            //     this.$router.push({ path: redirectTo, query: this.$route.query });
            // } else if () {

            // } else {

            // }
        }
    }
}
