
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import FormInput from "../../form/FormInput.vue";
import FormSelect from "../../form/FormSelect.vue";
import FormCheckbox from "../../form/FormCheckbox.vue";
import FormTextarea from "../../form/FormTextarea.vue";
import FormTel from "../../form/FormTel.vue";
import OrganizationResourcesPane from "./OrganizationResourcesPane.vue";
import StructureFinancialsPane from "./StructureFinancialsPane.vue";
import PartnershipPane from "./PartnershipPane.vue";
import OrganizationalPreferencesPane from "./OrganizationalPreferencesPane.vue";
import ProductsServicesPane from "./ProductsServicesPane.vue";
import { MergerAcquisition, Nullable, OrganizationProfileBody } from "@gsx/common";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { FormErrors } from "../../../../../common/src/form/types";
import KeyPerformanceIndicatorsPane from "./KeyPerformanceIndicatorsPane.vue";
import { isThirdpartyAdmin } from "@gsx/common";
import { Container } from "inversify";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import TransactionalDataPane from "./TransactionalDataPane.vue";
import AssessmentParametersPane from "./AssessmentParametersPane.vue";

@Component({
    components: {
        OrganizationResourcesPane,
        FormTel,
        FormTextarea,
        FormCheckbox,
        FormSelect,
        ActionButton,
        FormInput,
    },
})
export default class OrganizationProfileForm extends Vue {
    @Prop({ default: false }) loading!: boolean;
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() mergerAcquisitions!: { list: Nullable<MergerAcquisition>[] };
    @Prop() errors!: FormErrors<OrganizationProfileBody>;
    @Prop({ default: "Update Organization" }) submitText!: string;
    @Prop() admin!: boolean;
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);

    readonly tabs = [
        {
            title: "Organization &<br />Resources",
            component: OrganizationResourcesPane,
            onlyAdmin: false,
        },
        {
            title: "Org&nbsp;Structure&nbsp;&<br />Financials",
            component: StructureFinancialsPane,
            onlyAdmin: false,
        },
        {
            title: "Products &<br />Services",
            component: ProductsServicesPane,
            onlyAdmin: false,
        },
        {
            title: "Partnerships &<br />Competitors",
            component: PartnershipPane,
            onlyAdmin: false,
        },
        {
            title: "Organizational<br />Preferences",
            component: OrganizationalPreferencesPane,
            onlyAdmin: true,
        },
        {
            title: "KPI",
            component: KeyPerformanceIndicatorsPane,
            onlyAdmin: false,
        },
        {
            title: "Transactions",
            component: TransactionalDataPane,
            data: this.mergerAcquisitions,
            onlyAdmin: false,
        },
        {
            title: "Assessment Parameters",
            component: AssessmentParametersPane,
            onlyAdmin: false,
        },
    ];

    readonly thirdPartyTabs = [
        {
            title: "KPI",
            component: KeyPerformanceIndicatorsPane,
        },
        {
            title: "Transactions",
            component: TransactionalDataPane,
            data: this.mergerAcquisitions,
        },
    ];

    activeTab: number = 0;

    get progress(): number {
        const all = Object.keys(this.values);
        const filled = Object.values(this.values).filter((value) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }

            return !!value;
        });

        return Math.round((filled.length / all.length) * 100);
    }

    get enrichedTabs(): any[] {
        return this.tabs
            .filter((tab) => tab.onlyAdmin || this.admin)
            .map((tab, index) => ({
                ...tab,
                active: index === this.activeTab,
            }));
    }

    get quiltyEnrichedTabs(): any[] {
        return this.thirdPartyTabs.map((thirdPartyTabs, index) => ({
            ...thirdPartyTabs,
            active: index === this.activeTab,
        }));
    }

    activateTab(index: number) {
        this.activeTab = index;
    }

    get hasQuiltyAdminAccess(): boolean {
        return isThirdpartyAdmin(this.authStore.user);
    }

    @Emit()
    submit() {
        //
    }
}
