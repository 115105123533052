
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import InlineFormInput from "../../form/InlineFormInput.vue";
import InlineFormTextarea from "../../form/InlineFormTextarea.vue";
import InlineFormBoolSelect from "../../form/InlineFormBoolSelect.vue";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";
import { Container } from "inversify";
import TierSelect from "./TierSelect.vue";
import { Customer, Nullable, OrganizationProfileBody, YesNo } from "@gsx/common";
import { FormErrors } from "../../../../../common/src/form/types";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import InlineFormTreeselect from "../../form/InlineFormTreeselect.vue";
import { ProductTypeStore } from "../../../../../common/src/stores/ProductTypeStore";
import { ServiceTypeStore } from "../../../../../common/src/stores/ServiceTypeStore";
import { ExpertiseOptionStore } from "../../../../../common/src/stores/ExpertiseOptionStore";
import { OperatingRegimeTypeStore } from "../../../../../common/src/stores/OperatingRegimeTypeStore";
import { ApplicationTypeStore } from "../../../../../common/src/stores/ApplicationTypeStore";

@Component({
    components: {
        InlineFormTreeselect,
        TierSelect,
        InlineFormSimpleMultiSelect,
        InlineFormBoolSelect,
        InlineFormTextarea,
        InlineFormInput,
    },
})
export default class ProductsServicesPane extends Vue {
    @Inject("container") readonly container!: Container;
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<OrganizationProfileBody>;
    readonly expertiseOptionStore: ExpertiseOptionStore = this.container.get(ExpertiseOptionStore);
    readonly productTypeStore: ProductTypeStore = this.container.get(ProductTypeStore);
    readonly serviceTypeStore: ServiceTypeStore = this.container.get(ServiceTypeStore);
    readonly applicationTypeStore: ApplicationTypeStore = this.container.get(ApplicationTypeStore);
    readonly operatingRegimeTypeStore: OperatingRegimeTypeStore =
        this.container.get(OperatingRegimeTypeStore);

    created() {
        this.expertiseOptionStore.load().catch(handleError);
        this.productTypeStore.load().catch(handleError);
        this.serviceTypeStore.load().catch(handleError);
        this.applicationTypeStore.load().catch(handleError);
        this.operatingRegimeTypeStore.load().catch(handleError);
    }

    get operatingRegimeOptions() {
        return this.operatingRegimeTypeStore.item || [];
    }

    get applicationOptions() {
        return this.applicationTypeStore.item || [];
    }

    get serviceOptions() {
        return this.serviceTypeStore.item || [];
    }

    get productOptions() {
        return this.productTypeStore.item || [];
    }

    get expertiseOptions(): string[] {
        return (this.expertiseOptionStore.item || []).map((option) => option.value);
    }

    get customerTypes(): Customer[] {
        return Object.values(Customer);
    }

    get YesNo() {
        return YesNo;
    }
}
