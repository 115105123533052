
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { SignUpStore } from "../../../stores/sign-up/SignUpStore";
import FormInput from "../../../molecules/form/FormInput.vue";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import FormSelect from "../../../molecules/form/FormSelect.vue";
import { UserAffiliation, UserRoleInOrganization, OrderType } from "@gsx/common";
import FormCheckbox from "../../../molecules/form/FormCheckbox.vue";
import { focusOnFirstInvalidInput } from "../../../../../common/src/form/utils";
import FormTel from "../../../molecules/form/FormTel.vue";
import RecaptchaDisclosure from "../../../molecules/recaptcha/RecaptchaDisclosure.vue";
import FormEnumMultiSelect from "../../../molecules/form/FormEnumMultiSelect.vue";
import AddressFormGroup from "../../../molecules/form/AddressFormGroup.vue";
import { PASSWORD_HELP } from "../../../molecules/form/constants";
import { EmailVerificationStore } from "../../../stores/sign-up/EmailVerificationStore";
import FormCitizenship from "../../../molecules/form/FormCitizenship.vue";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { PaymentService } from "../../../utility/payment/PaymentService";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { event } from "vue-gtag";
import EmailSignupStep from "./signupSteps/EmailSignupStep.vue";
import IndustrySegmentSignupStep from "./signupSteps/IndustrySegmentSignupStep.vue";
import ProfileSignupStep from "./signupSteps/ProfileSignupStep.vue";
import EmailVerificationSignupStep from "./signupSteps/EmailVerificationSignupStep.vue";
import PasswordSignupStep from "./signupSteps/PasswordSignupStep.vue";

@Component({
    components: {
        FormCitizenship,
        AddressFormGroup,
        FormEnumMultiSelect,
        RecaptchaDisclosure,
        FormTel,
        FormCheckbox,
        FormSelect,
        ActionButton,
        FormInput,
        EmailSignupStep,
    },
})
export default class SignUpView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly signUpStore: SignUpStore = this.container.get(SignUpStore);
    readonly emailVerificationStore: EmailVerificationStore =
        this.container.get(EmailVerificationStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    terms: boolean = false;
    readonly paymentService: PaymentService = this.container.get(PaymentService);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    currentStep = 0;

    steps = [
        {
            title: "Maximize Your Impact in Space",
            component: EmailSignupStep,
        },
        {
            title: "An Experience Tailored to You",
            component: IndustrySegmentSignupStep,
        },
        {
            title: "An Environment For Professionals",
            component: ProfileSignupStep,
        },
        {
            title: "Secure Your Account",
            component: PasswordSignupStep,
        },
        {
            title: "Begin Your Journey",
            component: EmailVerificationSignupStep,
        },
    ];

    get values() {
        return this.signUpStore.data;
    }

    get errors() {
        return this.signUpStore.formErrors;
    }

    get affiliations(): UserAffiliation[] {
        return Object.values(UserAffiliation).sort();
    }

    get PASSWORD_HELP() {
        return PASSWORD_HELP;
    }

    get canSubmitForm(): boolean {
        return !!this.values.verificationCode && this.terms;
    }

    get UserRoleInOrganization() {
        return UserRoleInOrganization;
    }

    stepCompletion() {
        if (this.steps.length - 1 === this.currentStep) {
            this.signUp();
        } else {
            this.currentStep++;
        }
    }

    async signUp(): Promise<void> {
        await this.signUpStore.submit();

        if (this.signUpStore.failed) {
            this.notificationStore.warning("Could not sign up. Check the sign up form!");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have signed up successfully.");

            const { password, email } = this.signUpStore.data;
            await this.authStore.signin({ password: password || "", email: email || "" });

            const defaultRedirectTo = "/dashboard/member";

            const redirectTo = this.$route.query.redirectTo
                ? this.$route.query.redirectTo.toString()
                : defaultRedirectTo;

            if (redirectTo === "subscription") {
                try {
                    await this.paymentService.purchasePlan(SubscriptionPlanType.Individual);
                    event("Purchase", {
                        eventCategory: "Purchase",
                        eventAction: OrderType.RequestSubscriptionQuote,
                        eventLabel: OrderType.RequestSubscriptionQuote,
                    });
                } catch (e: any) {
                    window.location.href = defaultRedirectTo;
                    this.notificationStore.warning("Could not initialize a payment process.");
                    handleError(e);
                }
            } else if (redirectTo) {
                window.location.href = redirectTo;
            }

            // if (redirectTo.startsWith("/")) {
            //     this.$router.push({ path: redirectTo, query: this.$route.query });
            // } else if () {

            // } else {

            // }
        }
    }

    async getVerifcationCode(): Promise<void> {
        this.emailVerificationStore.data.email = this.signUpStore.data.email;
        await this.emailVerificationStore.submit();

        if (this.emailVerificationStore.failed) {
            this.notificationStore.warning("Failed to send email with verification code.");
        } else {
            this.notificationStore.success("Verification code has been sent.");
        }
    }
}
