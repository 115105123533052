import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Action, Module, RegisterOptions } from "vuex-class-modules";
import {
    OrganizationLogListItem,
    OrganizationLogListResponse,
} from "@gsx/common/src/types/http/admin/log";

@Module({ generateMutationSetters: true })
export class OrganizationLogListStore extends ListStoreVuex<OrganizationLogListItem> {
    id: string = "";
    query: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    @Action
    public async init(id: string): Promise<void> {
        if (this.id !== id) {
            this.id = id;
            this.reset();
        }
        await this.reload();
    }

    protected async fetch(): Promise<OrganizationLogListResponse> {
        return this.api.admin.organization.listLog(this.id, {
            ...this.queryParams,
            query: this.query,
        });
    }
}
