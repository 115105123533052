import { Nullable, Report } from "@gsx/common";
import get from "lodash.get";
import { ReportBody } from "@gsx/common/src/types/http/admin/report";

export const createReportEmptyBody = (data?: Partial<Report>): Nullable<ReportBody> => ({
    applicationTags: get(data, "applicationTags", null),
    operatingRegimeTags: get(data, "operatingRegimeTags", null),
    distroList: get(data, "distroList", null),
    editedDate: get(data, "editedDate", null),
    highlightBullets: get(data, "highlightBullets", null),
    isRevised: get(data, "isRevised", null),
    isPublished: get(data, "isPublished", null),
    metadata: get(data, "metadata", null),
    organizations: get(data, "organizations", null),
    pdf: get(data, "pdf", null),
    picture: get(data, "picture", null),
    postDate: get(data, "postDate", null),
    price: get(data, "price", "1000 USD"),
    products: get(data, "products", null),
    subTitle: get(data, "subTitle", null),
    summary: get(data, "summary", null),
    services: get(data, "services", null),
    tags: get(data, "tags", null),
    title: get(data, "title", null),
    isFree: get(data, "isFree", null),
});
