
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import FormInput from "../../../../molecules/form/FormInput.vue";
import { EmailVerificationStore } from "../../../../stores/sign-up/EmailVerificationStore";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { Nullable, SignUpBody, UserRoleInOrganization } from "@gsx/common";
import FormEnumMultiSelect from "../../../../molecules/form/FormEnumMultiSelect.vue";
import { PASSWORD_HELP } from "../../../..//molecules/form/constants";
@Component({
    components: {
        ActionButton,
        FormInput,
        FormEnumMultiSelect,
    },
})
export default class PasswordSignupStep extends Vue {
    @Inject("container") readonly container!: Container;
    readonly emailVerificationStore: EmailVerificationStore =
        this.container.get(EmailVerificationStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    terms: boolean = false;
    @Prop() value!: Nullable<SignUpBody>;
    passwordIsVisible: boolean = false;
    confirmPasswordIsVisible: boolean = false;

    get UserRoleInOrganization() {
        return UserRoleInOrganization;
    }

    get PASSWORD_HELP() {
        return PASSWORD_HELP;
    }

    get hasEightCharacters() {
        return this.value && this.value.password && this.value.password.length >= 8;
    }

    get hasOneNumber() {
        return this.value && this.value.password && /\d/.test(this.value.password);
    }

    get hasOneCapital() {
        return this.value && this.value.password && /[A-Z]/.test(this.value.password);
    }

    get hasOneSpecialCharacter() {
        return this.value && this.value.password && /[!@#$%^&*()_+,.?]/.test(this.value.password);
    }

    get isFormValid() {
        return (
            this.value &&
            this.value.password &&
            this.value.confirmPassword &&
            this.hasEightCharacters &&
            this.hasOneNumber &&
            this.hasOneCapital &&
            this.hasOneSpecialCharacter &&
            this.value.password === this.value.confirmPassword
        );
    }

    submit() {
        if (this.isFormValid) {
            this.$emit("submit");
        }
    }
}
