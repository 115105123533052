import { Module, RegisterOptions } from "vuex-class-modules";
import { Api } from "../../../../common/src/api/Api";
import { ApiStoreVuex } from "../../../../common/src/stores/general/ApiStoreVuex";
import { StatisticResponse } from "@gsx/common/src/types/http/guest/statistic";

@Module()
export class StatisticsStore extends ApiStoreVuex<StatisticResponse> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch() {
        return this.api.guest.statistics.get();
    }
}
