
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { FlashNewsEditStore } from "../../../stores/admin/flashNews/FlashNewsEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import FlashNewsForm from "../../../molecules/flashNews/FlashNewsForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import { FlashNewsListStore } from "../../../stores/admin/flashNews/FlashNewsListStore";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
@Component({
    components: {
        FlashNewsForm,
        ActionButton,
    },
})
export default class FlashNewsGeneralView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: FlashNewsEditStore = this.container.get(FlashNewsEditStore);
    api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: FlashNewsListStore = this.container.get(FlashNewsListStore);
    media?: { picture?: File } = {};
    created() {
        this.editStore.setId(this.flashNewsId);
        this.editStore.load().catch(handleError);
    }
    get flashNewsId(): string {
        return this.$route.params.flashNewsId;
    }
    async updateFlashNews() {
        this.editStore.flashNewsMedia.set("picture", this.media?.picture!);
        await this.editStore.update();
        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Flash news update failed.");
        } else {
            this.notificationStore.success("You have updated flash news successfully.");
        }
    }

    async destroyFlashNews() {
        try {
            var confirmation = confirm("Are you sure you want to delete this flash news?");
            if (confirmation) {
                await this.api.admin.flashNews.delete(this.flashNewsId);
                this.notificationStore.success("Flash news item has been deleted.");
                this.listStore.reload();
                this.$router.push(`/admin/flashNews`);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting flash news item.");
        }
    }
}
