
import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import InputField from "./InputField.vue";
import Multiselect from "vue-multiselect";
import { SelectOption } from "./types";
import { convertToSelectOptions } from "./utils";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";

@Component({
    components: { FormFeedback, FormLabel, InputField, Multiselect },
    inheritAttrs: false,
})
export default class InlineFormMultiSelect extends Vue {
    @Prop() label!: string;
    @Prop([String, Object, Array]) value?: SelectOption | SelectOption[];
    @Prop({ type: Array, default: () => [] }) options!: SelectOption[];
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;
    @Prop() placeholder?: string;
    @Prop({ default: "text-muted" }) labelClass?: string;
    @Ref("select") readonly select!: Multiselect;
    focused: boolean = false;

    get formattedValue(): SelectOption[] {
        return convertToSelectOptions(this.value);
    }

    get formattedOptions(): SelectOption[] {
        const options = convertToSelectOptions(this.options);
        const map = new Map(
            [...this.formattedValue, ...options].map((option) => [option.id, option]),
        );
        return [...map.values()];
    }

    get displayText(): string {
        if (Array.isArray(this.formattedValue)) {
            return this.formattedValue.map((item) => item.name).join(", ");
        }

        return "";
    }

    get listeners() {
        return {
            ...this.$listeners,
            input: this.input,
            close: this.unfocus,
            tag: this.addItem,
        };
    }

    @Emit()
    input(value: SelectOption | SelectOption[]) {
        return value;
    }

    focus() {
        this.focused = true;
        // @ts-ignore
        this.$nextTick(() => this.select.activate());
    }

    unfocus() {
        this.focused = false;
    }

    addItem(text: string) {
        const item: SelectOption = {
            id: text,
            name: text,
            new: true,
        };

        if (this.multiple) {
            this.input([...this.formattedValue, item]);
        } else {
            this.input(item);
        }
    }
}
