
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import { UserActivityLogsListStore } from "../../../stores/admin/user/UserActivityLogsListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { debounce } from "@fullcalendar/core";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { Action } from "@gsx/common";
import { UserActivityLogListItem } from "@gsx/common/src/types/http/admin/userActivityLog";
import { enrichedListColumns } from "../../../../../dashboard/src/utils/tableUtils";

interface Column {
    id: keyof (UserActivityLogListItem & {
        firstName: string;
    });
    name: string;
}

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords },
})
export default class UserActivityLogsView extends Vue {
    @Inject("container") readonly container!: Container;
    // CollectionStore
    readonly userActivityLogList: UserActivityLogsListStore =
        this.container.get(UserActivityLogsListStore);
    readonly api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    readonly userActivityColumns: ReadonlyArray<Column> = [
        {
            id: "firstName",
            name: "Name",
        },
        {
            id: "action",
            name: "Action",
        },
        {
            id: "createdAt",
            name: "Date",
        },
    ];

    Action = Action;
    opened: string[] = [];
    @Watch("userActivityLogList.query")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.userActivityLogList.load().catch(handleError);
    }

    reload() {
        this.userActivityLogList.page = 1;
        this.userActivityLogList.reload().catch(handleError);
    }

    get enrichedUserActivityColumns() {
        return enrichedListColumns(this.userActivityLogList, this.userActivityColumns);
    }
}
