import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { EditStore } from "../../general/EditStore";
import { Api } from "../../../../../common/src/api/Api";
import { ReportBody } from "@gsx/common/src/types/http/admin/report";
import { createReportEmptyBody } from "../../../../src/utility/entity/report";

// EditStoreVuex is not used here, because components are modifying
// data properties directly. That behaviour is not supported by vuex.
// The whole data would have to be replaced instead.

@injectable()
export class ReportEditStore extends EditStore<ReportBody> {
    private id: string = "";
    readonly data: Nullable<ReportBody> = createReportEmptyBody();
    reportMedia: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    public setId(value: string): void {
        this.id = value;
    }

    protected async loadRequest(): Promise<ReportBody> {
        return this.api.admin.reports.get(this.id);
    }

    protected async updateRequest(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.reports.update(this.id, body, this.reportMedia);
    }
}
