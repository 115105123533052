import { marshal, Nullable } from "@gsx/common";
import { SubmitStore } from "../../../../common/src/stores/general/SubmitStore";
import { injectable } from "inversify";
import { Api } from "../../../../common/src/api/Api";
import { PasswordResetBody } from "@gsx/common/src/types/http/guest/passwordReset";

@injectable()
export class PasswordResetStore extends SubmitStore<PasswordResetBody> {
    platformReferer: string = "gse";

    readonly data: Nullable<PasswordResetBody> = {
        email: null,
    };

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.guest.resetPassword(body, this.platformReferer);
    }
}
