
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { FeedbackListItem } from "@gsx/common/src/types/http/admin/feedback";
import { FeedbackListStore } from "../../../stores/admin/feedback/FeedbackListStore";
import Ticket from "../../../molecules/components/Ticket.vue";
import { FeedbackStatus } from "@gsx/common";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";

// TODO Pagination buttons should be disabled if no more records

@Component({
    components: { MoreActions, Ticket, LoadingOverlay },
})
export default class FeedbackListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: FeedbackListStore = this.container.get(FeedbackListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    created() {
        this.listStore.load().catch(handleError);
    }

    get FeedbackStatus() {
        return FeedbackStatus;
    }

    statusClass(feedback: FeedbackListItem) {
        return {
            "badge-accent": feedback.status === FeedbackStatus.New,
            "badge-info": feedback.status === FeedbackStatus.Planned,
            "badge-success": feedback.status === FeedbackStatus.Incorporated,
            "badge-danger": feedback.status === FeedbackStatus.Rejected,
        };
    }

    async changeStatus(feedback: FeedbackListItem, status: FeedbackStatus) {
        try {
            await this.api.admin.feedback.changeStatus(feedback.feedbackid, { status });
            feedback.status = status;
            this.notificationStore.success("Feedback status has been changed.");
        } catch (e) {
            this.notificationStore.warning("Feedback status hasn't been changed.");
        }
    }
}
