import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";
import { UserRepresentation } from "@gsx/common";
import { UserRepresentationListResponse } from "@gsx/common/src/types/http/admin/user";

@Module({ generateMutationSetters: true })
export class UserRepresentationListStore extends ListStoreVuex<UserRepresentation> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch(): Promise<UserRepresentationListResponse> {
        return this.api.admin.user.list();
    }
}
