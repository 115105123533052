
import { Component, Inject, Vue } from "vue-property-decorator";
import FormCheckbox from "../../../../molecules/form/FormCheckbox.vue";
import FormDate from "../../../../molecules/form/FormDate.vue";
import FormTextarea from "../../../../molecules/form/FormTextarea.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import { Container } from "inversify";
import { CheckListStore } from "../../../../stores/admin/organization/CheckListStore";
import LoadingOverlay from "../../../../../../common/src/components/LoadingOverlay.vue";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { toLocaleDateTimeString, UserRepresentation } from "@gsx/common";
import FormMultiSelect from "../../../../molecules/form/FormMultiSelect.vue";
import { UserRepresentationListStore } from "../../../../stores/admin/user/UserRepresentationListStore";
import { SelectOption } from "../../../../molecules/form/types";

@Component({
    components: {
        FormMultiSelect,
        LoadingOverlay,
        ActionButton,
        FormTextarea,
        FormCheckbox,
        FormDate,
    },
})
export default class CheckListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly checkListStore: CheckListStore = this.container.get(CheckListStore);
    readonly userListStore: UserRepresentationListStore = this.container.get(
        UserRepresentationListStore,
    );
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    showLinks: boolean = false;

    get organizationId(): string {
        return this.$route.params.organization;
    }

    get updatedAt(): string {
        return toLocaleDateTimeString(this.checkListStore.data.updatedAt) || "n/a";
    }

    get users(): SelectOption[] {
        return [...this.usersDictionary.values()].map(this.formatUserOption);
    }

    get usersDictionary(): ReadonlyMap<string, UserRepresentation> {
        return new Map((this.userListStore.items ?? []).map((user) => [user.userid, user]));
    }

    get contactCompanyUser(): SelectOption | null {
        return this.findUserOption(this.checkListStore.data.contactCompanyUserId);
    }

    get publicRecordsUser(): SelectOption | null {
        return this.findUserOption(this.checkListStore.data.publicRecordsUserId);
    }

    get openSourceUser(): SelectOption | null {
        return this.findUserOption(this.checkListStore.data.regulatoryRecordsUserId);
    }

    get socialMediaUser(): SelectOption | null {
        return this.findUserOption(this.checkListStore.data.socialMediaUserId);
    }

    get websiteUser(): SelectOption | null {
        return this.findUserOption(this.checkListStore.data.websiteUserId);
    }

    created() {
        this.checkListStore.init(this.organizationId).catch(handleError);
        this.userListStore.load().catch(handleError);
    }

    changeContactCompanyDate(checked: boolean) {
        this.checkListStore.data.contactCompanyDate = checked ? new Date() : null;
    }

    changeContactCompanyUser(option: SelectOption | null): void {
        this.checkListStore.data.contactCompanyUserId = option?.id ?? null;
    }

    changeOpenSourceUser(option: SelectOption | null): void {
        this.checkListStore.data.regulatoryRecordsUserId = option?.id ?? null;
    }

    changeOpenSourceDate(checked: boolean) {
        this.checkListStore.data.regulatoryRecordsDate = checked ? new Date() : null;
    }

    changePublicRecordsDate(checked: boolean) {
        this.checkListStore.data.publicRecordsDate = checked ? new Date() : null;
    }

    changePublicRecordsUser(option: SelectOption | null): void {
        this.checkListStore.data.publicRecordsUserId = option?.id ?? null;
    }

    publish(checked: boolean) {
        this.checkListStore.data.published = checked ? true : null;
    }

    changeWebsiteDate(checked: boolean) {
        this.checkListStore.data.websiteDate = checked ? new Date() : null;
    }

    changeWebsiteUser(option: SelectOption | null): void {
        this.checkListStore.data.websiteUserId = option?.id ?? null;
    }

    changeSocialMediaDate(checked: boolean) {
        this.checkListStore.data.socialMediaDate = checked ? new Date() : null;
    }

    changeSocialMediaUser(option: SelectOption | null): void {
        this.checkListStore.data.socialMediaUserId = option?.id ?? null;
    }

    async updateCheckList() {
        await this.checkListStore.update();

        if (this.checkListStore.hasUpdatingFailed) {
            this.notificationStore.warning("Check list update failed.");
        } else {
            this.checkListStore.data.updatedAt = new Date();
            this.notificationStore.success("You have updated check list successfully.");
        }
    }

    findUserOption(userId: string | null): SelectOption | null {
        if (userId) {
            const user = this.usersDictionary.get(userId);
            if (user) {
                return this.formatUserOption(user);
            }
        }

        return null;
    }

    formatUserOption(user: UserRepresentation): SelectOption {
        return {
            id: user.userid,
            name: `${user.firstName} ${user.lastName}`,
        };
    }
}
