import { RouteConfig } from "vue-router";
import SearchView from "../../views/SearchView.vue";
import RequestFormView from "../../views/RequestFormView.vue";
import PasswordResetView from "../../views/PasswordResetView.vue";
import ChangePasswordView from "../../views/ChangePasswordView.vue";
import PrivacyPolicyView from "../../views/PrivacyPolicyView.vue";
import TermsConditionsView from "../../views/TermsConditionsView.vue";
import CookiePolicyView from "../../views/CookiePolicyView.vue";
import PaymentCanceledView from "../../views/payment/PaymentCanceledView.vue";
import ConferenceView from "../../views/ConferenceView.vue";
import CurrentConferenceView from "../../views/CurrentConferenceView.vue";

export const createBaseGuestRoutes = (): RouteConfig[] => [
    { path: "/search/:search?", component: SearchView },
    { path: "/request", component: RequestFormView },
    // { path: "/about", component: AboutUsView },
    // { path: "/insights", component: InsightsView },
    // { path: "/services", component: ServicesView },
    // { path: "/contact", component: ContactUsView },
    { path: "/password-reset", component: PasswordResetView },
    { path: "/password-reset/:token", component: ChangePasswordView },
    { path: "/cookiepolicy", component: CookiePolicyView },
    { path: "/conference", component: CurrentConferenceView },
    { path: "/privacy", component: PrivacyPolicyView },
    { path: "/terms", component: TermsConditionsView },
    { path: "/payment/canceled", component: PaymentCanceledView },
    { path: "/terms", component: TermsConditionsView },
    { path: "/SmallSat-2020", component: ConferenceView },
];
