import {
    QuiltyInsightsListItem,
    QuiltyInsightsListResponse,
} from "@gsx/common/src/types/http/admin/quiltyInsights";
import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module({ generateMutationSetters: true })
export class QuiltyInsightsListStore extends ListStoreVuex<QuiltyInsightsListItem> {
    query: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
        this.pageSize = 5;
    }

    protected async fetch(): Promise<QuiltyInsightsListResponse> {
        return this.api.admin.quiltyInsights.list({
            ...this.queryParams,
            query: this.query,
        });
    }
}
