
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { ReportEditStore } from "../../../stores/admin/report/ReportEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import ReportForm from "../../../molecules/report/ReportForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import { ReportListStore } from "../../../stores/admin/report/ReportListStore";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";

@Component({
    components: {
        ReportForm,
        ActionButton,
    },
})
export default class ReportGeneralView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: ReportEditStore = this.container.get(ReportEditStore);
    api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: ReportListStore = this.container.get(ReportListStore);

    media?: { pdf?: File; picture?: File } = {};

    created() {
        this.editStore.setId(this.reportId);
        this.editStore.load().catch(handleError);
    }

    get reportId(): string {
        return this.$route.params.reportId;
    }

    async updateReport() {
        this.editStore.reportMedia.set("pdf", this.media?.pdf!);
        this.editStore.reportMedia.set("picture", this.media?.picture!);

        await this.editStore.update();

        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Report update failed.");
        } else {
            this.notificationStore.success("You have updated report successfully.");
        }
    }

    async destroyReport() {
        try {
            var confirmation = confirm("Are you sure you want to delete report");
            if (confirmation) {
                await this.api.admin.reports.delete(this.reportId);
                this.notificationStore.success("Report has been deleted.");
                this.listStore.reload();
                this.$router.push(`/admin/reports`);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting report.");
        }
    }

    async sendTestEmail() {
        try {
            await this.api.admin.reports.sendEmail({
                ...this.editStore.data,
                distroList: ["report test"],
            });
            this.notificationStore.success("Test email sent");
        } catch (err) {
            this.notificationStore.danger("Failed to send test email.");
        }
    }

    async sendEmail() {
        if (this.editStore.data.distroList?.length) {
            try {
                await this.api.admin.reports.sendEmail(this.editStore.data);
                this.notificationStore.success("Email sent");
            } catch (err) {
                this.notificationStore.danger("Failed to send email.");
            }
        } else {
            this.notificationStore.warning("No distribution list for report");
        }
    }
}
