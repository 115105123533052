import { boundMethod } from "autobind-decorator";
import { Api } from "../../../../../../common/src/api/Api";
import {
    PastProjectListItem,
    PastProjectListResponse,
} from "@gsx/common/src/types/http/user/organization/pastProject";
import { Module, RegisterOptions } from "vuex-class-modules";
import ListStoreVuex from "../../../../../../common/src/stores/general/ListStoreVuex";

@Module()
export class PastProjectListStore extends ListStoreVuex<PastProjectListItem> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    @boundMethod
    protected async fetch(): Promise<PastProjectListResponse> {
        return this.api.user.pastProject.list();
    }
}
