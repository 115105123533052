
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { OrganizationListStore } from "../../../../stores/admin/organization/OrganizationListStore";
import { handleError } from "../../../../../../common/src/utility/errorUtils";
import { OrganizationListItem } from "@gsx/common/src/types/http/admin/organization";

@Component({
    components: {},
})
export default class OrganizationInsightComponent extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: OrganizationListStore = this.container.get(OrganizationListStore);

    created() {
        this.listStore.load().catch(handleError);
    }

    get productsAndServices() {
        let ps: { [key: string]: number } = {};

        this.listStore.items?.forEach((org) => {
            [...(org.serviceTags || []), ...(org.productTags || [])].forEach(
                (productService: string) => {
                    if (!ps[productService]) {
                        ps[productService] = 0;
                    }

                    ps[productService]++;
                },
            );
        });

        // Convert the object to an array of key-value pairs
        const keyValueArray = Object.entries(ps);

        // Sort the array based on the numeric values
        keyValueArray.sort((a, b) => b[1] - a[1]);

        // Reconstruct the sorted object
        const sortedObj = Object.fromEntries(keyValueArray);

        return sortedObj;
    }

    getOrganizationUrl(organization: OrganizationListItem) {
        return `/admin/organizations/${organization.organizationid}/profile`;
    }
}
