import OrganizationProfileView from "../views/member/organization/profile/OrganizationProfileView.vue";
import OrganizationPastProjectsCreateTab from "../views/member/organization/past-project/PastProjectCreateView.vue";
import OrganizationPastProjectEditTab from "../views/member/organization/past-project/PastProjectEditView.vue";
import PastProjectListView from "../views/member/organization/past-project/PastProjectListView.vue";
import RequestsView from "../views/member/requests/RequestsView.vue";
import SecurityView from "../views/member/security/SecurityView.vue";
import RequestAffiliationView from "../views/member/request-affiliation/RequestAffiliationView.vue";
import { Container } from "inversify";
import MemberView from "../views/member/MemberView.vue";
import ProfileView from "../views/member/profile/ProfileView.vue";
import SubscriptionView from "../views/member/subscription/SubscriptionView.vue";
import { AuthGate } from "../../../common/src/AuthGate";
import { RouteConfig } from "vue-router";

export const createMemberRoutes = (container: Container): RouteConfig[] => {
    const authGate: AuthGate = container.get(AuthGate);

    return [
        {
            path: "/member",
            component: MemberView,
            redirect: "/member/profile",
            beforeEnter: authGate.requireAuthorizedGuard(),
            meta: {
                breadcrumbs: [
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Profile",
                        to: "/member",
                    },
                ],
            },
            children: [
                {
                    path: "organization/profile",
                    component: OrganizationProfileView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Organization",
                            },
                        ],
                        title: "Organization Profile",
                    },
                },
                {
                    path: "organization/past-projects/create",
                    component: OrganizationPastProjectsCreateTab,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Organization",
                            },
                            {
                                name: "Past Projects",
                                to: "/member/organization/past-projects",
                            },
                        ],
                        title: "Create",
                    },
                },
                {
                    path: "organization/past-projects/:pastProject",
                    component: OrganizationPastProjectEditTab,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Organization",
                            },
                            {
                                name: "Past Projects",
                                to: "/member/organization/past-projects",
                            },
                        ],
                        title: "Edit",
                    },
                },
                {
                    path: "organization/past-projects",
                    component: PastProjectListView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Organization",
                            },
                        ],
                        title: "Past Projects",
                    },
                },
                {
                    path: "profile",
                    component: ProfileView,
                    meta: {
                        title: "Personal Profile",
                    },
                },
                {
                    path: "subscription",
                    component: SubscriptionView,
                    meta: {
                        title: "Purchases",
                    },
                },
                {
                    path: "requests",
                    component: RequestsView,
                    meta: {
                        title: "My Requests",
                    },
                },
                {
                    path: "security",
                    component: SecurityView,
                    meta: {
                        title: "Security",
                    },
                },
                {
                    path: "request-affiliation",
                    component: RequestAffiliationView,
                    meta: {
                        title: "Apply For Affiliation",
                    },
                },
            ],
        },
    ];
};
