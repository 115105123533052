import { marshal, Nullable } from "@gsx/common";
import { SubmitStore } from "../../../../common/src/stores/general/SubmitStore";
import { injectable } from "inversify";
import { Api } from "../../../../common/src/api/Api";
import { ChangePasswordBody } from "@gsx/common/src/types/http/guest/passwordReset";

@injectable()
export class ChangePasswordStore extends SubmitStore<ChangePasswordBody> {
    private token: string = "";

    readonly data: Nullable<ChangePasswordBody> = {
        password: null,
        confirmPassword: null,
    };

    constructor(private readonly api: Api) {
        super();
    }

    public setToken(token: string): void {
        this.token = token;
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.guest.changePassword(this.token, body);
    }
}
