import { Nullable, FlashNews } from "@gsx/common";
import get from "lodash.get";
import { FlashNewsBody } from "@gsx/common/src/types/http/admin/flashNews";

export const createFlashNewsEmptyBody = (data?: Partial<FlashNews>): Nullable<FlashNewsBody> => ({
    applicationTags: get(data, "applicationTags", null),
    operatingRegimeTags: get(data, "operatingRegimeTags", null),
    picture: get(data, "picture", null),
    title: get(data, "title", null),
    author: get(data, "author", null),
    postDate: get(data, "postDate", null),
    narrative: get(data, "narrative", null),
    highlightBullets: get(data, "highlightBullets", null),
    tags: get(data, "tags", null),
    organizationNames: get(data, "organizationNames", null),
    products: get(data, "products", null),
    services: get(data, "services", null),
    url: get(data, "url", null),
    likes: get(data, "likes", null),
    // isRevised: get(data, "isRevised", null),
});
