
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { capitalizeWords, Tier } from "@gsx/common";
import { SelectOption } from "../../form/types";
import InlineFormMultiSelect from "../../form/InlineFormMultiSelect.vue";

const tierDenitions = {
    [Tier.System]: "Ex. Satellite",
    [Tier.SubSystem]: "Ex. Power System, Communication",
    [Tier.Unit]: "Ex. Transmitter",
    [Tier.Component]: "Ex. Antenna, Waveguide",
};

const createItem = (tier: Tier) => ({
    id: tier,
    name: capitalizeWords(tier),
    definition: tierDenitions[tier],
});

@Component({
    components: { InlineFormMultiSelect },
})
export default class TierSelect extends Vue {
    @Prop() value!: Tier[];

    get options() {
        return Object.values(Tier).map(createItem);
    }

    get formattedValue() {
        return this.value ? this.value.map(createItem) : [];
    }

    @Emit()
    input(item: SelectOption[]): string[] {
        return item.map((item) => item.id);
    }
}
