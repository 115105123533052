
import { Component, Vue } from "vue-property-decorator";
import InlineFormSelect from "./InlineFormSelect.vue";
import { YesNo } from "@gsx/common";

@Component({
    components: { InlineFormSelect },
})
export default class InlineFormBoolSelect extends Vue {
    get yesNo(): YesNo[] {
        return Object.values(YesNo);
    }
}
