
import { Component, Inject, Vue } from "vue-property-decorator";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import SubmitFeedbackModal from "../../../../common/src/feedback/SubmitFeedbackModal.vue";
import { FeedbackModalStore } from "../../../../common/src/feedback/FeedbackModalStore";
import { Container } from "inversify";
import Sidebar from "./Sidebar.vue";
import { IndividualSubscriptionRedirectModalStore } from "../../../../common/src/subscription-quote/IndividualSubscriptionRedirectModalStore";
import IndividualSubscriptionRedirectModal from "../../../../common/src/subscription-quote/IndividualSubscriptionRedirectModal.vue";
import CredentialFooter from "./CredentialFooter.vue";

@Component({
    components: {
        Sidebar,
        SubmitFeedbackModal,
        Footer,
        Header,
        IndividualSubscriptionRedirectModal,
        CredentialFooter,
    },
})
export default class Layout extends Vue {
    @Inject("container") readonly container!: Container;
    readonly feedbackModalStore: FeedbackModalStore = this.container.get(FeedbackModalStore);
    readonly individualSubscriptionRedirectModalStore: IndividualSubscriptionRedirectModalStore =
        this.container.get(IndividualSubscriptionRedirectModalStore);

    get isLandingPage() {
        return (
            this.$route.fullPath === "/signin" ||
            this.$route.fullPath === "/signup" ||
            this.$route.path.startsWith("/password-reset")
        );
    }
}
