import "../styles/main.scss";

import "core-js";
import "reflect-metadata";

import Vue from "vue";
import "cookieconsent";

import App from "./App.vue";
import { createContainer } from "./inversify.config";
import { createRouter } from "./router.config";
import { setup } from "./setup";
import { createStore } from "./vuex.config";
import { setupCookiePolicy, setupMoment, setupSentry } from "../../common/src/utility/setup";
import { ignore, isProduction } from "../../common/src/utility/utils";
import { AuthStore } from "../../common/src/stores/AuthStore";
import { AuthTokenGuard } from "../../common/src/AuthTokenGuard";
// import VueGtag from "vue-gtag";

Vue.config.productionTip = !isProduction();

setupSentry();
setupMoment();
setup();

const store = createStore();
const container = createContainer(store);
const router = createRouter(container);

// Vue.use(
//     VueGtag,
//     {
//         config: { id: "G-XLKK28P8ZG" },
//         debug: true,
//     },
//     // router,
// );

const authStore: AuthStore = container.get(AuthStore);
const authTokenGuard: AuthTokenGuard = container.get(AuthTokenGuard);

authStore
    .me()
    .then(() => {
        if (authStore.user && !authStore.user.cookiesAccepted) {
            setupCookiePolicy();
        }
    })
    .catch(ignore);
authTokenGuard.keepRefreshing();

new Vue({
    router,
    store,
    render: (h) => h(App),
    provide() {
        return { container };
    },
}).$mount("#app");
