
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import Ticket from "../../../molecules/components/Ticket.vue";
import { RequestListStore } from "../../../stores/admin/request/RequestListStore";
import { RequestListItem } from "@gsx/common/src/types/http/admin/request";
import { RequestStatus } from "@gsx/common";
import { RequestType } from "@gsx/common";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";

@Component({
    components: { MoreActions, Ticket, LoadingOverlay },
})
export default class RequestListView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: RequestListStore = this.container.get(RequestListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    created() {
        this.listStore.load().catch(handleError);
    }

    get RequestStatus() {
        return RequestStatus;
    }

    get RequestType() {
        return RequestType;
    }

    isSubscriptionRequestType(request: RequestListItem) {
        return request.type === RequestType.ThirdParty;
    }

    statusClass(request: RequestListItem) {
        return {
            "badge-accent":
                request.status === RequestStatus.Open ||
                request.status === RequestStatus.Accepted ||
                request.status === RequestStatus.PaymentLinkSent,
            "badge-danger":
                request.status === RequestStatus.Closed || request.status === RequestStatus.Denied,
            "badge-success": request.status === RequestStatus.Processed,
        };
    }

    async changeStatus(request: RequestListItem, status: RequestStatus) {
        try {
            await this.api.admin.request.changeStatus(request.requestid, { status });
            request.status = status;
            this.notificationStore.success("Request status has been changed.");
        } catch (e) {
            this.notificationStore.warning("Request status hasn't been changed.");
        }
    }
}
