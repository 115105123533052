
import { Component, Inject, Vue } from "vue-property-decorator";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { Container } from "inversify";
import { FORBIDDEN, UNAUTHORIZED, UNPROCESSABLE_ENTITY } from "http-status-codes";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { isAxiosError } from "../../../../../common/src/utility/http";

@Component({
    components: { ActionButton },
})
export default class SignInBox extends Vue {
    @Inject("container") readonly container!: Container;
    authStore: AuthStore = this.container.get(AuthStore);
    notificationStore: NotificationStore = this.container.get(NotificationStore);
    loading: boolean = false;
    error: Error | null | unknown = null;
    email: string = "";
    password: string = "";

    get emailError(): string | null {
        if (isAxiosError(this.error) && this.error.response) {
            const { data, status } = this.error.response;
            if (status === UNPROCESSABLE_ENTITY) {
                return data.errors.email || null;
            }
        }

        return null;
    }

    get passwordError(): string | null {
        if (isAxiosError(this.error) && this.error.response) {
            const { data, status } = this.error.response;

            if (status === UNAUTHORIZED) {
                return "Please enter valid credentials!";
            }

            if (status === FORBIDDEN) {
                return "Your account is blocked!";
            }

            if (status === UNPROCESSABLE_ENTITY) {
                return data.errors.password || null;
            }
        }

        return this.error ? "Error occured" : null;
    }

    async signin(): Promise<void> {
        this.loading = true;

        try {
            await this.authStore.signin({
                email: this.email,
                password: this.password,
            });

            window.location.href = "/dashboard";

            this.error = null;
            this.email = "";
            this.notificationStore.success("You have signed in successfully.");
        } catch (e) {
            this.error = e;
            this.notificationStore.warning("Could not sign in. Check the sign in form!");
        }

        this.password = "";
        this.loading = false;
    }
}
