
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import InlineFormInput from "../../form/InlineFormInput.vue";
import InlineFormSimpleMultiSelect from "../../form/InlineFormSimpleMultiSelect.vue";
import YearlyFinancialTable from "./key-performance-indicators/YearlyFinancialTable.vue";
import {
    OrganizationProfileBody,
    Nullable,
    KeyPerformanceIndicators,
    QuintileSizeOptions,
    OwnershipType,
    CustomerFocus,
    SpaceFocus,
    PublicPrivate,
    Currencies,
    Months,
} from "@gsx/common";
import { FormErrors } from "../../../../../common/src/form/types";
import InlineFormCurrency from "../../form/InlineFormCurrency.vue";
import FormDate from "../../form/InlineFormDate.vue";

@Component({
    components: {
        InlineFormCurrency,
        FormDate,
        InlineFormInput,
        InlineFormSimpleMultiSelect,
        YearlyFinancialTable,
    },
})
export default class KeyPerformanceIndicatorsPane extends Vue {
    @Prop() values!: Nullable<OrganizationProfileBody>;
    @Prop() errors!: FormErrors<KeyPerformanceIndicators>;

    @Inject("container") readonly container!: Container;

    get currencies(): string[] {
        return Object.values(Currencies);
    }

    get customerFocus(): string[] {
        return Object.values(CustomerFocus);
    }

    get months(): string[] {
        return Object.values(Months);
    }

    get ownership(): string[] {
        return Object.values(OwnershipType);
    }

    get publicPrivate(): string[] {
        return Object.values(PublicPrivate);
    }

    get quintileSizeOptions(): string[] {
        return Object.values(QuintileSizeOptions);
    }

    get spaceFocus(): string[] {
        return Object.values(SpaceFocus);
    }

    get kpi() {
        if (!this.values.keyPerformanceIndicators) {
            this.values.keyPerformanceIndicators = {} as KeyPerformanceIndicators;
        }
        return this.values.keyPerformanceIndicators;
    }
}
