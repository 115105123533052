import { Nullable, PastProject, PastProjectItem } from "@gsx/common";
import get from "lodash.get";

export const createPastProjectEmptyBody = (
    pastProject?: PastProject,
): Nullable<PastProjectItem> => ({
    customer: get(pastProject, "customer", null),
    dateCompleted: get(pastProject, "dateCompleted", null),
    dateConducted: get(pastProject, "dateConducted", null),
    description: get(pastProject, "description", ""),
    productList: get(pastProject, "productList", null),
    projectType: get(pastProject, "projectType", null),
    serviceTags: get(pastProject, "serviceTags", null),
    title: get(pastProject, "title", ""),
});
