
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Container } from "inversify";
import MoreActions from "../../../molecules/components/MoreActions.vue";
import { Api } from "../../../../../common/src/api/Api";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import LoadingOverlay from "../../../../../common/src/components/LoadingOverlay.vue";
import { ReportListStore } from "../../../stores/admin/report/ReportListStore";
import { ReportCollectionStore } from "../../../stores/admin/report/ReportCollectionStore";
import { ReportListItem } from "@gsx/common/src/types/http/admin/report";
import NoRecords from "../../../../../common/src/components/NoRecords.vue";
import { debounce } from "debounce";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import ReportTable from "../../../../../dashboard/src/molecules/reports/ReportTable.vue";

@Component({
    components: { MoreActions, LoadingOverlay, NoRecords, ReportTable },
})
export default class ReportsView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly listStore: ReportListStore = this.container.get(ReportListStore);
    readonly collectionStore: ReportCollectionStore = this.container.get(ReportCollectionStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly api: Api = this.container.get(Api);

    @Watch("listStore.query")
    reloadDebounce = debounce(this.reload, 300);

    created() {
        this.listStore.load().catch(handleError);
    }

    reload() {
        this.listStore.reload().catch(handleError);
    }

    editReport(report: ReportListItem) {
        this.$router.push(`/admin/reports/${report.reportId}`);
    }
}
