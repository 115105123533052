
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { QuiltyInsightsEditStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import QuiltyInsightsForm from "../../../molecules/quiltyInsights/QuiltyInsightsForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import { QuiltyInsightsListStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsListStore";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
@Component({
    components: {
        QuiltyInsightsForm,
        ActionButton,
    },
})
export default class QuiltyInsightsGeneralView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: QuiltyInsightsEditStore = this.container.get(QuiltyInsightsEditStore);
    api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: QuiltyInsightsListStore = this.container.get(QuiltyInsightsListStore);
    media?: { picture?: File } = {};
    created() {
        this.editStore.setId(this.quiltyInsightsId);
        this.editStore.load().catch(handleError);
    }
    get quiltyInsightsId(): string {
        return this.$route.params.quiltyInsightsId;
    }
    async updateQuiltyInsights() {
        this.editStore.quiltyInsightsMedia.set("picture", this.media?.picture!);
        await this.editStore.update();
        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Flash news update failed.");
        } else {
            this.notificationStore.success("You have updated flash news successfully.");
        }
    }

    async destroyQuiltyInsights() {
        try {
            var confirmation = confirm("Are you sure you want to delete this quilty insights?");
            if (confirmation) {
                await this.api.admin.quiltyInsights.delete(this.quiltyInsightsId);
                this.notificationStore.success("Quilty insights has been deleted.");
                this.listStore.reload();
                this.$router.push(`/admin/quiltyInsights`);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting quilty insights.");
        }
    }
}
