import { Api } from "../../../../common/src/api/Api";
import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";
import { SearchResults } from "@gsx/common/src/types/http/guest/search";

const PAGE_SIZE = 5;

@injectable()
export class SearchStore {
    results: SearchResults | null = null;
    count: number = 0;
    isLoading: boolean = false;
    error: Error | null = null;

    constructor(private readonly api: Api) {
        //
    }

    get failed(): boolean {
        return this.error !== null;
    }

    get isLoaded(): boolean {
        return this.results !== null;
    }

    get pageCount(): number {
        return Math.max(1, Math.ceil(this.count / PAGE_SIZE));
    }

    @boundMethod
    async load(query: string): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        try {
            const response = await this.api.guest.search({ query });
            this.results = response.data;
            this.count = response.count;
            this.error = null;
        } catch (e: any) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }
}
