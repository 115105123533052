
import { Component, Inject, Vue } from "vue-property-decorator";
import FormInput from "../../../molecules/form/FormInput.vue";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { Container } from "inversify";
import FormSelect from "../../../molecules/form/FormSelect.vue";
import FormCheckbox from "../../../molecules/form/FormCheckbox.vue";
import FormTextarea from "../../../molecules/form/FormTextarea.vue";
import { UserAffiliation } from "@gsx/common";
import { ProfileStore } from "../../../stores/member/profile/ProfileStore";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { focusOnFirstInvalidInput } from "../../../../../common/src/form/utils";
import FormTel from "../../../molecules/form/FormTel.vue";
import get from "lodash.get";
import AddressFormGroup from "../../../molecules/form/AddressFormGroup.vue";
// import FormCitizenship from "../../../molecules/form/FormCitizenship.vue";
import { ignore } from "../../../../../common/src/utility/utils";

@Component({
    components: {
        // FormCitizenship,
        AddressFormGroup,
        FormTel,
        FormTextarea,
        FormCheckbox,
        FormSelect,
        ActionButton,
        FormInput,
    },
})
export default class ProfileView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly profileStore: ProfileStore = this.container.get(ProfileStore);

    get values() {
        return this.profileStore.data;
    }

    get errors() {
        return this.profileStore.formErrors;
    }

    get affiliations(): UserAffiliation[] {
        return Object.values(UserAffiliation).sort();
    }

    get organization(): string | undefined {
        return get(this.authStore.user, "organization.organizationName");
    }

    async saveProfile() {
        await this.profileStore.submit();

        if (this.profileStore.failed) {
            this.notificationStore.warning("Profile update failed.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have updated profile successfully.");
            this.authStore.me().catch(ignore);
        }
    }
}
