
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../../components/MoreActions.vue";
import AddAddressModal from "./AddAddressModal.vue";
import EditAddressModal from "./EditAddressModal.vue";
import { Address } from "@gsx/common";

@Component({
    components: { AddAddressModal, EditAddressModal, MoreActions },
})
export default class OrganizationAddressTable extends Vue {
    @Prop({ default: () => [] }) value!: Address[];
    isAddModalVisible: boolean = false;
    editedIndex: number | null = null;

    get formattedValue(): Address[] {
        return this.value || [];
    }

    get isEditModalVisible(): boolean {
        return this.editedIndex !== null;
    }

    @Emit()
    input(value: Address[]) {
        return value;
    }

    displayAddModal() {
        this.isAddModalVisible = true;
    }

    hideAddModal() {
        this.isAddModalVisible = false;
    }

    displayEditModal(index: number) {
        this.editedIndex = index;
    }

    hideEditModal() {
        this.editedIndex = null;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add(address: Address) {
        this.input([...this.formattedValue, address]);
    }

    edit(address: Address) {
        if (this.editedIndex === null) {
            return;
        }

        const newValue = [...this.formattedValue];
        newValue[this.editedIndex] = address;
        this.input(newValue);
    }
}
