
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { UpdateUserOrganizationStore } from "../../../../stores/member/organization/profile/UpdateUserOrganizationStore";
import OrganizationProfileForm from "../../../../molecules/organization/profile/OrganizationProfileForm.vue";
import { focusOnFirstInvalidInput } from "../../../../../../common/src/form/utils";
import { AuthStore } from "../../../../../../common/src/stores/AuthStore";
import { ignore } from "../../../../../../common/src/utility/utils";
import { isAdmin } from "@gsx/common";

@Component({
    components: {
        OrganizationProfileForm,
    },
})
export default class OrganizationProfileView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly organizationStore: UpdateUserOrganizationStore = this.container.get(
        UpdateUserOrganizationStore,
    );
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    get organizationUrl() {
        return `/dashboard/organizations/${this.authStore.user?.organization?.organizationid}`;
    }

    get isUserAdmin() {
        return isAdmin(this.authStore.user);
    }

    async updateOrganization() {
        await this.organizationStore.submit();

        if (this.organizationStore.failed) {
            this.notificationStore.warning("Organization update failed.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have updated organization successfully.");
            this.authStore.me().catch(ignore);
        }
    }
}
