
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import LogoCard from "../organization/logo/LogoCard.vue";
import { NotificationStore } from "../../../../common/src/stores/NotificationStore";
import { UpdateUserOrganizationLogoStore } from "../../stores/member/organization/logo/UpdateUserOrganizationLogoStore";
import { Transaction } from "../../utility/Transaction";

@Component({
    components: { LogoCard },
})
export default class OrganizationLogoEditCard extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly updateUserOrganizationLogoStore: UpdateUserOrganizationLogoStore = this.container.get(
        UpdateUserOrganizationLogoStore,
    );
    logoFile: any = null;
    transaction: Transaction = new Transaction();

    async updateLogo() {
        this.updateUserOrganizationLogoStore.data.set("logo", this.logoFile);
        await this.updateUserOrganizationLogoStore.submit();

        if (this.updateUserOrganizationLogoStore.failed) {
            this.notificationStore.warning("Updating organization logo failed.");
        } else {
            this.notificationStore.success("Organization logo has been updated.");
            // Reload logo
            await this.authStore.me();
            this.logoFile = null;
        }
    }
}
