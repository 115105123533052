
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MoreActions extends Vue {
    isVisible: boolean = false;

    created() {
        window.addEventListener("click", this.onDocumentClick);
    }

    destroyed() {
        window.removeEventListener("click", this.onDocumentClick);
    }

    onDocumentClick(e: any) {
        if (!this.$el.contains(e.target)) {
            this.hide();
        }
    }

    show() {
        this.isVisible = true;
    }

    hide() {
        this.isVisible = false;
    }
}
