import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../common/src/api/Api";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { QuiltyInsightsBody } from "@gsx/common/src/types/http/admin/quiltyInsights";
import { createQuiltyInsightsEmptyBody } from "../../../utility/entity/quiltyInsights";

@injectable()
export class QuiltyInsightsCreateStore extends SubmitStore<QuiltyInsightsBody> {
    readonly data: Nullable<QuiltyInsightsBody> = createQuiltyInsightsEmptyBody();
    quiltyInsightsMedia: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.quiltyInsights.create(body, this.quiltyInsightsMedia);
    }
}
