import { marshal, Nullable, OrganizationProfileBody } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../common/src/api/Api";
import { createOrganizationEmptyBody } from "../../../utility/entity/organization";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";

@injectable()
export class OrganizationCreateStore extends SubmitStore<OrganizationProfileBody> {
    readonly data: Nullable<OrganizationProfileBody> = createOrganizationEmptyBody();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.organization.create(body);
    }
}
