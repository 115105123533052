
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import PastProjectForm from "../../../../molecules/organization/past-projects/PastProjectForm.vue";
import FormSimpleMultiSelect from "../../../../molecules/form/FormSimpleMultiSelect.vue";
import FormTextarea from "../../../../molecules/form/FormTextarea.vue";
import ActionButton from "../../../../../../common/src/components/ActionButton.vue";
import FormEnumMultiSelect from "../../../../molecules/form/FormEnumMultiSelect.vue";
import FormInput from "../../../../molecules/form/FormInput.vue";
import { NotificationStore } from "../../../../../../common/src/stores/NotificationStore";
import { focusOnFirstInvalidInput } from "../../../../../../common/src/form/utils";
import { PastProjectCreateStore } from "../../../../stores/admin/organization/PastProjectCreateStore";
import { PastProjectListStore } from "../../../../stores/admin/organization/PastProjectListStore";
import { handleError } from "../../../../../../common/src/utility/errorUtils";

@Component({
    components: {
        PastProjectForm,
        FormSimpleMultiSelect,
        FormTextarea,
        ActionButton,
        FormEnumMultiSelect,
        FormInput,
    },
})
export default class PastProjectCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly createStore: PastProjectCreateStore = this.container.get(PastProjectCreateStore);
    readonly listStore: PastProjectListStore = this.container.get(PastProjectListStore);

    created() {
        this.listStore.loadWithId(this.organizationId).catch(handleError);
        this.createStore.setOrganizationId(this.organizationId);
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    async submit() {
        await this.createStore.submit();

        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create past project.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have created past project successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push(`/admin/organizations/${this.organizationId}/past-projects`);
        }
    }
}
