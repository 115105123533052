import { marshal, Nullable, OrganizationProfileBody } from "@gsx/common";
import { injectable } from "inversify";
import { EditStore } from "../../general/EditStore";
import { Api } from "../../../../../common/src/api/Api";
import { createOrganizationEmptyBody } from "../../../utility/entity/organization";

// EditStoreVuex is not used here, because components are modifying
// data properties directly. That behaviour is not supported by vuex.
// The whole data would have to be replaced instead.

@injectable()
export class OrganizationEditStore extends EditStore<OrganizationProfileBody> {
    private id: string = "";
    readonly data: Nullable<OrganizationProfileBody> = createOrganizationEmptyBody();

    constructor(private readonly api: Api) {
        super();
    }

    public setId(value: string): void {
        this.id = value;
    }

    protected async loadRequest(): Promise<OrganizationProfileBody> {
        return this.api.admin.organization.get(this.id);
    }

    protected async updateRequest(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.organization.update(this.id, body);
    }
}
