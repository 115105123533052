
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import OrganizationProfileForm from "../../../molecules/organization/profile/OrganizationProfileForm.vue";
import { OrganizationCreateStore } from "../../../stores/admin/organization/OrganizationCreateStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { OrganizationListStore } from "../../../stores/admin/organization/OrganizationListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";

@Component({
    components: {
        OrganizationProfileForm,
    },
})
export default class OrganizationCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: OrganizationCreateStore = this.container.get(OrganizationCreateStore);
    readonly listStore: OrganizationListStore = this.container.get(OrganizationListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    async createOrganization() {
        await this.createStore.submit();

        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create an organization.");
        } else {
            this.notificationStore.success("You have created organization successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/admin/organizations");
        }
    }
}
