
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MoreActions from "../../components/MoreActions.vue";
import FormInput from "../../form/FormInput.vue";
@Component({
    components: { MoreActions, FormInput },
})
export default class ReportMetadataTable extends Vue {
    @Prop({ default: () => [] }) value!: { key: string; value: string }[];

    get formattedValue(): { key: string; value: string }[] {
        return this.value || [];
    }

    @Emit()
    input(value: { key: string; value: string }[]) {
        return value;
    }

    destroy(index: number) {
        const newValue = [...this.formattedValue];
        newValue.splice(index, 1);
        this.input(newValue);
    }

    add() {
        this.input([...this.formattedValue, { key: "", value: "" }]);
    }
}
