
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { FlashNewsEditStore } from "../../../stores/admin/flashNews/FlashNewsEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
@Component
export default class FlashNewsEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: FlashNewsEditStore = this.container.get(FlashNewsEditStore);

    created() {
        this.editStore.setId(this.flashNewsId);
        this.editStore.load().catch(handleError);
    }
    get flashNewsId(): string {
        return this.$route.params.reportId;
    }

    get generalUrl(): string {
        return `/admin/flashNews/${this.flashNewsId}/general`;
    }
    get mediaUrl(): string {
        return `/admin/flashNews/${this.flashNewsId}/media`;
    }
}
