
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";

@Component({
    components: { ActionButton },
})
export default class LogoCard extends Vue {
    @Prop() logo?: string;
    @Prop({ default: false }) isProcessing?: boolean;
    @Prop() value?: File;

    @Ref("organizationLogo") organizationLogo!: HTMLInputElement;
    tempLogo: string | null = null;

    get logoStyle() {
        return this.logoUrl ? { "background-image": `url("${this.logoUrl}")` } : null;
    }

    get logoUrl(): string | undefined {
        if (this.tempLogo) {
            return this.tempLogo;
        }

        if (this.logo) {
            return this.logo;
        }

        return undefined;
    }

    changeOrganizationLogo(event: any) {
        const target: HTMLInputElement = event.target;
        const file = target.files && target.files[0];
        this.input(file);
    }

    clear() {
        this.organizationLogo.value = "";
        this.input(null);
    }

    @Emit()
    input(file: File | null) {
        return file;
    }

    @Emit()
    change() {
        //
    }

    @Watch("value")
    updateTempLogo(newValue: File | null) {
        if (newValue) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.tempLogo = e.target.result;
            };
            reader.readAsDataURL(newValue);
        } else {
            this.tempLogo = null;
        }
    }
}
