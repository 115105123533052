import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { EditStore } from "../../general/EditStore";
import { Api } from "../../../../../common/src/api/Api";
import { QuiltyInsightsBody } from "@gsx/common/src/types/http/admin/quiltyInsights";
import { createQuiltyInsightsEmptyBody } from "../../../../src/utility/entity/quiltyInsights";

// EditStoreVuex is not used here, because components are modifying
// data properties directly. That behaviour is not supported by vuex.
// The whole data would have to be replaced instead.

@injectable()
export class QuiltyInsightsEditStore extends EditStore<QuiltyInsightsBody> {
    private id: string = "";
    readonly data: Nullable<QuiltyInsightsBody> = createQuiltyInsightsEmptyBody();
    quiltyInsightsMedia: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    public setId(value: string): void {
        this.id = value;
    }

    protected async loadRequest(): Promise<QuiltyInsightsBody> {
        return this.api.admin.quiltyInsights.get(this.id);
    }

    protected async updateRequest(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.quiltyInsights.update(this.id, body, this.quiltyInsightsMedia);
    }
}
