import { boundMethod } from "autobind-decorator";
import {
    PastProjectListItem,
    PastProjectListResponse,
} from "@gsx/common/src/types/http/admin/organizationPastProject";
import { Api } from "../../../../../common/src/api/Api";
import { Action, Module, Mutation, RegisterOptions } from "vuex-class-modules";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";

@Module({ generateMutationSetters: true })
export class PastProjectListStore extends ListStoreVuex<PastProjectListItem> {
    private organizationId: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    @Mutation
    public setId(id: string): void {
        this.organizationId = id;
    }

    @Action
    public async loadWithId(organizationId: string): Promise<void> {
        if (this.organizationId !== organizationId) {
            this.setId(organizationId);
            this.clear();
        }

        await this.load();
    }

    @boundMethod
    protected async fetch(): Promise<PastProjectListResponse> {
        return this.api.admin.organization.pastProject.list(this.organizationId, this.queryParams);
    }
}
