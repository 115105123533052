
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { QuiltyResourceEditStore } from "../../../stores/admin/quiltyResource/QuiltyResourceEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import QuiltyResourceForm from "../../../molecules/quiltyResource/QuiltyResourceForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import { QuiltyResourceListStore } from "../../../stores/admin/quiltyResource/QuiltyResourceListStore";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";

@Component({
    components: {
        QuiltyResourceForm,
        ActionButton,
    },
})
export default class QuiltyResourceGeneralView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: QuiltyResourceEditStore = this.container.get(QuiltyResourceEditStore);
    api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: QuiltyResourceListStore = this.container.get(QuiltyResourceListStore);

    media?: { resource?: File; picture?: File } = {};

    created() {
        this.editStore.setId(this.quiltyResourceId);
        this.editStore.load().catch(handleError);
    }

    get quiltyResourceId(): string {
        return this.$route.params.quiltyResource;
    }

    get organizationId(): string {
        return this.$route.params.organization;
    }

    async updateQuiltyResource() {
        this.editStore.quiltyResourceMedia.set("resource", this.media?.resource!);
        this.editStore.quiltyResourceMedia.set("picture", this.media?.picture!);

        await this.editStore.update();

        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Quilty resource update failed.");
        } else {
            this.notificationStore.success("You have updated quilty resource successfully.");
        }
    }

    async destroyQuiltyResource() {
        try {
            var confirmation = confirm("Are you sure you want to delete quilty resource");
            if (confirmation) {
                await this.api.admin.quiltyResources.delete(this.quiltyResourceId);
                this.notificationStore.success("Quilty resource has been deleted.");
                this.listStore.reload();
                this.$router.push(`/admin/organizations/${this.organizationId}/quilty-resources`);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting quilty resource.");
        }
    }

    async sendTestEmail() {
        try {
            await this.api.admin.quiltyResources.sendEmail({
                ...this.editStore.data,
                distroList: ["report test"],
            });
            this.notificationStore.success("Test email sent");
        } catch (err) {
            this.notificationStore.danger("Failed to send test email.");
        }
    }

    async sendEmail() {
        if (this.editStore.data.distroList?.length) {
            try {
                await this.api.admin.quiltyResources.sendEmail(this.editStore.data);
                this.notificationStore.success("Email sent");
            } catch (err) {
                this.notificationStore.danger("Failed to send email.");
            }
        } else {
            this.notificationStore.warning("No distribution list for quiltyResource");
        }
    }
}
