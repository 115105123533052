import { Module } from "vuex-class-modules";
import { CollectionStore } from "../../../../../common/src/stores/CollectionStore";
import { FlashNewsListItem } from "@gsx/common/src/types/http/admin/flashNews";
import { SortOrder } from "@gsx/common";

@Module()
export class FlashNewsCollectionStore extends CollectionStore<FlashNewsListItem> {
    sort: keyof FlashNewsListItem = "postDate";
    order: SortOrder = SortOrder.Desc;
}
