
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { QuiltyInsightsEditStore } from "../../../stores/admin/quiltyInsights/QuiltyInsightsEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
@Component
export default class QuiltyInsightsEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: QuiltyInsightsEditStore = this.container.get(QuiltyInsightsEditStore);

    created() {
        this.editStore.setId(this.quiltyInsightsId);
        this.editStore.load().catch(handleError);
    }
    get quiltyInsightsId(): string {
        return this.$route.params.reportId;
    }

    get generalUrl(): string {
        return `/admin/quiltyInsights/${this.quiltyInsightsId}/general`;
    }
    get mediaUrl(): string {
        return `/admin/quiltyInsights/${this.quiltyInsightsId}/media`;
    }
}
