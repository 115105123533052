
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
// @ts-ignore
import ClickOutside from "vue-click-outside";
import ProvideFeedback from "../../../../common/src/feedback/ProvideFeedback.vue";
import AccordionCard from "../components/AccordionCard.vue";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import ProfileItems from "./components/ProfileItems.vue";
import { SidebarStore } from "../../../../common/src/stores/SidebarStore";
import { hasAccessToDashboard } from "../../../../common/src/utility/entity/user";
import RecommendOrganization from "../../../../common/src/feedback/RecommendOrganization.vue";

@Component({
    components: { RecommendOrganization, ProfileItems, AccordionCard, ProvideFeedback },
    directives: { ClickOutside },
})
export default class Sidebar extends Vue {
    @Inject("container") readonly container!: Container;
    sidebarStore: SidebarStore = this.container.get(SidebarStore);
    authStore: AuthStore = this.container.get(AuthStore);

    get hasDashboardAccess(): boolean {
        return hasAccessToDashboard(this.authStore.user);
    }
}
