
import { Component, Emit, Inject, Prop, Vue } from "vue-property-decorator";
import { QuiltyResource } from "@gsx/common";
import LoadingOverlay from "../../../../common/src/components/LoadingOverlay.vue";
import { Container } from "inversify";
import NoRecords from "../../../../common/src/components/NoRecords.vue";
import { CollectionStore } from "../../../../common/src/stores/CollectionStore";
import { enrichedColumns } from "../../../../dashboard/src/utils/tableUtils";
import { ProductTypeStore } from "../../../../common/src/stores/ProductTypeStore";
import { handleError } from "../../../../common/src/utility/errorUtils";
import { ServiceTypeStore } from "../../../../common/src/stores/ServiceTypeStore";
import { isNthLevel } from "../../../../common/src/utility/treeUtils";
import { QuiltyResourceListItem } from "@gsx/common/src/types/http/admin/quiltyResource";
import { escapeRegExp } from "../../../../../server/src/utils";
// import ListStoreVuex from "../../../../common/src/stores/general/ListStoreVuex";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import { QuiltyResourceListStore } from "../../../../landing/src/stores/admin/quiltyResource/QuiltyResourceListStore";

interface Column {
    id?: keyof QuiltyResource;
    name: string;
}

@Component({
    components: { NoRecords, LoadingOverlay },
})
export default class QuiltyResourceTable extends Vue {
    @Prop() collectionStore!: CollectionStore<QuiltyResourceListItem>;
    // @Prop() listStore!:
    //     | (FilterableListStore<QuiltyResourceListItem> & { query: string })
    //     | (ListStoreVuex<QuiltyResourceListItem> & { query: string });
    @Inject("container") readonly container!: Container;
    readonly listStore: QuiltyResourceListStore = this.container.get(QuiltyResourceListStore);

    readonly productTypeStore: ProductTypeStore = this.container.get(ProductTypeStore);
    readonly serviceTypeStore: ServiceTypeStore = this.container.get(ServiceTypeStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    readonly columns: ReadonlyArray<Column> = [
        {
            id: "picture",
            name: "",
        },
        {
            id: "title",
            name: "Title",
        },
        {
            id: "summary",
            name: "Narrative",
        },
        {
            id: "postDate",
            name: "Posted At",
        },
    ];

    created() {
        this.collectionStore.changeSort("postDate");
        this.collectionStore.changeSort("postDate");
        this.productTypeStore.load().catch(handleError);
        this.serviceTypeStore.load().catch(handleError);
    }

    get enrichedColumns() {
        return enrichedColumns(this.collectionStore, this.columns);
    }

    getProductTags(quiltyResource: QuiltyResourceListItem): string[] {
        return (quiltyResource.products || [])
            .filter((tag) => isNthLevel(tag, 1))
            .map((tag) => this.productTypeStore.idLabelMapping.get(tag) || tag);
    }

    searchingTag(tag: string) {
        if (this.listStore.query) {
            const regexp = new RegExp(escapeRegExp(this.listStore.query.trim()), "i");
            if (regexp.test(tag)) {
                return "primary";
            }
        }

        return "secondary";
    }

    @Emit("rowSelect")
    rowSelect(quiltyResource: QuiltyResourceListItem) {
        return quiltyResource;
    }

    getServiceTags(quiltyResource: QuiltyResourceListItem): string[] {
        return (quiltyResource.services || [])
            .filter((tag) => isNthLevel(tag, 1))
            .map((tag) => this.serviceTypeStore.idLabelMapping.get(tag) || tag);
    }

    getAllTags(
        quiltyResource: QuiltyResourceListItem,
    ): { label: string; link?: string | undefined }[] {
        const productTags = this.getProductTags(quiltyResource).map((tag) => ({ label: tag }));
        const serviceTags = this.getServiceTags(quiltyResource).map((tag) => ({ label: tag }));
        const orgTags = (quiltyResource.organizations || []).map((org) => ({
            label: org.organizationName,
            link: `organizations/${org.organizationId}`,
        }));
        const customTags = (quiltyResource.tags || []).map((tag) => ({ label: tag }));
        return [...orgTags, ...productTags, ...serviceTags, ...customTags];
    }
}
