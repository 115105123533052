import AdminView from "../views/admin/AdminView.vue";
import { UserRole } from "@gsx/common";
import { Container } from "inversify";
import FeedbackListView from "../views/admin/feedback/FeedbackListView.vue";
import OrganizationListView from "../views/admin/organization/OrganizationListView.vue";
import RequestTabsView from "../views/admin/request/RequestTabsView.vue";
import FlashNewsCreateView from "../views/admin/flashNews/FlashNewsCreateView.vue";
import FlashNewsEditView from "../views/admin/flashNews/FlashNewsEditView.vue";
import FlashNewsGeneralView from "../views/admin/flashNews/FlashNewsGeneralView.vue";
import FlashNewsListView from "../views/admin/flashNews/FlashNewsListView.vue";
import OrganizationEditView from "../views/admin/organization/OrganizationEditView.vue";
import OrganizationCreateView from "../views/admin/organization/OrganizationCreateView.vue";
import PastProjectListView from "../views/admin/organization/past-project/PastProjectListView.vue";
import PastProjectEditView from "../views/admin/organization/past-project/PastProjectEditView.vue";
import OrganizationEditProfileView from "../views/admin/organization/profile/OrganizationEditProfileView.vue";
import PastProjectCreateView from "../views/admin/organization/past-project/PastProjectCreateView.vue";
import LogoEditView from "../views/admin/organization/logo/LogoEditView.vue";
import { AuthGate } from "../../../common/src/AuthGate";
import CheckListView from "../views/admin/organization/check-list/CheckListView.vue";
import OrganizationLogView from "../views/admin/organization/log/OrganizationLogView.vue";
import UserAccountsView from "../views/admin/accounts/UserAccountsView.vue";
import AccountCreationView from "../views/admin/accounts/AccountCreationView.vue";
import UserActivityLogsView from "../views/admin/activityLogs/UserActivityLogsView.vue";
import FilesView from "../views/admin/organization/files/FilesView.vue";

import MergerAcquisitionsListview from "../views/admin/mergerAcquisitions/MergerAcquisitionsListView.vue";
import MergerAcquisitionsCreateView from "../views/admin/mergerAcquisitions/MergerAcquisitionsCreateView.vue";
import MergerAcquisitionsEditView from "../views/admin/mergerAcquisitions/MergerAcquisitionsEditView.vue";

import ReportsListView from "../views/admin/reports/ReportsListView.vue";
import ReportsCreateView from "../views/admin/reports/ReportsCreateView.vue";
import ReportsEditView from "../views/admin/reports/ReportsEditView.vue";
import ReportGeneralView from "../views/admin/reports/ReportGeneralView.vue";

import QuiltyInsightsCreateView from "../views/admin/quiltyInsights/QuiltyInsightsCreateView.vue";
import QuiltyInsightsEditView from "../views/admin/quiltyInsights/QuiltyInsightsEditView.vue";
import QuiltyInsightsGeneralView from "../views/admin/quiltyInsights/QuiltyInsightsGeneralView.vue";
import QuiltyInsightsListView from "../views/admin/quiltyInsights/QuiltyInsightsListView.vue";

import QuiltyResourceListView from "../views/admin/quiltyResources/QuiltyResourceListView.vue";
import QuiltyResourceCreateView from "../views/admin/quiltyResources/QuiltyResourceCreateView.vue";
import QuiltyResourceGeneralView from "../views/admin/quiltyResources/QuiltyResourceGeneralView.vue";
import QuiltyResourceEditView from "../views/admin/quiltyResources/QuiltyResourceEditView.vue";

import { RouteConfig } from "vue-router";

import OrganizationInsightComponent from "../views/admin/organization/insights/OrganizationInsightComponent.vue";

export const createAdminRoutes = (container: Container): RouteConfig[] => {
    const authGate: AuthGate = container.get(AuthGate);

    return [
        {
            path: "/admin",
            redirect: "/admin/accounts",
            component: AdminView,
            beforeEnter: authGate.requireAuthorizedGuard([UserRole.Admin, UserRole.Assistant]),
            meta: {
                breadcrumbs: [
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Admin",
                        to: "/admin",
                    },
                ],
            },
            children: [
                {
                    path: "organizations",
                    component: OrganizationListView,
                    meta: {
                        title: "Organizations",
                    },
                },
                {
                    path: "organizations/create",
                    component: OrganizationCreateView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Organizations",
                                to: "/admin/organizations",
                            },
                        ],
                        title: "Create",
                    },
                },
                {
                    path: "organizations/:organization",
                    component: OrganizationEditView,
                    redirect: "organizations/:organization/profile",
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Organizations",
                                to: "/admin/organizations",
                            },
                        ],
                        title: "Edit",
                    },
                    children: [
                        {
                            path: "profile",
                            component: OrganizationEditProfileView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Profile",
                            },
                        },
                        {
                            path: "logo",
                            component: LogoEditView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Logo",
                            },
                        },
                        {
                            path: "files",
                            component: FilesView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Attach Files",
                            },
                        },
                        {
                            path: "past-projects",
                            component: PastProjectListView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Past Projects",
                            },
                        },
                        {
                            path: "past-projects/create",
                            component: PastProjectCreateView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                    {
                                        name: "Past Projects",
                                        to: "/admin/organizations/:organization/past-projects",
                                    },
                                ],
                                title: "Create",
                            },
                        },
                        {
                            path: "past-projects/:pastProject",
                            component: PastProjectEditView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                    {
                                        name: "Past Projects",
                                        to: "/admin/organizations/:organization/past-projects",
                                    },
                                ],
                                title: "Edit",
                            },
                        },
                        {
                            path: "check-list",
                            component: CheckListView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Check List",
                            },
                        },
                        {
                            path: "log",
                            component: OrganizationLogView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Log",
                            },
                        },
                        {
                            path: "quilty-resources",
                            component: QuiltyResourceListView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                ],
                                title: "Quilty Resources",
                            },
                        },
                        {
                            path: "quilty-resources/create",
                            component: QuiltyResourceCreateView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                    {
                                        name: "Quilty Resources",
                                        to: "/admin/organizations/:organization/quilty-resources/create",
                                    },
                                ],
                                title: "Create",
                            },
                        },
                        {
                            path: "quilty-resources/:quiltyResource",
                            component: QuiltyResourceEditView,
                            redirect: "quilty-resources/:quiltyResource/general",
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Organization",
                                        to: "/admin/organizations/:organization",
                                    },
                                    {
                                        name: "Quilty Resources",
                                        to: "/admin/organizations/:organization/quilty-resources/edit",
                                    },
                                ],
                                title: "Edit",
                            },
                            children: [
                                {
                                    path: "general",
                                    component: QuiltyResourceGeneralView,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                name: "Quilty Resource",
                                                to: "/admin/general/:quiltyResourceId",
                                            },
                                        ],
                                        title: "General",
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "org-insight",
                    component: OrganizationInsightComponent,
                    meta: {
                        title: "Organization P&S",
                    },
                },
                {
                    path: "feedback",
                    component: FeedbackListView,
                    meta: {
                        title: "Feedback",
                    },
                },
                {
                    path: "accounts",
                    component: UserAccountsView,
                    meta: {
                        title: "User Accounts",
                    },
                },
                {
                    path: "activity",
                    component: UserActivityLogsView,
                    meta: {
                        title: "User Activity",
                    },
                },
                {
                    path: "accountCreation",
                    component: AccountCreationView,
                    meta: {
                        title: "Account Creation",
                    },
                },
                {
                    path: "requests",
                    component: RequestTabsView,
                    meta: {
                        title: "Requests",
                    },
                },
                {
                    path: "reports",
                    component: ReportsListView,
                    meta: {
                        title: "Reports",
                    },
                },
                {
                    path: "reports/create",
                    component: ReportsCreateView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Reports",
                                to: "/admin/reports",
                            },
                        ],
                        title: "Create",
                    },
                },
                {
                    path: "reports/:reportId",
                    component: ReportsEditView,
                    redirect: "reports/:reportId/general",
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Reports",
                                to: "/admin/reports",
                            },
                        ],
                        title: "Edit",
                    },
                    children: [
                        {
                            path: "general",
                            component: ReportGeneralView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Report",
                                        to: "/admin/general/:reportId",
                                    },
                                ],
                                title: "General",
                            },
                        },
                    ],
                },
                {
                    path: "flashNews",
                    component: FlashNewsListView,
                    meta: {
                        title: "FlashNews",
                    },
                },
                {
                    path: "flashNews/create",
                    component: FlashNewsCreateView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Flash News",
                                to: "/admin/flashNews",
                            },
                        ],
                        title: "Create",
                    },
                },
                {
                    path: "flashNews/:flashNewsId",
                    component: FlashNewsEditView,
                    redirect: "flashNews/:flashNewsId/general",
                    meta: {
                        breadcrumbs: [
                            {
                                name: "FlashNews",
                                to: "/admin/flashNews",
                            },
                        ],
                        title: "Edit",
                    },
                    children: [
                        {
                            path: "general",
                            component: FlashNewsGeneralView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Flash News",
                                        to: "/admin/general/:flashNewsId",
                                    },
                                ],
                                title: "General",
                            },
                        },
                    ],
                },
                {
                    path: "quiltyInsights",
                    component: QuiltyInsightsListView,
                    meta: {
                        title: "QuiltyInsights",
                    },
                },
                {
                    path: "quiltyInsights/create",
                    component: QuiltyInsightsCreateView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Quilty Insights",
                                to: "/admin/quiltyInsights",
                            },
                        ],
                        title: "Create",
                    },
                },
                {
                    path: "quiltyInsights/:quiltyInsightsId",
                    component: QuiltyInsightsEditView,
                    redirect: "quiltyInsights/:quiltyInsightsId/general",
                    meta: {
                        breadcrumbs: [
                            {
                                name: "QuiltyInsights",
                                to: "/admin/quiltyInsights",
                            },
                        ],
                        title: "Edit",
                    },
                    children: [
                        {
                            path: "general",
                            component: QuiltyInsightsGeneralView,
                            meta: {
                                breadcrumbs: [
                                    {
                                        name: "Quilty Insights",
                                        to: "/admin/general/:quiltyInsightsId",
                                    },
                                ],
                                title: "General",
                            },
                        },
                    ],
                },
                {
                    path: "mergerAcquisitions",
                    component: MergerAcquisitionsListview,
                    meta: {
                        title: "Merger Acquisitions",
                    },
                },
                {
                    path: "mergerAcquisitions/create",
                    component: MergerAcquisitionsCreateView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Merger Acquisitions",
                                to: "/admin/mergerAcquisitions",
                            },
                        ],
                        title: "Create",
                    },
                },
                {
                    path: "mergerAcquisitions/:mergerAcquisitionId",
                    component: MergerAcquisitionsEditView,
                    meta: {
                        breadcrumbs: [
                            {
                                name: "Merger Acquisitions",
                                to: "/admin/mergerAcquisitions",
                            },
                        ],
                        title: "Edit",
                    },
                },
            ],
        },
    ];
};
